import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  RequireAdmin,
  RequireAuth,
  RequireLogin,
  checkToken,
  useFETCH,
} from "./Tools/APIs";
import { P403, P404, P500 } from "./Tools/Error";
import { Message } from "./components";
import logo from "./images/logo.png";
import { Navbar, SideBar } from "./layout";

import * as pages from "./pages";

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { data } = useFETCH(
    localStorage.getItem("token") ? "admin/check/auth" : ""
  );
  const preventScrollForNumberFields = (e) => {
    if (e.target.type === "number") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    document.addEventListener("wheel", preventScrollForNumberFields, {
      passive: false,
    });
    return () => {
      document.removeEventListener("wheel", preventScrollForNumberFields);
    };
  }, []);
  

  
  return (
    <div className="!text-sm">
      <Message />
      {/* <SuccessMessage /> */}
      <div className={`relative flex ${!(checkToken || data) && " items-center h-screen" } `}>
        { data  && <SideBar /> }
        <div className="container mx-auto relative ">
          {  data && <Navbar />}
          <div className="overflow-hidden min-h-[50vh]">
            <>
              <Routes>
                <Route element={<RequireLogin />}>
                  <Route path="login" element={<pages.Login />} />
                </Route>
                <Route
                  path="forget-password"
                  element={<pages.ForgetPassword />}
                />
                <Route path="verification-code" element={<pages.Code />} />
                <Route path="set-password" element={<pages.SetPassword />} />

                <Route element={<RequireAuth />}>
                  <Route
                    path=""
                    element={
                      <h1 className="grid place-content-center h-[75vh]">
                        <img
                          src={logo}
                          alt=""
                          className="w-[450px] h-[350px] object-contain max-md:w-[250px] rounded-3xl"
                        />
                      </h1>
                    }
                  />
                  <Route path="*" element={<P404 />} />
                  <Route path="403" element={<P403 />} />
                  <Route path="500" element={<P500 />} />
                  <Route
                    element={
                      <RequireAdmin name={"financial_collection_officer"} />
                    }
                  >
                    <Route element={<RequireAdmin name={"register_manager"} />}>
                      <Route path="admins">
                        <Route index element={<pages.Users />} />
                        <Route path=":id" element={<pages.AddUser />} />
                        <Route path="info/:id" element={<pages.SingleUser />} />
                        <Route path="wallet/:id" element={<pages.Wallet />} />
                      </Route>
                      <Route path="items">
                        <Route index element={<pages.Items />} />
                        <Route path=":id" element={<pages.EditItems />} />
                        <Route
                          path=":idItems/processes"
                          element={<pages.Processes />}
                        />
                        <Route
                          path=":idItems/processes/:id"
                          element={<pages.AddProcesses />}
                        />
                      </Route>
                      <Route path="templates" element={<pages.Templates />} />
                      <Route path="processes">
                        <Route index element={<pages.Processes />} />
                        <Route path="wallet/:id" element={<pages.Wallet />} />
                        <Route path=":id" element={<pages.AddProcesses />} />
                      </Route>
                      <Route path="academic-degrees">
                        <Route index element={<pages.AcademicDegrees />} />
                        <Route
                          path=":id"
                          element={<pages.AddAcademicDegrees />}
                        />
                      </Route>
                      <Route path="authentication-types">
                        <Route index element={<pages.AuthenticationTypes />} />
                        <Route
                          path=":id"
                          element={<pages.AddAuthenticationTypes />}
                        />
                      </Route>
                      <Route path="beneficiaries">
                        <Route index element={<pages.Beneficiaries />} />
                        <Route
                          path=":id"
                          element={<pages.AddBeneficiaries />}
                        />
                        <Route
                          path="info/:id"
                          element={<pages.BeneficiariesSingleUser />}
                        />
                        <Route
                          path="wallet/:id"
                          element={<pages.BeneficiariesWallet />}
                        />
                      </Route>
                      <Route path="/all-payments" element={<pages.Payment all={true}/>}/>
                      <Route path="specialties">
                        <Route index element={<pages.Specialties />} />
                        <Route path=":id" element={<pages.AddSpecialties />} />
                        <Route
                          path=":id/single-specialties"
                          element={<pages.SingleSpecialties />}
                        />
                        <Route
                          path=":id/single-specialties/:idd"
                          element={<pages.AddSingleSpecialties />}
                        />
                      </Route>
                      <Route path="profits" element={<pages.Profits />} />
                      <Route path="payments">
                        <Route index element={<pages.Payments />} />
                        <Route path=":id" element={<pages.AddPayments />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    path="students/info/:id"
                    element={<pages.SingleStudents />}
                  />
                  <Route
                    element={
                      <RequireAdmin name={"financial_collection_officer"} />
                    }
                  >
                    <Route path="students">
                      <Route index element={<pages.Students />} />
                      <Route
                        path="transactions/:id"
                        element={<pages.StudentTransactions />}
                      />
                      <Route path="emails/:id" element={<pages.Emails />} />
                      <Route
                        path=":student_id/transactions/:id"
                        element={<pages.AddTransactions />}
                      />
                      <Route path=":id" element={<pages.AddStudents />} />
                    </Route>
                  </Route>

                  <Route path="transactions">

                    <Route index element={<pages.Transactions />} />
                    <Route
                      path=":id/payment/pdf/:idPayment"
                      element={<pages.IframePDF />}
                    />
                    <Route
                      path=":id/payments/summary/pdf"
                      element={<pages.IframePDF summary={true} />}
                    />
                    <Route path=":id" element={<pages.AddTransactions />} />

                    <Route path=":id/payment" element={<pages.Payment />} />
              
                    
                    <Route
                      path=":id/payment/:idd"
                      element={<pages.AddPayment />}
                    />
                    <Route
                      path=":id/payment-details/:idd"
                      element={<pages.PaymentDetails />}
                    />
                  </Route>
                  <Route path={`my-info/:id`} element={<pages.MyInfo />} />
                  <Route path="profile" element={<pages.Profile />} />
                  <Route
                    path="notifications"
                    element={<pages.Notifications />}
                  />
                  <Route
                    path="Change-Password"
                    element={<pages.ChangePassword />}
                  />
                </Route>
              </Routes>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
