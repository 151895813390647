import { Link, useParams } from "react-router-dom";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Back, Data, Title, Update } from "../../components";
import Loading from "../../Tools/Loading";
import EditSittingNumber from "../../components/Delete/EditSittingNumber";
import transactions from "../../svg/sort_12963813.svg";
import { Tooltip } from "react-tooltip";
import AddImage from "../../Tools/AddFile";
function SingleStudents() {
  // console.log(dataAll?.certificates)

  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/students/${id}`);
  let dataAll = data?.data.data;
  console.log(dataAll);
  return (
    <div>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Row>
          <Col lg={6} md={6} sm={6}>
            <Title title="الطالب" />
          </Col>
          <Col lg={5} md={5} sm={5}></Col>
          <Col lg={1} md={1} sm={1}>
            <Back />
          </Col>
        </Row>

        <Col
          lg={11}
          className="bg-white mx-auto border-2 border-Secondary py-5 px-3 rounded-xl"
        >
          <Row className="justify-end">
            <Col md={6}>
              <Data name=" الاسم الأول" text={dataAll?.first_name} />
            </Col>
            <Col md={6}>
              <Data name="اسم الاب" text={dataAll?.mid_name} />
            </Col>
            <Col md={6}>
              <Data name="الاسم الاخير" text={dataAll?.last_name} />
            </Col>

            <Col md={6}>
              <Data name="رقم الهاتف" text={dataAll?.phone_number} />
            </Col>
            <Col md={6}>
              <Data name="الإيميل" text={dataAll?.email} />
            </Col>
            <Col md={6}>
              <Data name="الحالة" text={dataAll?.status} />
            </Col>
            <Col md={6}>
              <Data name="الدرجة العلمية" text={dataAll?.degree} />
            </Col>
            <Col md={6}>
              <Data
                name=" نوع التصديق"
                text={dataAll?.authentication_type?.name}
              />
            </Col>
            <Col md={12} lg={12}>
              <Data name="رقم الجلوس" text={dataAll?.sitting_number} />
            </Col>
            {dataAll?.certificates.length > 0 ? (
              <>
                <h2 className="font-bold inline text-Main relative !mt-3 mr-20 max-lg:mr-[50px]">
                  الشهادات
                </h2>
                {dataAll?.certificates.map((e, i) => (
                  <Col key={i} lg={12}>
                    <a
                      href={fileUrl + e.file}
                      download="image.jpg"
                      target="_blank"
                    >
                      <svg
                        width="40px"
                        height="40px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 17H17.01M17.4 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H6.6M12 15V4M12 15L9 12M12 15L15 12"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </Col>
                ))}
              </>
            ) : (
              <div className="flex items-center gap-3 mr-20 max-lg:mr-[10%]">
                <h2 className="font-bold text-Main">الشهادات</h2>
                <span>لا يوجد صور شهادات</span>
              </div>
            )}
            {dataAll?.official_documents.length > 0 ? (
              <>
                <h2 className="font-bold text-Main !mt-3 mr-20 max-lg:mr-[10%] ">
                  مستندات رسمية
                </h2>
                {dataAll?.official_documents.map((e, i) => (
                  <Col key={i} lg={12}>
                    <a
                      href={fileUrl + e.file}
                      download="image.jpg"
                      target="_blank"
                    >
                      <svg
                        width="40px"
                        height="40px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 17H17.01M17.4 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H6.6M12 15V4M12 15L9 12M12 15L15 12"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </Col>
                ))}
              </>
            ) : (
              <div className="flex items-center gap-3 mr-20 max-lg:mr-[10%]">
                <h2 className="font-bold text-Main">المستندات الرسمية</h2>
                <span>لا يوجد صور مستندات رسمية</span>
              </div>
            )}
          </Row>
          <div className="flex justify-end items-center">
            {!dataAll?.sitting_number ? (
              <div id="my-anchor-element_email">
                <div className="w-fit">
                  <EditSittingNumber
                    id={dataAll?.id}
                    email={dataAll?.email}
                    name={dataAll?.name}
                    phone_number={dataAll?.phone_number}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <Update edit={`/students/${dataAll?.id}`} />
            <Link to={`/students/transactions/${dataAll?.id}`}>
              <div
                className={`w-10 h-10  rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
              >
                <div id="my-anchor-element">
                  <img src={transactions} alt="" className="w-7 h-7" />
                  <Tooltip
                    anchorSelect="#my-anchor-element"
                    content="عرض  الملف المالي"
                  />
                </div>
              </div>
            </Link>
          </div>
        </Col>
      </Container>
    </div>
  );
}
export default SingleStudents;
