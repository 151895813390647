import { FiUploadCloud } from "react-icons/fi";
import { usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import Popup from "../Popup/Popup";
import { Col, Row } from "../../Tools/Grid-system";
const GivingAll = ({ id }) => {
  const { handleSubmit, loading } = usePOST({});
  const handleClick = () => {
    handleSubmit(
      `admin/users/${id}/balance/logs/manage/status?status=COMPLETED`,
      "",
      true
    );
  };
  return (
    <>
      {loading ? <Loading /> : ""}
      <Popup
        // text="تسليم كافة السجلات"
        id="giving_all"
        description="هل تريد تسليم كافة السجلات"
        onClick={handleClick}
      >
           <Row>
          <Col lg={5} >
          <div
          className={`flex z-10  p-2  items-center justify-around shadow-Third shadow-sm font-bold text-base bg-white rounded-2xl text-[#0d5069] cursor-pointer w-[200px]   `}
        >
          <div>
             تسليم كافة السجلات
          </div>

          <div>
          <FiUploadCloud className="text-Third " size={20} />
          </div>
         
          
        </div>

          </Col>
        </Row>
  
      </Popup>
    </>
  );
};

export default GivingAll;
