import { RiDeleteBin2Line, RiDeleteBin5Fill, RiDeleteBin5Line, RiDeleteBin7Fill } from "react-icons/ri";
import Popup from "../Popup/Popup";
const Delete = ({ onClick, name }) => {
  return (
    <>
      <div className="w-fit">
        {localStorage.getItem("role_name") === "register_manager" ||
        localStorage.getItem("role_name") === "financial_collection_officer" ? (
          ""
        ) : (
          <Popup
            text="حذف"
            description={
              <p className="font-semibold text-xl text-center py-5">
                هل أنت متأكد من حذف العنصر
                <span className="text-red-600 mx-1">
                  {name ? `(${name})` : ""}
                </span>
                ؟
              </p>
            }
            onClick={onClick} >
              <RiDeleteBin5Line size={27} className="text-red-600" />
          </Popup>
        )}
      </div>
    </>
  );
};

export default Delete;
