import { useLocation } from "react-router-dom";
import { Add, Back, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Col, Row } from "../../Tools/Grid-system";

function Specialties() {
  const { search } = useLocation();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/specialty-sections${search}`,
    `admin/specialty-sections`
  );

  return (
    <>
      <Row>
        <Col lg={6}>
          <Title title="الكليات" />
        </Col>
        <Col lg={5} className="flex lg:justify-end">
          <Add link={`/Specialties/add`} />
        </Col>
        <Col lg={1} className="flex justify-center ">
        <Back />
        </Col>
      </Row>

      
      {isLoading ? <Loading /> : ""}
      <Table
        thead={["الاسم"]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[{ name: "name" }]}
        funDelete={deleteItem}
        edit="Specialties"
        show="Specialties"
        show2="single-specialties"
      />
     
      {/* <Back /> */}
    </>
  );
}
export default Specialties;
