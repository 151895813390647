import Logo from "../../images/logo.png";
import { useLOGIN } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { ButtonRed, Input, PasswordInput } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import login_1 from "../../images/login_img_1.png";
import logo from "../../images/logo 1.png";
import login_2 from "../../images/login_2.png";
import login_3 from "../../images/login_3.png";

const Login = () => {
  const { handleSubmit, error, loading, handleChange, setFormData, formData } =
    useLOGIN();
  const handleSubmitMain = () => {
    handleSubmit("admin/login");
  };
  useEffect(() => {
    if (sessionStorage.getItem("fcm_token")) {
      setFormData({
        ...formData,
        fcm_token: sessionStorage.getItem("fcm_token"),
      });
    }
  }, []);
  document.onkeyup = function (e) {
    if (e.key === "Enter") {
      handleSubmitMain();
    }
  };
  return (
    <Container>
      <Row justify={"center"} className="items-center">
        <Col
          lg={5}
          sm={11}
          className="relative h-[80vh] max-md:h-[40vh] bg-login   rounded-ee-[80px] rounded-se-[80px]   border-2 border-Secondary max-md:hidden  "
        >
          <img
            src={logo}
            alt=""
            className="w-[90%] h-1/4   absolute top-1/2 -translate-y-1/2 -translate-x-5"
          />
        </Col>
        <Col lg={6} className=" h-[80vh] w-full  flex-col  ">
          <Row justify={"end"}>
            <Col lg={4} sm={11} className="max-md:mb-12">
              <img src={login_2} alt="" />
            </Col>
          </Row>
          <Row>
            <Col lg={8} sm={11} className="mx-auto ">
              <h2 className="text-2xl font-bold text-[#2B3674] mb-6">
                تسجيل الدخول
              </h2>
              <Input
                type="name"
                name="name_or_email"
                onChange={handleChange}
                title="اسم المستخدم أو البريد الالكتروني"
              />
              <PasswordInput name="password" onChange={handleChange} />
              <div className="text-red-600 ">{error}</div>
              <p className="flex justify-between text-gray-700 my-3">
                <Link
                  to="/forget-password"
                  className="underline text-Main mx-1"
                >
                  هل نسيت كلمة المرور
                </Link>
              </p>
              {loading ? <Loading /> : ""}

              <ButtonRed
                name="تسجيل دخول"
                onClick={handleSubmitMain}
                className="w-[70%] py-3 mx-auto"
              />
            </Col>
          </Row>
        </Col>
        {/* <Col
          md={4}
          className="mx-auto border-2 bg-white border-Secondary rounded-xl p-5 w-full"
        >
          <img src={Logo} alt="" className="w-[300px] mx-auto" />
          <h2 className="text-xl text font-semibold">تسجيل الدخول</h2>
          <Input
            type="name"
            name="name_or_email"
            onChange={handleChange}
            title="اسم المستخدم أو البريد الالكتروني"
          />
          <PasswordInput name="password" onChange={handleChange} />
          <div className="text-red-600 ">{error}</div>
          <p className="flex justify-between text-gray-700 my-3">
            <Link to="/forget-password" className="underline text-Main mx-1">
              هل نسيت كلمة المرور
            </Link>
          </p>
          {loading ? <Loading /> : ""}

          <ButtonRed
            name="تسجيل دخول"
            onClick={handleSubmitMain}
            className="w-[70%] py-3 mx-auto"
          />
        </Col> */}
      </Row>
    </Container>
  );
};

export default Login;
