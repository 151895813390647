import React from "react";
import { Link } from "react-router-dom";

const Title = ({ title, className, link, ball }) => {
  return (
    <div className=" mr-2 pr-2 ">


      {/* {ball && (
        <span className="absolute flex justify-start top-3/5 -translate-y-1 right-0 text-lg font-bold">
          »
        </span>
      ) 
       
      } */}

      <Link to={link || ""}>
        <div className={` text-start mb-3 text-2xl max-md:text-base ${className} text-3xl font-extrabold  text-Third `}>
          {title}
        </div>
      </Link> 
      
    </div>
  );
};

export default Title;
 