import { useFETCH, useFilter } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, DateFilter, Title } from "../../components";
import { BsGraphDownArrow, BsGraphUpArrow } from "react-icons/bs";
import { AiFillDollarCircle, AiOutlineDollarCircle } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
function Profits() {
  const { search } = useLocation();
  const { data, isLoading } = useFETCH(`admin/financial-statistics${search}`);
  const { data: dataPie } = useFETCH(
    `admin/financial-statistics/pie-chart/expenses${search}`
  );
  const colors = [
    "#201658",
    "#1D24CA",
    "#98ABEE",
    "#F9E8C9",
    "#9195F6",
    "#B7C9F2",
    "#F9F07A",
    "#FB88B4",
    "#DCFFB7",
    "#FF6868",
    "#FFBB64",
    "#FFEAA7",
  ];
  return (
    <div className="main-h-[50vh]">
      <Row>
        <Col lg={6}>
          <Title title="المالية" />
        </Col>
        <Col lg={5}></Col>
        <Col lg={1} className="flex justify-center ">
          <Back />
        </Col>
      </Row>

      <Col xs={6} md={4} lg={2} className="lg:mx-3">
        <DateFilter hideAll={true} now={dataPie} />
      </Col>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Row justify=" px-3">
          <Col md={4}>
            <Profit
              className="bg-blue-400"
              name="الإيرادات"
              mony={
                data?.data.data.total_income
                  ? data?.data.data.total_income
                  : "0"
              }
            >
              <BsGraphUpArrow
                className="bg-white text-blue-400 rounded-lg p-1"
                size={35}
              />
            </Profit>
          </Col>
          <Col md={4}>
            <Profit
              className="bg-yellow-500"
              name="الأرباح"
              mony={
                data?.data.data.total_profits
                  ? data?.data.data.total_profits
                  : "0"
              }
            >
              <BsGraphDownArrow
                className="bg-white text-yellow-300 rounded-lg p-1"
                size={35}
              />
            </Profit>
          </Col>
          <Col md={4}>
            <Profit
              className="bg-red-400"
              name="المصاريف"
              mony={
                data?.data.data.total_operations_amount
                  ? data?.data.data.total_operations_amount
                  : "0"
              }
            >
              <AiFillDollarCircle
                className="bg-red-400 text-white rounded-lg p-1"
                size={35}
              />
            </Profit>
          </Col>
          <Row className="md:pt-20 flex justify-center  md:justify-center ">
            <Col
              md={4}
              className="bg-Third/90 h-[50vh] overflow-y-auto  text-white rounded-2xl flex justify-center items- p-1 pt-3 border  border-Secondary"
            >
              <div className="flex-1 text-center ">
                {dataPie?.data.data.map((e, i) => (
                  <Row className="flex gap-2 items-center max-md:!mt-6 max-md:!mr-5 ">
                    <Col col={1} className="">
                      <div
                        style={{ backgroundColor: colors[i + 1] }}
                        className="w-5 h-5 mr-auto rounded"
                      ></div>
                    </Col>
                    <Col col={4}>{e.name}</Col>
                    <Col col={3}>% {e.percentage} </Col>
                    <Col col={3}>{e.total_amount}</Col>
                  </Row>
                ))}
              </div>
            </Col>
            <Col md={1} />
            <Col md={5} className="max:lg-h-96 !w-96">
              <PieChart
                style={{ border: "3px solid white", borderRadius: "50%" }}
                data={dataPie?.data.data.map((e, i) => {
                  return {
                    title: e.name + " " + e.percentage + "%",
                    value: e.percentage,
                    color: colors[i + 1],
                  };
                })}
              />
            </Col>
          </Row>
        </Row>
      </Container>
      {/* <Back /> */}
    </div>
  );
}
export default Profits;

function Profit({ children, name, mony, className }) {
  return (
    <div
      className={`flex justify-between items-center text-white bg-black py-5 px-3 rounded-xl  font-bold ${className}`}
    >
      <div>
        <p>{name}</p>
        <p>{mony}</p>
      </div>
      <div>{children}</div>
    </div>
  );
}
