import { useFilter } from "../../Tools/APIs";

function ArchiveFilter() {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  return (
    <div className="">
      <select
        value={searchParams.get("in_archive")}
        onChange={(e) => {
          if (!e.target.value) {
            handleParamsDelete("in_archive");
          } else {
            handleParamsClick("in_archive", e.target.value);
          }
        }}
        className="w-full font-bold text-base bg-white rounded-xl py-2 text-center shadow-sm shadow-Third border-solid border-Third border-1 px-5 text-Third outline-none"
      >
        <option  selected disabled hidden>
          فلترة حسب الأرشفة
        </option>
        <option className="" value="">الكل</option>
        <option value="1">مؤرشفة</option>
        <option value="0">غير مؤرشفة</option>
      </select>
    </div>
  );
}
export default ArchiveFilter;
