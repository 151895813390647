import { useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, Data, Title } from "../../components";
import user from "../../svg/user_1144709.svg";

function Profile() {
  const { data, isLoading } = useFETCH(`admin/profile`);
  let dataAll = data?.data.data;
  return (
    <div>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Row>
          <Col lg={6}>
            <Title title="الحساب الشخصي" />
          </Col>

          <Col lg={5}>

          </Col>
          <Col lg={1} className="flex justify-center ">
        <Back />
        </Col>
        </Row>
      
        <img src={user} alt="" className="w-[100px] mx-auto mb-5" />
        <Col
          sm={11}
          className="bg-white mx-auto border-2 border-Secondary py-5 px-3 rounded-xl"
        >
          <Row className="justify-center">
            <Col md={6}>
              <Data name="الدور" text={dataAll?.role?.display} />
            </Col>
            <Col md={6}>
              <Data name="الاسم" text={dataAll?.name} />
            </Col>
            <Col md={6}>
              <Data name="رقم الهاتف" text={dataAll?.phone_number} />
            </Col>
            <Col md={6}>
              <Data name="الإيميل" text={dataAll?.email} />
            </Col>
            <Col md={6}>
              <Data name="الراتب" text={dataAll?.salary} />
            </Col>
            <Col md={6}>
              <Data name="اسم المستخدم" text={dataAll?.username} />
            </Col>
            <Col md={6}>
              <Data
                name="تاريخ الإنشاء"
                text={dataAll?.created_at.substring(0, 10)}
              />
            </Col>
            <Col md={6}>
              <Data name="الرصيد" text={dataAll?.balance} />
            </Col>
            {localStorage?.getItem("role_name") ===
            "financial_collection_officer" ? (
              ""
            ) : (
              <>
                <Col md={6}>
                  <Data name="العمولة" text={dataAll?.commission} />
                </Col>
                <Col md={6}>
                  <Data
                    name="نوع العمولة"
                    text={dataAll?.commission_type_display}
                  />
                </Col>
              </>
            )}
            <Col md={6}>
              <Data name="عمولة التحصيل" text={dataAll?.fc_commission} />
            </Col>
            <Col md={6}>
              <Data
                name="نوع عمولة التحصيل"
                text={dataAll?.fc_commission_type_display}
              />
            </Col>
          </Row>
        </Col>
      </Container>
      {/* <Back /> */}
    </div>
  );
}
export default Profile;
