import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ButtonRed = ({
  name,
  link,
  onClick,
  className,
  popup,
  text,
  successPost,
}) => {
  const [successPost2, setSuccessPost2] = useState(0);
  const [sure, setSure] = useState(false);
  document.onkeyup = function (e) {
    if (e.key === "Enter") {
      if (name === "تعديل" && sure === false) {
        setSure(true);
      } else {
        onClick(e);
      }
    }
  };
  useEffect(() => {
    setSuccessPost2(successPost || 0);
  }, [successPost]);
  return (
    <>
      <div className={sure && successPost2 === 0 ? "" : "hidden"}>
        <div
          className="fixed top-0 left-0 w-full h-full  bg-black bg-opacity-60 z-30"
          onClick={() => setSure(false)}
        ></div>
        <div
          className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center`}
        >
          <div className="bg-white z-[60] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
            <p className="font-semibold text-xl text-center py-7">
              {text ? text : "  هل أنت متأكد من إجراء هذا التعديل؟"}
            </p>
            <div className="flex items-center justify-center m-5">
              <div className="flex gap-9 mx-3">
                <div
                  className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                  onClick={onClick}
                >
                  نعم
                </div>
              </div>

              <button
                onClick={() => setSure(false)}
                className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
              >
                لا
              </button>
            </div>
          </div>
        </div>
      </div>
      {name === "تعديل" || popup ? (
        <div
          className={`bg-[#004B60] text-sm px-2 rounded-xl hover:bg-opacity-70 text-center font-semibold text-white cursor-pointer w-full  py-3 ${className}`}
          onClick={() => setSure(true)}
        >
          {name}
        </div>
      ) : link ? (
        <Link to={`${link || ""}`}>
          <div
            className={`bg-white border-2 border-Third shadow-sm shadow-Third text-sm px-2 rounded-xl hover:bg-opacity-70 text-center font-semibold text-Third cursor-pointer w-full  py-3 ${className}`}
            onClick={() => setSure(true)}
          >
            {name}
          </div>
        </Link>
      ) : (
        <div
          className={`bg-[#004B60] text-sm px-2 rounded-xl hover:bg-opacity-70 text-center font-semibold text-white cursor-pointer w-full  py-3 ${className}`}
          onClick={onClick}
        >
          {name}
        </div>
      )}
    </>
  );
};

export default ButtonRed;
