import { BiMenu } from "react-icons/bi";
import Logo from "../../images/log.png";
import { useEffect, useState } from "react";
import { useContextHook } from "../../Context/ContextOPen";
import { ButtonRed } from "../../components";
import { logout } from "../../Tools/APIs";
import NotificationNew from "./NotificationNew";
import { Link, useLocation } from "react-router-dom";
import logo from '../../svg/logo.svg'

const Navbar = () => {
  const { changeMenu } = useContextHook();
  const [sure, setSure] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    const checkTime = () => {
      const currentTime = new Date();
      const storedTime = localStorage.getItem("storedTime");
      if (!storedTime) {
        localStorage.setItem("storedTime", currentTime.toString());
      } else {
        const storedTimeObj = new Date(storedTime);
        const timeDifference = currentTime - storedTimeObj;
        const fifteenMinutes = 15 * 60 * 1000;
        if (timeDifference >= fifteenMinutes) {
          localStorage.removeItem("storedTime");
          localStorage.removeItem("token");
        } else {
          localStorage.setItem("storedTime", currentTime.toString());
        }
      }
    };
    const intervalId = setInterval(checkTime, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [pathname]);
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0  h-[105px] bg-opacity-60 z-30"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-2 border-Third bg-[#f5f5f5] rounded-3xl z-40 flex justify-center items-center max-sm:w-full`}
          >
            <div className="bg-slate-50 z-[60] rounded-3xl w-[400px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-xl text-center py-7">
                هل انت متأكد من تسجيل الخروج ؟
              </p>
              <div className="flex items-center justify-center gap-3 m-5">
                <ButtonRed
                  className="py-3 !px-"
                  onClick={() => {
                    logout("admin/logout");
                  }}
                  name="نعم"
                />
                <ButtonRed
                  className="mx-2  !px-2  font-semibold border-2 border-Secondary !bg-white !text-Secondary hover:!bg-Secondary hover:!text-white rounded-xl ml-5"
                  onClick={() => setSure(false)}
                  name=" إلغاء"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="container mx-auto sticky w-[98%] top-0 z-[30] bg-white flex items-center justify-between pt-2 flex-wrap mb-5 pb-2  border-Secondary">
        <div>
          <BiMenu
            size={35}
            className="text-[#3DAAC6] cursor-pointer lg:mx-12"
            onClick={changeMenu}
          />
        </div>

        {/* <div className="max-sm:hidden">
          <img src={logo} alt="xx"/>
        </div> */}
        
        <div className="max-sm:flex-1">
          <div className="flex gap-9 mx-3 justify-between max-md:flex max-md:justify-end   ">
            <NotificationNew />
            <div
              className={`bg-[#004B60] rounded-xl hover:bg-opacity-70 text-center font-semibold text-white cursor-pointer w-fit px-5 py-3`}
              onClick={() => setSure(true)}
            >
              تسجيل خروج
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
