import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Input, Title } from "../../components";
import Loading from "../../Tools/Loading";

const AddPayments = () => {
  const { id } = useParams();
  const isAdd = id === "add";
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({});
  const { data, isLoading } = useFETCH(
    isAdd ? "" : `admin/payment/methods/${id}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      isAdd ? "admin/payment/methods" : `admin/payment/methods/${id}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    !isAdd &&
      setFormData({
        name: dataOld?.name,
      });
  }, [dataOld]);
  return (
    <>
      <Row>
        <Col lg={6} md={6} sm={6}>
          <Title title="طريقة الدفع" className="" />
        </Col>
        <Col lg={5} md={5} sm={5}></Col>
        <Col lg={1} md={1} sm={1}>
          <Back />
        </Col>
      </Row>

      <Row className="h-[70vh] flex justify-center items-center">
        <Col
          md={4}
          className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto "
        >
          <Input
            name="name"
            value={formData?.name}
            onChange={handleChangeInput}
            title="ادخل طريقة الدفع"
          />

          <select
            name="type"
            className={`h-[40px] w-full max-md:mx-3 text-Third font-bold text-base  max-md:w-[94%] shadow-Third shadow-sm border-Secondary  rounded-xl outline-none text-center mx-auto `}
            onChange={handleChangeInput}
          >
            <option value="" disabled selected hidden>
              طريقة الدفع
            </option>
            <option value="CURRENCY_EXCHANGE_OFFICE">مكتب صرافة</option>
            <option value="WESTERN_UNION"> ويسترين يونين </option>
            <option value="STRIPE"> سترايب </option>
            <option value="BANK_ACCOUNT"> حساب بنكي </option>
            <option value="OTHER"> أخرى </option>
          </select>

          {loading || (isLoading && !isAdd) ? <Loading /> : ""}

          <ButtonRed
            name={!isAdd ? "تعديل" : "اضافة"}
            onClick={handleSubmitMain}
            className="py-3 mt-3"
          />
        </Col>
      </Row>
      {/* <Back /> */}
    </>
  );
};

export default AddPayments;
