import { IoIosTimer } from "react-icons/io";
import { useLocation, useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import {
  Back,
  DateFilter,
  Table,
  Title,
  WalletFilter
} from "../../components";
import GivingAll from "../../components/Delete/GivingAll";
function Wallet() {
  const { id } = useParams();
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/users/${id}/balance/logs${search}`,
    `admin/users/${id}/balance/logs`
  );
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      amount_type: (
        <td
          className={`${
            e.amount_type === "CHARGE" ? "text-green-500" : "text-red-600"
          } text-center font-bold`}
        >
          {e.amount_type === "CHARGE" ? "+" : "-"} {e.amount}
        </td>
      ),
      balance_after: e.balance_after,
      amount_type_display: e.amount_type_display,
      status: (
        <td className="flex items-center justify-center gap-2 relative bottom-2">
          <p>{e.status === "PENDING" && <IoIosTimer size={20} />}</p>
          {e.status_display}
        </td>
      ),
      created_at: e.created_at.substring(0, 10),
      reason: (
        <div className="min-w-[200px]" cl>
          {e.reason}
        </div>
      ),
      walletStatus: e.status,
    };
  });
  return (
    <Container>
      <Row>
        <Col lg={6} md={6} sm={8}>
          <TitleAdmin id={id} />
        </Col>
        <Col lg={5} md={5} sm={8} className="flex justify-end">
        <GivingAll id={id} />
        </Col>
        <Col lg={1} md={1} sm={1} className="flex justify-center ">
          <Back />
        </Col>
      </Row>
      
      <Col xs={7} md={4} lg={2} className="!mx-6">
        <DateFilter />
      </Col>
      <Col >
        <WalletFilter />
      </Col>
      <Row className="justify-center">
        <Col>
          <div
            className={`flex items-center gap-1 w-[300px] mx-auto font-bold text-Secondary mt-3 `}
          >
            <p className="">الرصيد الحالي :</p>
            <p className="font-normal text-Third flex-1">
              {data?.data?.total_balance}
            </p>
          </div>
        </Col>
      </Row>
     
        
        {/* <GivingAll id={id} /> */}
 
      {isLoading && <Loading />}
      <Table
        thead={[
          "المبلغ",
          "الرصيد الجديد",
          "النوع",
          "حالة السجل",
          "تاريخ المنح/الصرف ",
          "السبب",
        ]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={dataAll}
        tbody={[
          { name: "amount_type" },
          { name: "balance_after" },
          { name: "amount_type_display" },
          { name: "status" },
          { name: "created_at" },
          { name: "reason" },
        ]}
        funDelete={deleteItem}
        walletRestore="true"
        id={id}
      />
      {/* <Back /> */}
    </Container>
  );
}
export default Wallet;

const TitleAdmin = ({ id }) => {
  const { data } = useFETCH(id && `admin/users/${id}`);
  return (
    <>
      <Title title={data?.data.data.name} link="/admins"  />
      <Title ball title="المحفظة" className="!mt-5" />
    </>
  );
};
