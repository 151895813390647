import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Input, Selected, Title } from "../../components";
import Loading from "../../Tools/Loading";
import AddImage from "../../Tools/AddFile";

import { useRef } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { useContextHook } from "../../Context/ContextOPen";
import { Container } from "../../Tools/Grid-system";
import { Data } from "../../components";
import { DataTransaction } from "../Transactions/Payment";
import { BiAddToQueue, BiSearch } from "react-icons/bi";
import { RiArrowDownSLine } from "react-icons/ri";
import { GrAdd, GrAddCircle } from "react-icons/gr";

const AddStudents = () => {
  const { id } = useParams();
  const isAdd = id === "add";
  const nav = useNavigate();
  const [deleteImgs, setDeleteImgs] = useState(false);

  const { data, isLoading } = useFETCH(isAdd ? "" : `admin/students/${id}`);
  const { data: dataAuthentication } = useFETCH(
    `admin/authentication-types?paginate=0`
  );

  const [selectedOptionType, setSelectedOptionType] = useState(-1);

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      isAdd ? "admin/students" : `admin/students/${id}`,
      `/students/transactions/${id}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    !isAdd &&
      setFormData({
        first_name: dataOld?.first_name,
        mid_name: dataOld?.mid_name,
        last_name: dataOld?.last_name,
        phone_number: dataOld?.phone_number,
        email: dataOld?.email,
        degree: dataOld?.degree,
        sitting_number: dataOld?.sitting_number || "",
        status: dataOld?.status || "",
        can_manage_setting_number: dataOld?.can_manage_setting_number,
        authentication_type_id: dataOld?.authentication_type_id,

        trans_date: dataOld?.trans_date,
        total_cost: dataOld?.total_cost,
        discounted_amount: dataOld?.discounted_amount,
        specialty_id: dataOld?.specialty?.id,
        specialty_section_id: dataOld?.specialty?.specialty_section_id,
        degree_id: dataOld?.degree?.id,
        extra_charge: dataOld?.extra_charge,
        extra_charge_reason: dataOld?.extra_charge_reason,

        payment_date: dataOld?.payment_date || "",
        amount: dataOld?.amount || "",
        state: dataOld?.state || "",
        payment_method_id: dataOld?.payment_method?.id || "",
        transferred_by: dataOld?.transferred_by || "",
        email: dataOld?.email || "",
        transfer_number: dataOld?.transfer_number || "",
        transfer_date: dataOld?.transfer_date || "",
        transfer_arrival_date: dataOld?.transfer_arrival_date || "",
        transfer_amount: dataOld?.transfer_amount || "",
        transfer_recipient_id: dataOld?.transfer_recipient_id || "",
        received_amount: dataOld?.received_amount || "",
        notes: dataOld?.notes || "",
        transfer_recipient_commission:
          dataOld?.transfer_recipient_commission || "",
      });
    setSelectedOptionType(dataOld?.payment_method?.type);
  }, [dataOld]);
  let dates = new Date().toISOString().substring(0, 10);
  const { student_id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    handleChangeArrayImages,
    handleCheckedArray,
    setImages,
    setViewImages,
    viewImages,
    images,
    formData,
    loading,
  } = usePOST({
    trans_date: dates,
    payment_date: dates,
    student_id: student_id,
  });

  const { successPost } = useContextHook();

  const selectRef = useRef(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const { data: dataStudents } = useFETCH(`admin/students?paginate=0`);
  const { data: dataSpecialty } = useFETCH(
    `admin/specialty-sections?paginate=0&test=${successPost}`
  );
  const [dataDegrees, setDataDegrees] = useState([]);
  const [degreePrice, setDegreePrice] = useState();
  const { data: specialties } = useFETCH(
    formData?.specialty_section_id
      ? `admin/specialty-sections/${formData?.specialty_section_id}/specialties?paginate=0&test=${successPost}`
      : ""
  );
  const { data: dataPayment } = useFETCH(`admin/payment/methods?paginate=0`);

  useEffect(() => {
    !isAdd &&
      setDegreePrice(
        dataDegrees?.filter((p) => +p.id === +dataOld?.degree?.id)?.[0]?.price
      );
  }, [dataOld, dataDegrees]);

  useEffect(() => {
    !isAdd &&
      setDataDegrees(
        specialties?.data.data.filter(
          (p) => +p.id === +dataOld?.specialty?.id
        )?.[0]?.degrees
      );
  }, [dataOld, specialties]);
  const studentA = dataStudents?.data.data.filter(
    (p) => +p.id === +student_id
  )?.[0];

  const [add, setAdd] = useState(true);
  const [edit, setEdit] = useState(false);
  const [addPayment, setAddPayment] = useState(false);

  return (
    <>
      {/* <Row>
        <Col lg={6} md={6} sm={6}>
          {!isAdd ? (
            <Title title="تعديل الطالب" className="mt-5  " />
          ) : (
            <Title title="إضافة طالب" className="mt-5  " />
          )}
        </Col>
        <Col lg={5} md={5} sm={5}></Col>
        <Col lg={1} md={1} sm={1}>
          <Back />
        </Col>
      </Row> */}

      <Row justify="" className="!mt-5">
        <Col lg={10} sm={11} md={5} className="flex justify-start mx-5 ">
          <div className=" max-md:w-full  ">
            <button
              onClick={() => setAdd(!add)}
              className={`flex items-center justify-between px-4 gap-3 text-sm py-2 rounded-r-full bg-white hover:bg-white text-[#0d5069] w-full ${
                add ? "bg-white  mb-2" : ""
              }`}
            >
              <div className="flex items-center gap-2">
                <GrAddCircle size={22} className="text-[#0d5069]" />

                {!isAdd ? (
                  <span className="text-[#0d5069] text-lg max-md:text-sm font-bold">
                    تعديل الطالب
                  </span>
                ) : (
                  <span className="text-[#0d5069] text-lg max-md:text-sm font-bold">
                    اضافة طالب
                  </span>
                )}
              </div>
              {
                <RiArrowDownSLine
                  size={20}
                  className={`${
                    add
                      ? "rotate-180 transition-all"
                      : "rotate-0 transition-all"
                  } text-[#0d5069]`}
                />
              }
            </button>
          </div>
        </Col>
      </Row>

      {add && (
        <Row
          justify={"center"}
          className="px-2 space-x-1 grid items-center !mt-4"
        >
          <Col
            lg={9}
            md={14}
            className="bg-white border-2 border-Main rounded-2xl p-3   mx-auto"
          >
            <Row justify={"center"} className="!mx-2">
              <Col lg={6} md={3}>
                <Input
                  name="first_name"
                  value={formData?.first_name}
                  onChange={handleChangeInput}
                  title="اسم الاول"
                />
              </Col>
              <Col lg={6} md={3}>
                <Input
                  name="mid_name"
                  value={formData?.mid_name}
                  onChange={handleChangeInput}
                  title="اسم الاب"
                />
              </Col>
              <Col lg={6} md={3}>
                <Input
                  name="last_name"
                  value={formData?.last_name}
                  onChange={handleChangeInput}
                  title="الكنية"
                />
              </Col>
              <Col lg={6} md={3}>
                <Input
                  name="phone_number"
                  value={formData?.phone_number}
                  onChange={handleChangeInput}
                  title="رقم الهاتف"
                />
              </Col>
              <Col lg={6} md={3}>
                <Input
                  name="email"
                  value={formData?.email}
                  onChange={handleChangeInput}
                  title="الإيميل"
                />
              </Col>
              <Col lg={6} md={3}>
                <Input
                  name="degree"
                  value={formData?.degree}
                  onChange={handleChangeInput}
                  title="الدرجة العلمية"
                />
              </Col>
              <Col lg={6} md={3}>
                <Input
                  name="sitting_number"
                  value={formData?.sitting_number}
                  onChange={handleChangeInput}
                  title="رقم الجلوس"
                />
              </Col>
              <Col lg={6} md={3}>
                <Selected
                  text="انواع التصديقات"
                  title="اختر النوع"
                  name="authentication_type_id"
                  value={formData?.authentication_type_id}
                  onChange={handleChangeInput}
                >
                  {dataAuthentication?.data.data?.map((e) => (
                    <option value={e.id}>{e.name}</option>
                  ))}
                </Selected>
              </Col>
              <Col lg={6} md={3}>
                <Selected
                  text="الحالة"
                  title="اختر الحالة"
                  name="status"
                  value={formData?.status}
                  onChange={handleChangeInput}
                >
                  <option value="يدرس">يدرس</option>
                  <option value="متخرج">متخرج</option>
                  <option value="متوقف حالياً ">متوقف حالياً </option>
                  <option value="منسحب">منسحب</option>
                </Selected>
              </Col>
              <Col lg={6} md={3}></Col>

              <Col
                lg={6}
                md={4}
                className="lg:!mt-6 max-md:mx-3 lg:mx-3 mx-auto !p-0"
              >
                <div className="text-start mt-1 lg:mx-40 md:mx-10">
                  (image) (pdf) (file.zip)
                </div>
                <AddImage
                  title="مستندات رسمية"
                  type="IMAGES"
                  id="images"
                  name={"official_documents[]"}
                  onChange={handleChangeArrayImages}
                />
                <Row className="py-4">
                  {viewImages["official_documents[]"] &&
                    viewImages["official_documents[]"]?.map((e, i) => (
                      <Col key={i} md={5} sm={6}>
                        <div className="relative">
                          <span
                            onClick={() => {
                              let total = [
                                ...viewImages["official_documents[]"],
                              ];
                              let total2 = [...images["official_documents[]"]];
                              total.splice(i, 1);
                              total2.splice(i, 1);
                              setViewImages({
                                ...viewImages,
                                "official_documents[]":
                                  total.length === 0 ? [] : total,
                              });
                              setImages({
                                ...images,
                                "official_documents[]":
                                  total2.length === 0 ? [] : total2,
                              });
                            }}
                            className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                          >
                            X
                          </span>
                          {images["official_documents[]"][i]?.type ===
                            "application/pdf" ||
                          images["official_documents[]"][i]?.type ===
                            "application/x-zip-compressed" ? (
                            <div className="w-[140px] h-[140px] rounded-2xl flex justify-center items-center bg-slate-200">
                              {images["official_documents[]"][i]?.name}
                            </div>
                          ) : (
                            <img
                              src={e}
                              alt=""
                              className="w-[140px] h-[140px] rounded-2xl"
                            />
                          )}
                        </div>
                      </Col>
                    ))}
                </Row>
                {!isAdd && dataOld?.official_documents.length !== 0 && (
                  <div>
                    <div
                      onClick={() => setDeleteImgs(!deleteImgs)}
                      className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg  text-white font-semibold bg-Main my-2 ml-auto "
                    >
                      حذف الصور
                    </div>
                    <Row>
                      {dataOld?.official_documents?.map((e, i) => (
                        <Col key={i} md={5} sm={6} className="relative">
                          {deleteImgs && (
                            <input
                              type="checkbox"
                              value={e.id}
                              name="official_docs_to_be_deleted_ids[]"
                              onChange={handleCheckedArray}
                              className="absolute -top-2 left-1 h-5 w-5"
                            />
                          )}
                          <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                            <img
                              src={fileUrl + e.file}
                              alt=""
                              className="w-full h-full object-contain rounded-2xl"
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )}
              </Col>
              <Col md={5} lg={5} className="lg:!mt-6 max-md:mx-3  ">
                <div className="text-start mt-1 lg:mx-28 md:mx-10">
                  (image) (pdf) (file.zip)
                </div>
                <AddImage
                  title="شهادات"
                  type="IMAGES"
                  id="images2"
                  name="certificates[]"
                  onChange={handleChangeArrayImages}
                />
                <Row className="py-4">
                  {viewImages["certificates[]"] &&
                    viewImages["certificates[]"]?.map((e, i) => (
                      <Col key={i} md={5} sm={6}>
                        <div className="relative">
                          <span
                            onClick={() => {
                              let total = [...viewImages["certificates[]"]];
                              let total2 = [...images["certificates[]"]];
                              total.splice(i, 1);
                              total2.splice(i, 1);
                              setViewImages({
                                ...viewImages,
                                "certificates[]":
                                  total.length === 0 ? [] : total,
                              });
                              setImages({
                                ...images,
                                "certificates[]":
                                  total2.length === 0 ? [] : total2,
                              });
                            }}
                            className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                          >
                            X
                          </span>
                          {images["certificates[]"][i]?.type ===
                            "application/pdf" ||
                          images["certificates[]"][i]?.type ===
                            "application/x-zip-compressed" ? (
                            <div className="w-[140px] h-[140px] rounded-2xl flex justify-center items-center bg-slate-200">
                              {images["certificates[]"][i]?.name}
                            </div>
                          ) : (
                            <img
                              src={e}
                              alt=""
                              className="w-[140px] h-[140px] rounded-2xl"
                            />
                          )}
                        </div>
                      </Col>
                    ))}
                </Row>
                {!isAdd && dataOld?.certificates.length !== 0 && (
                  <div>
                    <div
                      onClick={() => setDeleteImgs(!deleteImgs)}
                      className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg  text-white font-semibold bg-Main my-2 ml-auto "
                    >
                      حذف الصور
                    </div>
                    <Row>
                      {dataOld?.certificates?.map((e, i) => (
                        <Col key={i} md={5} sm={6} className="relative">
                          {deleteImgs && (
                            <input
                              type="checkbox"
                              value={e.id}
                              name="certificates_to_be_deleted_ids[]"
                              onChange={handleCheckedArray}
                              className="absolute -top-2 left-1 h-5 w-5"
                            />
                          )}
                          <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                            <img
                              src={fileUrl + e.file}
                              alt=""
                              className="w-full h-full object-contain rounded-2xl"
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )}
              </Col>
              {/* <Col md={5}>
              <ButtonRed
                name={!isAdd ? "تعديل" : "اضافة"}
                onClick={handleSubmitMain}
                className="py-3 mt-3"
              />
            </Col> */}
            </Row>
          </Col>
        </Row>
      )}

      {isAdd && (
        <>
          <Row justify="" className="!mt-5">
            <Col lg={10} sm={11} md={5} className="flex justify-start mx-5 ">
              <div className=" max-md:w-full  ">
                <button
                  onClick={() => setEdit(!edit)}
                  className={`flex items-center justify-between px-4 gap-3 text-sm py-2 rounded-r-full bg-white hover:bg-white text-[#0d5069] w-full ${
                    edit ? "bg-white  mb-2" : ""
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <GrAddCircle size={22} className="text-[#0d5069]" />
                    <span className="text-[#0d5069] text-lg max-md:text-sm font-bold">
                      انشاء ملف مالي
                    </span>
                  </div>
                  {
                    <RiArrowDownSLine
                      size={20}
                      className={`${
                        edit
                          ? "rotate-180 transition-all"
                          : "rotate-0 transition-all"
                      } text-[#0d5069]`}
                    />
                  }
                </button>
              </div>
            </Col>
          </Row>

          {edit && (
            <Container>
              {loading || (isLoading && !isAdd) ? <Loading /> : ""}
              {/* <Row>
             <Col lg={12} md={6} sm={6}>
               {!isAdd ? (
                 <Title title="تعديل الملف المالي " className="mt-5 flex justify-center" />
               ) : (
                 <Title title="انشاء الملف المالي " className="mt-5 flex justify-center" />
               )}
             </Col>
           </Row> */}

              <Col
                md={11}
                lg={9}
                className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto "
              >
                <Row className="items-center justify-center ">
                  <Col md={6} className="transition-all !mt-2  ">
                    <Input
                      type="date"
                      name="trans_date"
                      value={formData?.trans_date ? formData.trans_date : dates}
                      onChange={handleChangeInput}
                      title="تاريخ الملف المالي "
                    />
                  </Col>
                  <Col md={6} className="transition-all">
                    <div className="flex justify-between items-center">
                      <h1 className={`text-start mb-2 text-Third font-bold`}>
                        الكليات
                      </h1>
                      <AddSpecialties />
                    </div>
                    <select
                      style={{
                        boxShadow:
                          "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        border: "1px solid black",
                      }}
                      text="الكليات"
                      title="اختر الكلية"
                      name="specialty_section_id"
                      value={formData?.specialty_section_id}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          specialty_section_id: e.target.value,
                          specialty_id: "",
                          degree_id: "",
                          discounted_amount: "",
                        });
                        setDataDegrees([]);
                        setDegreePrice();
                      }}
                      className={`w-full border-2 border-Secondary py-3 rounded-xl  outline-none text-center mx-auto`}
                    >
                      <option value="" disabled selected hidden>
                        اختر الكلية
                      </option>
                      {dataSpecialty?.data.data.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  {formData?.specialty_section_id && (
                    <Col md={6} className="transition-all">
                      <div className="my-3">
                        <div className="flex justify-between items-center">
                          <h1 className="text-start mb-2 text-Third font-bold">
                            التخصصات
                          </h1>
                          <AddSpecialtie id={formData?.specialty_section_id} />
                        </div>
                        <select
                          style={{
                            boxShadow:
                              "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            border: "1px solid black",
                          }}
                          name="specialty_id"
                          value={formData?.specialty_id}
                          onChange={(e) => {
                            setFormData((prevFormData) => {
                              const {
                                degree_id,
                                discounted_amount,
                                ...formData
                              } = prevFormData;
                              return {
                                ...formData,
                                specialty_id: e.target.value,
                                degree_id: "",
                                discounted_amount: "",
                              };
                            });
                            setDegreePrice();
                            setDataDegrees(
                              specialties?.data.data.filter(
                                (p) => +p.id === +e.target.value
                              )?.[0].degrees
                            );
                          }}
                          className={`w-full border-2 border-Secondary py-3 rounded-xl  outline-none text-center mx-auto`}
                        >
                          <option value="" disabled selected hidden>
                            اختر التخصص
                          </option>

                          {specialties?.data.data.map((e) => (
                            <option key={e.id} value={e.id}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  )}

                  {dataDegrees?.length > 0 && (
                    <Col md={6} className="transition-all !mt-1">
                      <div className="my-3">
                        <div className="flex justify-between items-center">
                          <h1 className="text-start mb-2 text-Third font-bold">
                            {" "}
                            درجة العلمية{" "}
                          </h1>
                        </div>
                        <select
                          style={{
                            boxShadow:
                              "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            border: "1px solid black",
                          }}
                          name="degree_id"
                          value={formData?.degree_id}
                          onChange={(e) => {
                            setFormData((prevFormData) => {
                              const { discounted_amount, ...formData } =
                                prevFormData;
                              return {
                                ...formData,
                                degree_id: e.target.value,
                              };
                            });
                            setDegreePrice(
                              dataDegrees?.filter(
                                (p) => +p.id === +e.target.value
                              )?.[0]?.price
                            );
                          }}
                          className={`w-full border-2 border-Secondary py-3 rounded-xl  outline-none text-center mx-auto`}
                        >
                          <option value="" disabled selected hidden>
                            اختر الدرجة العلمية
                          </option>

                          {dataDegrees?.map((e) => (
                            <option key={e.id} value={e.id}>
                              {e.name} - {e.price}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  )}
                  {degreePrice && (
                    <>
                      <Col md={6} className="transition-all">
                        <div className="my-3">
                          <h1 className="text-start mb-2 text-Third font-bold">
                            قيمة الملف المالي{" "}
                          </h1>
                          <input
                            style={{
                              boxShadow:
                                "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              border: "1px solid black",
                            }}
                            readOnly
                            type="number"
                            value={degreePrice}
                            placeholder="قيمة الملف المالي "
                            className="w-full border-2 border-Secondary text-center py-3 rounded-xl"
                          />
                        </div>
                      </Col>
                      <Col md={6} className="transition-all !mt-2">
                        <Input
                          type="number"
                          name="discounted_amount"
                          value={formData?.discounted_amount}
                          onChange={handleChangeInput}
                          title="قيمة الخصم من سعر الإختصاص"
                        />
                      </Col>
                      <Col md={6} className="transition-all">
                        <div className="my-3">
                          <h1 className="text-start mb-2 text-Third font-bold">
                            رسوم اضافية{" "}
                          </h1>
                          <input
                            style={{
                              boxShadow:
                                "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              border: "1px solid black",
                            }}
                            type="number"
                            onChange={handleChangeInput}
                            name="extra_charge"
                            value={formData?.extra_charge}
                            placeholder="  رسوم اضافية "
                            className="w-full border-2 border-Secondary text-center py-3 rounded-xl"
                          />
                        </div>
                      </Col>
                      <Col md={6} className="transition-all">
                        <div className="my-3">
                          <h1 className="text-start mb-2 text-Third font-bold">
                            {" "}
                            سبب الرسوم{" "}
                          </h1>
                          <input
                            style={{
                              boxShadow:
                                "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              border: "1px solid black",
                            }}
                            name="extra_charge_reason"
                            onChange={handleChangeInput}
                            value={formData?.extra_charge_reason}
                            type="text"
                            // value={}
                            placeholder="  سبب الرسوم "
                            className="w-full border-2 border-Secondary text-center py-3 rounded-xl"
                          />
                        </div>
                      </Col>
                    </>
                  )}
                  {isAdd && (
                    <Row justify="center">
                      <Col
                        md={7}
                        lg={12}
                        className="flex items-center justify-start gap-3 my-4  font-semibold"
                      >
                        <input
                          type="checkbox"
                          checked={isCheckboxChecked}
                          onChange={(event) =>
                            setIsCheckboxChecked(event.target.checked)
                          }
                          className="w-6 h-6"
                        />
                        <p>إضافة دفعات مجدولة تلقائية</p>
                      </Col>
                      <Col md={9} />
                      {isCheckboxChecked && (
                        <>
                          <Col md={4} className="transition-all">
                            <Input
                              type="number"
                              name="first_payment_amount"
                              onChange={handleChangeInput}
                              title="قيمة الدفعة الأولى"
                            />
                          </Col>
                          <Col md={4} className="transition-all">
                            <Input
                              type="number"
                              name="payments_count"
                              onChange={handleChangeInput}
                              title="عدد الدفعات المجدولة"
                              className=""
                            />
                          </Col>
                          <Col md={4} className="transition-all">
                            <Input
                              type="number"
                              name="payment_gap_days"
                              onChange={handleChangeInput}
                              title="المدة الزمنة بين الدفعات بالأيام"
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  )}
                  {/* <h2 className="text-xl font-extrabold text-center !my-5">
                 معلومات الطالب
               </h2>
               {isAdd && (
                 <>
                   <Col md={6} className="transition-all mb-20">
                     <h1 className={`text-start mb-2`}> اسم الطالب </h1>
                     <Input
                       readOnly={true}
                       value={
                         studentA?.first_name +
                         " " +
                         studentA?.mid_name +
                         " " +
                         studentA?.last_name
                       }
                     />
                   </Col>
                   <Col md={6} />
                 </>
               )} */}
                  {!isAdd && <AddStudents id={dataOld?.student?.id} />}
                  {/* <Col sm={6} className="mx-auto">
                 <ButtonRed
                   name={!isAdd ? "تعديل" : "اضافة"}
                   onClick={handleSubmitMain}
                   className="py-3"
                 />
               </Col> */}
                </Row>
              </Col>
            </Container>
          )}
        </>
      )}

      {/* <Row>
      <Col lg={12} md={6} sm={6}>
         <Title title={!isAdd ? "تعديل الدفعة" : "انشاء دفعة"} className="mt-5 flex justify-center" />
      </Col>
      <Col lg={5} md={5} sm={5}>
      </Col>
     
    </Row> */}

      {isAdd && (
        <>
          <Row justify="" className="!mt-5">
            <Col lg={10} sm={11} md={5} className="flex justify-start mx-5 ">
              <div className=" max-md:w-full  ">
                <button
                  onClick={() => setAddPayment(!addPayment)}
                  className={`flex items-center justify-between px-4 gap-3 text-sm py-2 rounded-r-full bg-white hover:bg-white text-[#0d5069] w-full ${
                    addPayment ? "bg-white  mb-2" : ""
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <GrAddCircle size={22} className="text-[#0d5069]" />
                    <span className="text-[#0d5069] text-lg max-md:text-sm font-bold">
                      انشاء دفعة
                    </span>
                  </div>
                  {
                    <RiArrowDownSLine
                      size={20}
                      className={`${
                        addPayment
                          ? "rotate-180 transition-all"
                          : "rotate-0 transition-all"
                      } text-[#0d5069]`}
                    />
                  }
                </button>
              </div>
            </Col>
          </Row>

          {addPayment && (
            <>
              {/* <DataTransaction id={id} /> */}
              <Col lg={9} sm={11} className="mx-auto">
                <Row className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto justify-center">
                  <Col lg={4} md={4}>
                    <Input
                      type="date"
                      title="تاريخ الدفعة"
                      name="payment_date"
                      value={
                        formData?.payment_date ? formData.payment_date : dates
                      }
                      onChange={handleChangeInput}
                    />
                  </Col>
                  <Col lg={4} md={4}>
                    <Input
                      type="number"
                      title="قيمة الدفعة"
                      name="amount"
                      value={formData?.amount}
                      onChange={handleChangeInput}
                    />
                  </Col>
                  <Col lg={4} md={4}>
                    <Selected
                      text="طريقة الدفع"
                      title="اختر طريقة الدفع"
                      name="payment_method_id"
                      value={formData?.payment_method_id}
                      onChange={(e) => {
                        handleChangeInput(e);
                        setSelectedOptionType(
                          e.target.options[e.target.selectedIndex].dataset.type
                        );
                      }}
                    >
                      {dataPayment?.data?.data?.map((e) => (
                        <option key={e.id} value={e.id} data-type={e.type}>
                          {e.name}
                        </option>
                      ))}
                    </Selected>
                  </Col>

                  <h1 className="text-xl !my-5 font-extrabold text-Third text-center  ">
                    معلومات الحوالة
                  </h1>

                  {selectedOptionType === "STRIPE" && (
                    <Col lg={4} md={4}>
                      <Input
                        title=" أيميل "
                        name="email"
                        value={formData?.email}
                        onChange={handleChangeInput}
                      />
                    </Col>
                  )}

                  <Col lg={4} md={4}>
                    <Input
                      title="اسم الشخص الذي حوّل الحوالة"
                      name="transferred_by"
                      value={formData?.transferred_by}
                      onChange={handleChangeInput}
                    />
                  </Col>
                  {selectedOptionType === "CURRENCY_EXCHANGE_OFFICE" ||
                    selectedOptionType === "WESTERN_UNION" ||
                    (selectedOptionType === "BANK_ACCOUNT" && (
                      <Col lg={4} md={3}>
                        <Input
                          type="text"
                          title="رقم الحوالة"
                          name="transfer_number"
                          value={formData?.transfer_number}
                          onChange={handleChangeInput}
                        />
                      </Col>
                    ))}
                  <Col lg={4} md={4}>
                    <Input
                      type="date"
                      title="تاريخ الحوالة"
                      name="transfer_date"
                      value={formData?.transfer_date}
                      onChange={handleChangeInput}
                    />
                  </Col>
                  {selectedOptionType === "BANK_ACCOUNT" && (
                    <Col
                      lg={4}
                      md={4}
                      className={`${
                        localStorage?.getItem("role_name") ===
                          "financial_collection_officer" ||
                        localStorage?.getItem("role_name") ===
                          "register_manager"
                          ? "hidden"
                          : ""
                      }`}
                    >
                      <Input
                        type="date"
                        title="تاريخ وصول الحوالة (اذا كانت لحساب بنكي)"
                        name="transfer_arrival_date"
                        value={formData?.transfer_arrival_date}
                        onChange={handleChangeInput}
                      />
                    </Col>
                  )}
                  {selectedOptionType === "CURRENCY_EXCHANGE_OFFICE" ||
                  selectedOptionType === "WESTERN_UNION" ? (
                    <>
                      <Col lg={4} md={4}>
                        <Selected
                          name="transfer_recipient_id"
                          value={formData?.transfer_recipient_id}
                          onChange={handleChangeInput}
                          text="اسم مستلم الحوالة "
                          title="اختر اسم مستلم الحوالة "
                        >
                          {/* {dataAdmins?.data.data?.map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.name}
                    </option>
                  ))} */}
                        </Selected>
                      </Col>
                      {localStorage?.getItem("role_name") ===
                        "financial_manager" ||
                      localStorage?.getItem("role_name") === "super_admin" ? (
                        <Col lg={4} md={4}>
                          <Input
                            type="number"
                            title="عمولة مستلم الحوالة"
                            name="transfer_recipient_commission"
                            value={formData?.transfer_recipient_commission}
                            onChange={handleChangeInput}
                          />
                        </Col>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <Col lg={4} md={4}>
                    <Input
                      type="number"
                      title="قيمة الحوالة"
                      name="transfer_amount"
                      value={formData?.transfer_amount}
                      onChange={handleChangeInput}
                    />
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    className={`${
                      localStorage?.getItem("role_name") ===
                        "financial_collection_officer" ||
                      localStorage?.getItem("role_name") === "register_manager"
                        ? "hidden"
                        : ""
                    }`}
                  >
                    <Input
                      type="number"
                      title="المبلغ الذي وصل"
                      name="received_amount"
                      value={formData?.received_amount}
                      onChange={handleChangeInput}
                    />
                  </Col>
                  {localStorage?.getItem("role_name") === "register_manager" ||
                  localStorage?.getItem("role_name") ===
                    "financial_collection_officer" ? (
                    ""
                  ) : (
                    <Col lg={4} md={4}>
                      <Selected
                        name="state"
                        value={formData?.state}
                        onChange={handleChangeInput}
                        text="حالة الدفعة"
                        title="اختر حالة الدفعة"
                        optionValue="true"
                      >
                        <option value="2">مقبولة</option>
                      </Selected>
                    </Col>
                  )}

                  <Col lg={4} md={4}>
                    <Input
                      title="ملاحظات"
                      name="notes"
                      value={formData?.notes}
                      onChange={handleChangeInput}
                    />
                  </Col>
                  <Col lg={4} md={4}>
                    <div>
                      <h1 className="text-center mt-2  ">أضف صورة</h1>
                      <AddImage
                        type="file"
                        name="transfer_image"
                        // newImage={viewFile}
                        oldImage={
                          dataOld?.transfer_image
                            ? fileUrl + dataOld?.transfer_image
                            : ""
                        }
                        onChange={handleChangeInput}
                        title="أضف صورة"
                        clickDeleteImage={() => {
                          // setViewFile("");
                          setFormData({ ...formData, transfer_image: "" });
                        }}
                      />
                    </div>
                  </Col>

                  {!isAdd &&
                  dataOld?.fco?.id === localStorage.getItem("admin_id") ? (
                    <h2 className="text-center text-green-600 font-semibold">
                      تم تحصيل الحوالة من قبلك
                    </h2>
                  ) : !isAdd && dataOld?.fco?.name ? (
                    <Col lg={4} md={4}>
                      <Data
                        name=" تم تحصيل الحوالة بواسطة "
                        text={dataOld?.fco?.name}
                      />
                    </Col>
                  ) : (
                    !isAdd && (
                      <h2 className="text-center text-orange-600 font-semibold">
                        لم يتم تحصيل الحوالة بعد .
                      </h2>
                    )
                  )}

                  {/* <Row>
            <Col md={4} className="mx-auto">
              {loading || (isLoading && !isAdd) ? <Loading /> : ""}
              <ButtonRed
                name={!isAdd ? "تعديل" : "اضافة"}
                onClick={handleSubmitMain}
                className="py-3 mt-3"
              />
            </Col>
          </Row> */}
                </Row>
              </Col>

              {loading || (isLoading && !isAdd) ? <Loading /> : ""}
            </>
          )}
        </>
      )}
      <Row justify={"center"}>
        <Col lg={4} md={4} className=" mb-6">
          {loading || (isLoading && !isAdd) ? <Loading /> : ""}
          <ButtonRed
            name={!isAdd ? "تعديل" : "حفظ"}
            onClick={handleSubmitMain}
            className="py-3 mt-3 "
          />
        </Col>
      </Row>
    </>
  );
};

export default AddStudents;

const AddSpecialtie = ({ id }) => {
  const {
    handleChangeInput,
    handleSubmit,
    successfulResponse,
    setSuccessfulResponse,
    loading,
    handleChangeObject,
    handleAddObject,
    setDataDegrees,
    dataDegrees,
    setObj,
    obj,
  } = usePOST({});
  const { data: dataAllDegrees } = useFETCH(`admin/degrees?paginate=0`);
  return (
    <>
      <div>
        {successfulResponse && (
          <>
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
              onClick={() => setSuccessfulResponse(false)}
            ></div>
            <div
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center `}
            >
              {loading ? <Loading /> : ""}
              <div className="bg-white z-[40] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
                <p className="font-semibold text-xl text-center py-7 w-[90%] mx-auto">
                  <Input
                    name="name"
                    onChange={handleChangeInput}
                    title="اسم التخصص"
                  />
                  <Row justify="center" className="items-center ">
                    <Col xs={7}>
                      <Input
                        type="number"
                        name="price"
                        value={obj?.price}
                        onChange={handleChangeObject}
                        title="السعر"
                      />
                    </Col>
                    <Col xs={7}>
                      <div className="">
                        <h1 className={`text-start mb-2 `}>الدرجة العلمية</h1>
                        <select
                          name="id"
                          value={obj?.id}
                          onChange={handleChangeObject}
                          className={`w-full border-2 border-Secondary py-3 rounded-xl  outline-none text-center mx-auto `}
                        >
                          <option value="" disabled selected hidden>
                            اختر الدرجة العلمية
                          </option>

                          {dataAllDegrees?.data.data
                            .filter(
                              (i) => !dataDegrees?.some((d) => +d.id === +i.id)
                            )
                            .map((k, i) => (
                              <option key={i} value={k.id}>
                                {k.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Col>
                    <Col xs={5}>
                      <ButtonRed
                        name="حفظ"
                        onClick={handleAddObject}
                        className="w-fit px-5 py-2 mb-2 mx-auto"
                      />
                    </Col>
                    <Col xs={8}></Col>
                    {dataDegrees?.map((e, i) => (
                      <Col
                        key={i}
                        md={7}
                        sm={12}
                        className="border rounded-xl border-Main flex justify-between mx-1"
                      >
                        <div>
                          <Data
                            name="الدرجة العلمية"
                            className="!text-sm"
                            text={
                              dataAllDegrees?.data.data?.filter(
                                (p) => +p.id === +e.id
                              )?.[0]?.name
                            }
                          />
                          <Data
                            className="!text-sm"
                            name="السعر"
                            text={e.price}
                          />
                        </div>
                        <div>
                          <ButtonRed
                            name=" تعديل"
                            onClick={() => {
                              let thisData = [...dataDegrees];
                              let x = thisData.splice(i, 1);
                              setDataDegrees(thisData);

                              setObj({
                                price: x[0].price,
                                id: x[0].id,
                              });
                            }}
                            className="w-fit !px-1 !py-1 !my-1 mx-auto !bg-Main"
                          />
                          <ButtonRed
                            name="حذف"
                            onClick={() => {
                              let total = [...dataDegrees];
                              total.splice(i, 1);
                              setDataDegrees(total);
                            }}
                            className="w-fit !px-1 !py-1 !my-1 mx-auto !bg-red-600"
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </p>
                <div className="flex items-center justify-center m-5">
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() =>
                        handleSubmit(
                          `admin/specialty-sections/${id}/specialties`,
                          "",
                          true
                        )
                      }
                    >
                      إضافة
                    </div>
                  </div>
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() => setSuccessfulResponse(false)}
                    >
                      إلغاء
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-fit">
          <FaPlusCircle
            className="cursor-pointer text-Main mb-1  font-bold"
            size={28}
            onClick={() => setSuccessfulResponse(true)}
          />
        </div>
      </div>
    </>
  );
};

const AddSpecialties = () => {
  const {
    handleSubmit,
    handleChangeInput,
    successfulResponse,
    setSuccessfulResponse,
    loading,
  } = usePOST({});
  return (
    <>
      <div>
        {successfulResponse && (
          <>
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
              onClick={() => setSuccessfulResponse(false)}
            ></div>
            <div
              className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center `}
            >
              {loading ? <Loading /> : ""}
              <div className="bg-white z-[60] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
                <p className="font-semibold text-xl text-center py-7 w-[90%] mx-auto">
                  <Input
                    name="name"
                    onChange={handleChangeInput}
                    title="اسم القسم"
                  />
                </p>
                <div className="flex items-center justify-center m-5">
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() =>
                        handleSubmit("admin/specialty-sections", "", true)
                      }
                    >
                      إضافة
                    </div>
                  </div>
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() => setSuccessfulResponse(false)}
                    >
                      إلغاء
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-fit ">
          <FaPlusCircle
            className="cursor-pointer text-Main mb-1 font-bold"
            size={28}
            onClick={() => setSuccessfulResponse(true)}
          />
        </div>
      </div>
    </>
  );
};
