import { Link } from "react-router-dom";
import { Col, Row } from "../../Tools/Grid-system";

const Add = ({ link }) => {
  return (
    <>
      <Link to={link || ""}>
        {/* <div
          className={`flex z-10 justify-center items-center fixed bottom-10 left-5 font-bold text-5xl bg-Secondary text-white cursor-pointer w-14 h-14 rounded-full`}
        >
          +
        </div> */}

        <Row>
          <Col lg={5} >
          <div
          className={`flex z-10 h-[40px]  items-center justify-around shadow-Third shadow-sm   font-bold text-xl bg-white rounded-2xl text-[#0d5069] cursor-pointer w-[180px] py-2  `}
        >
          <div>
             اضافة
          </div>

          <div>
            +
          </div>
         
          
        </div>

          </Col>
        </Row>
      </Link>
    </>
  );
};

export default Add;
