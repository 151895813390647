import React from "react";
import { Col } from "../../Tools/Grid-system";
import { useLocation } from "react-router-dom";
import { useFilter } from "../../Tools/APIs";

const DeleteParamsAll = () => {
  const { handleParamsDeleteAll } = useFilter();
  const { search } = useLocation();
  return (
    <Col lg={2} md={3} sm={10} className="flex md:justify-center  max-sm:mx-auto mx-5  ">
      <div
        className={`h-[49px] border-2  max-sm:mx-0 font-bold  text-lg max-md:text-lg flex items-center justify-center lg:w-[210px] md:w-[210px]  max-md:w-full rounded-xl hover:bg-opacity-70 text-center shadow-Third shadow-sm  cursor-pointer px-5 py-[0.8rem]
       ${search ? " bg-white text-Third " : "text-Third bg-white "} `}
        onClick={() => handleParamsDeleteAll()}
      >
        الكل
      </div>
    </Col>
  );
};

export default DeleteParamsAll;
