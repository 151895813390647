import { useLocation, useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import { Container } from "../../Tools/Grid-system";
import { Add, Back, Table, Title } from "../../components";
import Loading from "../../Tools/Loading";

function SingleSpecialties() {
  const { id } = useParams();
  const { search } = useLocation();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/specialty-sections/${id}/specialties?page=${search}`,
    `admin/specialty-sections/${id}/specialties`
  );
  return (
    <Container>
      <Title title="الكليات" link="/specialties" />
      <Title ball title="التخصصات" />
      {isLoading ? <Loading /> : ""}
      <Table
        showDe={true}
        thead={["اسم التخصص", "رقم التخصص (id)"]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[{ name: "name" }, { name: "specialty_section_id" }]}
        funDelete={deleteItem}
        edit={`specialties/${id}/single-specialties`}
      />
      <Add link={`/specialties/${id}/single-specialties/add`} />
      <Back />
    </Container>
  );
}
export default SingleSpecialties;
