import { useEffect, useState } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, ButtonRed, Data, MapSelect, Title } from "../../components";

function Templates() {
  const [show, setShow] = useState(false);
  const { handleChangeInput, handleSubmit, formData, setFormData, loading } =
    usePOST({});
  const { data, isLoading } = useFETCH(
    formData.template_type ? `admin/templates/${formData.template_type}` : ""
  );
  let dataOld = data?.data.data;
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/templates/${formData.template_type}`, "", true);
  };
  useEffect(() => {
    setFormData({
      text: dataOld?.text,
      template_type: formData.template_type,
    });
  }, [dataOld, formData.template_type]);

  let options = [
    { name: "قالب رسالة التذكير", value: "reminder" },
    { name: "قالب رسالة الإنذار", value: "warning" },
  ];
  return (
    <div>
      <Row>
        <Col lg={6}> 
        <Title title="القوالب" />
        </Col>

        <Col lg={5}>
        </Col>

        <Col lg={1} className="flex justify-center ">
        <Back />
        </Col>
      </Row>
     
      <Col md={6} sm={9} className="mx-auto">
        <MapSelect
          name="template_type"
          value={formData?.template_type}
          onChange={handleChangeInput}
          title="اختر نوع القالب"
          options={options}
          nameOption="name"
          valueOption="value"
          className="!py-1 !border"
        />
      </Col>
      <Col sm={9} className="mx-auto">
        {formData.template_type && (
          <>
            <div className="bg-gray-200 p-5 rounded-2xl w-fit my-5">
              <Data name="اسم الطالب" text="STUDENT_NAME" />
              <Data name="رقم الجلوس" text="SITTING_NUMBER" />
              <Data name="التخصص" text="SPECIALTY" />
              <Data name="تاريخ الدفعة المتخلف عنها" text="LAST_PAYMENT_DATE" />
              <Data name="تاريخ آخر فرصة للتسديد" text="LAST_CHANCE_DATE" />
              <Data name="المبلغ الإجمالي" text="TOTAL_AMOUNT" />
              <p className="my-3">
                لاستخدام أي من المتغيرات التالية يجب وضعهم ضمن
                <span className="text-Fourth px-1">
                  &#123;&#123; &#125;&#125;
                </span>
                <span className="text-red-500"> مثال : </span>
                عزيري الطالب
                <span className="text-Fourth px-2">
                  &#123;&#123;
                  <span className="text-Third font-bold px-1">
                    STUDENT_NAME
                  </span>
                  &#125;&#125;
                </span>
                يجب تسديد القسط قبل تاريخ
                <span className="text-Fourth px-2">
                  &#123;&#123;
                  <span className="text-Third font-bold px-1">
                    LAST_CHANCE_DATE
                  </span>
                  &#125;&#125;
                </span>
                وهكذا لبقية المتغيرات المتاحة .
              </p>
            </div>
            <textarea
              type="text"
              name="text"
              value={formData?.text}
              onChange={(e) => {
                handleChangeInput(e);
                setShow(true);
              }}
              placeholder="النص"
              className="border-2 border-Secondary outline-none m-1 py-3 rounded-xl block w-full px-3 h-[200px]"
            />
            {show && (
              <ButtonRed
                name="حفظ"
                onClick={handleSubmitMain}
                className="py-3 mt-3 !w-[200px] mx-auto"
              />
            )}
          </>
        )}
      </Col>
      {loading || (formData.template_type && isLoading) ? <Loading /> : ""}
    </div>
  );
}
export default Templates;
