import { useFilter } from "../../Tools/APIs";

function PaymentFilter() {
  const { searchParams, handleParamsClick, handleParamsDelete,handleParamsDeleteAll } = useFilter();
  return (
    <div >
      <select
        value={searchParams.get("state")}
        onChange={(e) => {
          if (!e.target.value) {
            handleParamsDelete("state");
          } else {
            handleParamsClick("state", e.target.value);
          }
        }}
        className="w-full bg-white font-bold mx-auto  text-base shadow-sm shadow-Third rounded-xl py-2 text-center px-5 text-Third outline-none"
      >
        <option value="">
          الكل
        </option>
        <option value="2">مقبول</option>
        <option value="1">غير مقبول</option>
      </select>
    </div>
  );
}
export default PaymentFilter;
