import { useState } from "react";
import CommissionBtn from "./CommissionBtn";
import IconHover from "../IconHover/IconHover";
import { useClose } from "../../Tools/APIs";
const Popup = ({
  description,
  children,
  desClass,
  className,
  btn,
  onClick,
  Icon,
  iconColor,
  text,
  id,
}) => {
  const [sure, setSure] = useState(false);
  return (
    <div>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-20"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg/black/60 z-20 flex justify-center items-center max-sm:w-full`}
          >
            <div
              className={`bg-white !z-10 px-2 rounded-3xl w-fit h-fit py-7 max-h-[90vh] scroll overflow-y-auto sidebar max-sm:mx-6 ${className}`}
            >
              {Icon && <Icon size={70} className={`mx-auto ${iconColor}`} />}
              {description && (
                <p
                  className={`font-semibold text-2xl text-black text-center px-4 ${desClass}`}
                >
                  {description}
                </p>
              )}
              {btn && <CommissionBtn />}
              {onClick && (
                <div className="flex items-end justify-center gap-5">
                  <div
                    className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={()=>{
                      onClick() 
                      setSure(false)
                    }}
                  >
                    نعم
                  </div>
                  <div
                    className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={() => setSure(false)}
                  >
                    تراجع
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div
        onClick={() => {
          setSure(true);
        }}
      >
        {text ? (
          <IconHover id={id} text={text}>
            {children}
          </IconHover>
        ) : children
        }
      </div>
    </div>
  );
};

export default Popup;
