import { Popup } from "react-leaflet";

function PopupTable({ text }) {
  return (
    <div>
      <Popup
        hide={true}
        hover={true}
        description={text}
        className="min-w-[300px]"
        desClass="text-lg"
      >
        <div className="text-sm cursor-pointer">
          <p className="w-20 mx-auto h-5 overflow-hidden">{text} </p>
          <p className="text-[12px] text-Third">قراءة المزيد</p>
        </div>
      </Popup>
    </div>
  );
}
export default PopupTable;
