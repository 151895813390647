import { useEffect } from "react";
import { usePOST } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { ButtonRed } from "../../components";
import Input from "../../components/Input/Input";
function ForgetPassword() {
  const { handleSubmit, loading, handleChangeInput, formData } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/send/verification-code", "/verification-code");
  };
  useEffect(() => {
    sessionStorage.setItem("email", formData?.email);
  }, [formData]);
  return (
    <Container>
      <Row className="h-[70vh] flex justify-center items-center">
        <Col
          md={6}
          className="mx-auto space-y-3 bg-white p-8 rounded-3xl border-t shadow-xl "
        >
          <h1 className="text-2xl font-semibold text-center">
            هل نسيت كلمة المرور
          </h1>
          <Input
            name="email"
            onChange={handleChangeInput}
            title="ادخل البريد الالكتروني"
          />
          {loading ? <Loading /> : ""}

          <ButtonRed
            className="mt-3"
            name="التالي"
            onClick={handleSubmitMain}
          />
        </Col>
      </Row>
    </Container>
  );
}
export default ForgetPassword;
