import { useEffect, useRef, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs";
import AddImage from "../../Tools/AddFile";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import {
  Back,
  ButtonRed,
  Data,
  Input,
  Selected,
  Title,
} from "../../components";
const AddTransactions = () => {
  const { setMessage } = useContextHook();
  const { student_id, id } = useParams();
  const isAdd = id === "add";
  const { successPost } = useContextHook();
  let dates = new Date().toISOString().substring(0, 10);
  const selectRef = useRef(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({ trans_date: dates, student_id: student_id });
  const { data: dataStudents } = useFETCH(`admin/students?paginate=0`);
  const { data: dataSpecialty } = useFETCH(
    `admin/specialty-sections?paginate=0&test=${successPost}`
  );
  const [dataDegrees, setDataDegrees] = useState([]);
  const [degreePrice, setDegreePrice] = useState();
  const { data: specialties } = useFETCH(
    formData?.specialty_section_id
      ? `admin/specialty-sections/${formData?.specialty_section_id}/specialties?paginate=0&test=${successPost}`
      : ""
  );
  const { data, isLoading } = useFETCH(isAdd ? "admin/transactions" : `admin/transactions/${id}`);
  
  const handleSubmitMain = (e) => {
    e.preventDefault();
    if (
      formData?.discounted_amount >= +selectRef.current?.options[selectRef.current.selectedIndex]?.dataset.mode
    ) {
      setMessage(["يجب أن يكوم الخصم أقل من قيمة الملف المالي "]);
    } else {
      handleSubmit(isAdd ? `admin/transactions` : `admin/transactions/${id}`);
    }
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    !isAdd &&
      setFormData({
        trans_date: dataOld?.date,
        total_cost: dataOld?.total_cost,
        discounted_amount: dataOld?.discounted_amount,
        specialty_id: dataOld?.specialty?.id,
        specialty_section_id: dataOld?.specialty?.specialty_section_id,
        degree_id: dataOld?.degree?.id,
        extra_charge: dataOld?.extra_charge,
        extra_charge_reason: dataOld?.extra_charge_reason,
      });
  }, [dataOld]);

  useEffect(() => {
    !isAdd &&
      setDegreePrice(
        dataDegrees?.filter((p) => +p.id === +dataOld?.degree?.id)?.[0]?.price
      );
  }, [dataOld, dataDegrees]);

  useEffect(() => {
    !isAdd &&
      setDataDegrees(
        specialties?.data.data.filter(
          (p) => +p.id === +dataOld?.specialty?.id
        )?.[0]?.degrees
      );
  }, [dataOld, specialties]);
  const studentA = dataStudents?.data.data.filter( (p) => +p.id === +student_id)?.[0];

 
  return (
    <Container>
      {loading || (isLoading && !isAdd) ? <Loading /> : ""}
      <Row>
        <Col lg={6} md={6} sm={6}>
          {!isAdd ? (
            <Title title="تعديل الملف المالي " className="mt-5" />
          ) : (
            <Title title="انشاء الملف المالي " className="mt-5" />
          )}
        </Col>
        <Col lg={5} md={5} sm={5}></Col>
        <Col lg={1} md={1} sm={1}>
          <Back />
        </Col>
      </Row>

      <Col
        md={11}
        lg={9}
        className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto "
      >
        <Row className="items-center justify-center ">
          <Col md={6} className="transition-all !mt-2  ">
            <Input
              type="date"
              name="date"
              value={formData?.date ? formData.date : dates}
              onChange={handleChangeInput}
              title="تاريخ الملف المالي "
            />
          </Col>
          <Col md={6} className="transition-all">
            <div className="flex justify-between items-center">
              <h1 className={`text-start mb-2 text-Third font-bold`}>الكليات</h1>
              <AddSpecialties />
            </div>
            <select
              style={{
                boxShadow:
                  "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                border: "1px solid black",
              }}
              text="الكليات"
              title="اختر الكلية"
              name="specialty_section_id"
              value={formData?.specialty_section_id}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  specialty_section_id: e.target.value,
                  specialty_id: "",
                  degree_id: "",
                  discounted_amount: "",
                });
                setDataDegrees([]);
                setDegreePrice();
              }}
              className={`w-full border-2 border-Secondary py-3 rounded-xl  outline-none text-center mx-auto`}
            >
              <option value="" disabled selected hidden>
                اختر الكلية
              </option>
              {dataSpecialty?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </Col>
          {formData?.specialty_section_id && (
            <Col md={6} className="transition-all">
              <div className="my-3">
                <div className="flex justify-between items-center">
                  <h1 className="text-start mb-2 text-Third font-bold">التخصصات</h1>
                  <AddSpecialtie id={formData?.specialty_section_id} />
                </div>
                <select
                  style={{
                    boxShadow:
                      "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    border: "1px solid black",
                  }}
                  name="specialty_id"
                  value={formData?.specialty_id}
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const { degree_id, discounted_amount, ...formData } =
                        prevFormData;
                      return {
                        ...formData,
                        specialty_id: e.target.value,
                        degree_id: "",
                        discounted_amount: "",
                      };
                    });
                    setDegreePrice();
                    setDataDegrees(
                      specialties?.data.data.filter((p) => +p.id === +e.target.value)?.[0].degrees
                    );
                   
                  
                    
                  }}
                  className={`w-full border-2 border-Secondary py-3 rounded-xl  outline-none text-center mx-auto`}
                >
                  <option value="" disabled selected hidden>
                    اختر التخصص
                  </option>
                
                  {specialties?.data.data.map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          )}
          
          {dataDegrees?.length > 0   && (
            <Col md={6} className="transition-all !mt-1">
              <div className="my-3">
                <div className="flex justify-between items-center">
                  <h1 className="text-start mb-2 text-Third font-bold"> درجة العلمية </h1>
                </div>
                <select
                  style={{
                    boxShadow:
                      "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    border: "1px solid black",
                  }}
                  name="degree_id"
                  value={formData?.degree_id}
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const { discounted_amount, ...formData } = prevFormData;
                      return {
                        ...formData,
                        degree_id: e.target.value,
                      };
                    });
                    setDegreePrice(
                      dataDegrees?.filter((p) => +p.id === +e.target.value)?.[0]
                        ?.price
                    );
                   
                  }}
                  className={`w-full border-2 border-Secondary py-3 rounded-xl  outline-none text-center mx-auto`}
                >
                  <option value="" disabled selected hidden>
                    اختر الدرجة العلمية
                  </option>
                   
                  {dataDegrees?.map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.name} - {e.price}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          )}
          {degreePrice && (
            <>
              <Col md={6} className="transition-all">
                <div className="my-3">
                  <h1 className="text-start mb-2 text-Third font-bold">قيمة الملف المالي </h1>
                  <input
                    style={{
                      boxShadow:
                        "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      border: "1px solid black",
                    }}
                    readOnly
                    type="number"
                    value={degreePrice}
                    placeholder="قيمة الملف المالي "
                    className="w-full border-2 border-Secondary text-center py-3 rounded-xl"
                  />
                </div>
              </Col>
              <Col md={6} className="transition-all !mt-2">
                <Input
                  type="number"
                  name="discounted_amount"
                  value={formData?.discounted_amount}
                  onChange={handleChangeInput}
                  title="قيمة الخصم من سعر الإختصاص"
                />
              </Col>
              <Col md={6} className="transition-all">
                <div className="my-3">
                  <h1 className="text-start mb-2 text-Third font-bold">رسوم اضافية </h1>
                  <input
                    style={{
                      boxShadow:
                        "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      border: "1px solid black",
                    }}
                    type="number"
                    onChange={handleChangeInput}
                    name="extra_charge"
                    value={formData?.extra_charge}
                    placeholder="  رسوم اضافية "
                    className="w-full border-2 border-Secondary text-center py-3 rounded-xl"
                  />
                </div>
              </Col>
              <Col md={6} className="transition-all">
                <div className="my-3">
                  <h1 className="text-start mb-2 text-Third font-bold"> سبب الرسوم </h1>
                  <input
                    style={{
                      boxShadow:
                        "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      border: "1px solid black",
                    }}
                    name="extra_charge_reason"
                    onChange={handleChangeInput}
                    value={formData?.extra_charge_reason}
                    type="text"
                    // value={}
                    placeholder="  سبب الرسوم "
                    className="w-full border-2 border-Secondary text-center py-3 rounded-xl"
                  />
                </div>
              </Col>
            </>
          )}
          {isAdd && (
            <Row justify="center">
              <Col
                md={7}
                lg={12}
                className="flex items-center justify-start gap-3 my-4  font-semibold"
              >
                <input
                  type="checkbox"
                  checked={isCheckboxChecked}
                  onChange={(event) =>
                    setIsCheckboxChecked(event.target.checked)
                  }
                  className="w-6 h-6"
                />
                <p>إضافة دفعات مجدولة تلقائية</p>
              </Col>
              <Col md={9} />
              {isCheckboxChecked && (
                <>
                  <Col md={4} className="transition-all">
                    <Input
                      type="number"
                      name="first_payment_amount"
                      onChange={handleChangeInput}
                      title="قيمة الدفعة الأولى"
                    />
                  </Col>
                  <Col md={4} className="transition-all">
                    <Input
                      type="number"
                      name="payments_count"
                      onChange={handleChangeInput}
                      title="عدد الدفعات المجدولة"
                      className=""
                    />
                  </Col>
                  <Col md={4} className="transition-all">
                    <Input
                      type="number"
                      name="payment_gap_days"
                      onChange={handleChangeInput}
                      title="المدة الزمنة بين الدفعات بالأيام"
                    />
                  </Col>
                </>
              )}
            </Row>
          )}
          <h2 className="text-xl font-extrabold text-center !my-5">معلومات الطالب</h2>
          {isAdd && (
            <>
              <Col md={6} className="transition-all mb-20">
                <h1 className={`text-start mb-2`}> اسم الطالب </h1>
                <Input
                  readOnly={true}
                  value={
                    studentA?.first_name +
                    " " +
                    studentA?.mid_name +
                    " " +
                    studentA?.last_name
                  }
                />
              </Col>
              <Col md={6} />
            </>
          )}
          {!isAdd && <AddStudents id={dataOld?.student?.id} />}
          <Col sm={6} className="mx-auto">
            <ButtonRed
              name={!isAdd ? "تعديل" : "اضافة"} 
              onClick={handleSubmitMain}
              className="py-3"
            />
          </Col>
        </Row>
      </Col>
     
    </Container>
  );
};

export default AddTransactions;



const AddStudents = ({ id }) => {
  const [deleteImgs, setDeleteImgs] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    handleChangeArrayImages,
    handleCheckedArray,
    setImages,
    setViewImages,
    viewImages,
    images,
    formData,
    loading,
  } = usePOST({});
  const { data } = useFETCH(id ? `admin/students/${id}` : "");
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/students/${id}`, "", true);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    setFormData({
      first_name: dataOld?.first_name,
      mid_name: dataOld?.mid_name,
      last_name: dataOld?.last_name,
      phone_number: dataOld?.phone_number,
      email: dataOld?.email,
      degree: dataOld?.degree,
      sitting_number: dataOld?.sitting_number || "",
      authenticatoin_name: dataOld?.authentication_type?.name,
      status: dataOld?.status || "",
      can_manage_setting_number: dataOld?.can_manage_setting_number,
    });
  }, [dataOld]);

  return (
    <>
      <Row className="">
        <Col md={3}>
          <Input
            name="first_name"
            readOnly={readOnly}
            value={formData?.first_name}
            onChange={handleChangeInput}
            title="اسم الاول"
          />
        </Col>
        <Col md={3}>
          <Input
            name="mid_name"
            readOnly={readOnly}
            value={formData?.mid_name}
            onChange={handleChangeInput}
            title="اسم الاب"
          />
        </Col>
        <Col md={3}>
          <Input
            name="last_name"
            readOnly={readOnly}
            value={formData?.last_name}
            onChange={handleChangeInput}
            title="الكنية"
          />
        </Col>
        <Col md={3}>
          <Input
            readOnly={readOnly}
            name="phone_number"
            value={formData?.phone_number}
            onChange={handleChangeInput}
            title="رقم الهاتف"
          />
        </Col>
        <Col md={3}>
          <Input
            readOnly={readOnly}
            name="email"
            value={formData?.email}
            onChange={handleChangeInput}
            title="الإيميل"
          />
        </Col>
        <Col md={3}>
          <Input
            readOnly={readOnly}
            name="degree"
            value={formData?.degree}
            onChange={handleChangeInput}
            title="الدرجة العلمية"
          />
        </Col>
        {readOnly ? (
          <Col md={3}>
            <Input
              readOnly={readOnly}
              name="degree"
              value={formData?.status}
              title="الدرجة العلمية"
            />
          </Col>
        ) : (
          <>
            <Col md={3}>
              <Selected
                text="الحالة"
                title="اختر الحالة"
                name="status"
                value={formData?.status}
                onChange={handleChangeInput}
              >
                <option value="يدرس">يدرس</option>
                <option value="متخرج">متخرج</option>
                <option value="متوقف حالياً ">متوقف حالياً </option>
                <option value="منسحب">منسحب</option>
              </Selected>
            </Col>
          </>
        )}
        <Col md={3}>
          <Input
            readOnly={readOnly}
            name="sitting_number"
            value={formData?.sitting_number}
            onChange={handleChangeInput}
            title="رقم الجلوس"
          />
        </Col>
        <Col md={3}>
          <Input
            readOnly={readOnly}
            name="authentication_type_id"
            value={formData?.authenticatoin_name}
            onChange={handleChangeInput}
            title="نوع التصديق"
          />
        </Col>
        {readOnly ? (
          ""
        ) : (
          <>
            <Col md={6}>
              <div className="text-start mt-1 ">(image) (pdf) (file.zip)</div>
              <AddImage
                title="مستندات رسمية"
                type="IMAGES"
                id="images"
                name={"official_documents[]"}
                onChange={handleChangeArrayImages}
              />
              <Row className="py-4">
                {viewImages["official_documents[]"] &&
                  viewImages["official_documents[]"]?.map((e, i) => (
                    <Col key={i} md={3} xs={4} sm={6} lg={24}>
                      <div className="relative">
                        <span
                          onClick={() => {
                            let total = [...viewImages["official_documents[]"]];
                            let total2 = [...images["official_documents[]"]];
                            total.splice(i, 1);
                            total2.splice(i, 1);
                            setViewImages({
                              ...viewImages,
                              "official_documents[]":
                                total.length === 0 ? [] : total,
                            });
                            setImages({
                              ...images,
                              "official_documents[]":
                                total2.length === 0 ? [] : total2,
                            });
                          }}
                          className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                        >
                          X
                        </span>
                        <img
                          src={e}
                          alt=""
                          className="w-[140px] h-[140px] rounded-2xl"
                        />
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col md={6}>
              <div className="text-start mt-1 ">(image) (pdf) (file.zip)</div>
              <AddImage
                title="شهادات"
                type="IMAGES"
                id="images2"
                name="certificates[]"
                onChange={handleChangeArrayImages}
              />
              <Row className="py-4">
                {viewImages["certificates[]"] &&
                  viewImages["certificates[]"]?.map((e, i) => (
                    <Col key={i} md={3} xs={4} sm={6} lg={24}>
                      <div className="relative">
                        <span
                          onClick={() => {
                            let total = [...viewImages["certificates[]"]];
                            let total2 = [...images["certificates[]"]];
                            total.splice(i, 1);
                            total2.splice(i, 1);
                            setViewImages({
                              ...viewImages,
                              "certificates[]": total.length === 0 ? [] : total,
                            });
                            setImages({
                              ...images,
                              "certificates[]":
                                total2.length === 0 ? [] : total2,
                            });
                          }}
                          className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                        >
                          X
                        </span>
                        <img
                          src={e}
                          alt=""
                          className="w-[140px] h-[140px] rounded-2xl"
                        />
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </>
        )}
        {dataOld?.official_documents?.length !== 0 && (
          <div>
            {!readOnly ? (
              <div
                onClick={() => setDeleteImgs(!deleteImgs)}
                className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg  text-white font-semibold bg-Main my-2 ml-auto "
              >
                حذف الصور مستندات رسمية
              </div>
            ) : (
              <Title title="مستندات الرسمية" />
            )}
            <Row>
              {dataOld?.official_documents?.map((e) => (
                <Col key={e.id} md={4} sm={6} className="relative">
                  {deleteImgs && (
                    <input
                      type="checkbox"
                      value={e.id}
                      name="official_docs_to_be_deleted_ids[]"
                      onChange={handleCheckedArray}
                      className="absolute -top-2 left-1 h-5 w-5"
                    />
                  )}
                  <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                    <img
                      src={fileUrl + e.file}
                      alt=""
                      className="w-full h-full object-contain rounded-2xl"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
        {dataOld?.certificates?.length !== 0 && (
          <div>
            {!readOnly ? (
              <div
                onClick={() => setDeleteImgs(!deleteImgs)}
                className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg  text-white font-semibold bg-Main my-2 ml-auto "
              >
                حذف الصور شهادات
              </div>
            ) : (
              <Title title="شهادات" />
            )}

            <Row>
              {dataOld?.certificates?.map((e) => (
                <Col key={e.id} md={4} sm={6} className="relative">
                  {deleteImgs && (
                    <input
                      type="checkbox"
                      value={e.id}
                      name="certificates_to_be_deleted_ids[]"
                      onChange={handleCheckedArray}
                      className="absolute -top-2 left-1 h-5 w-5"
                    />
                  )}
                  <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                    <img
                      src={fileUrl + e.file}
                      alt=""
                      className="w-full h-full object-contain rounded-2xl"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
        {loading ? <Loading /> : ""}
        {!readOnly && (
          <Row>
            <Col md={4}>
              <ButtonRed
                name={"تعديل"}
                onClick={handleSubmitMain}
                className="py-3 mt-3"
              />
            </Col>
          </Row>
        )}
      </Row>
      {/* <Back /> */}
    </>
  );
};

const AddSpecialties = () => {
  const {
    handleSubmit,
    handleChangeInput,
    successfulResponse,
    setSuccessfulResponse,
    loading,
  } = usePOST({});
  return (
    <>
      <div>
        {successfulResponse && (
          <>
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
              onClick={() => setSuccessfulResponse(false)}
            ></div>
            <div
              className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center `}
            >
              {loading ? <Loading /> : ""}
              <div className="bg-white z-[60] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
                <p className="font-semibold text-xl text-center py-7 w-[90%] mx-auto">
                  <Input
                    name="name"
                    onChange={handleChangeInput}
                    title="اسم القسم"
                  />
                </p>
                <div className="flex items-center justify-center m-5">
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() =>
                        handleSubmit("admin/specialty-sections", "", true)
                      }
                    >
                      إضافة
                    </div>
                  </div>
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() => setSuccessfulResponse(false)}
                    >
                      إلغاء
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-fit ">
          <FaPlusCircle
            className="cursor-pointer text-Main mb-1 font-bold"
            size={28}
            onClick={() => setSuccessfulResponse(true)}
          />
        </div>
      </div>
    </>
  );
};
const AddSpecialtie = ({ id }) => {
  const {
    handleChangeInput,
    handleSubmit,
    successfulResponse,
    setSuccessfulResponse,
    loading,
    handleChangeObject,
    handleAddObject,
    setDataDegrees,
    dataDegrees,
    setObj,
    obj,
  } = usePOST({});
  const { data: dataAllDegrees } = useFETCH(`admin/degrees?paginate=0`);
  return (
    <>
      <div>
        {successfulResponse && (
          <>
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
              onClick={() => setSuccessfulResponse(false)}
            ></div>
            <div
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center `}
            >
              {loading ? <Loading /> : ""}
              <div className="bg-white z-[40] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
                <p className="font-semibold text-xl text-center py-7 w-[90%] mx-auto">
                  <Input
                    name="name"
                    onChange={handleChangeInput}
                    title="اسم التخصص"
                  />
                  <Row justify="center" className="items-center ">
                    <Col xs={7}>
                      <Input
                        type="number"
                        name="price"
                        value={obj?.price}
                        onChange={handleChangeObject}
                        title="السعر"
                      />
                    </Col>
                    <Col xs={7}>
                      <div className="">
                        <h1 className={`text-start mb-2 `}>الدرجة العلمية</h1>
                        <select
                          name="id"
                          value={obj?.id}
                          onChange={handleChangeObject}
                          className={`w-full border-2 border-Secondary py-3 rounded-xl  outline-none text-center mx-auto `}
                        >
                          <option value="" disabled selected hidden>
                            اختر الدرجة العلمية
                          </option>

                          {dataAllDegrees?.data.data
                            .filter(
                              (i) => !dataDegrees?.some((d) => +d.id === +i.id)
                            )
                            .map((k, i) => (
                              <option key={i} value={k.id}>
                                {k.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Col>
                    <Col xs={5}>
                      <ButtonRed
                        name="حفظ"
                        onClick={handleAddObject}
                        className="w-fit px-5 py-2 mb-2 mx-auto"
                      />
                    </Col>
                    <Col xs={8}></Col>
                    {dataDegrees?.map((e, i) => (
                      <Col
                        key={i}
                        md={7}
                        sm={12}
                        className="border rounded-xl border-Main flex justify-between mx-1"
                      >
                        <div>
                          <Data
                            name="الدرجة العلمية"
                            className="!text-sm"
                            text={
                              dataAllDegrees?.data.data?.filter(
                                (p) => +p.id === +e.id
                              )?.[0]?.name
                            }
                          />
                          <Data
                            className="!text-sm"
                            name="السعر"
                            text={e.price}
                          />
                        </div>
                        <div>
                          <ButtonRed
                            name=" تعديل"
                            onClick={() => {
                              let thisData = [...dataDegrees];
                              let x = thisData.splice(i, 1);
                              setDataDegrees(thisData);

                              setObj({
                                price: x[0].price,
                                id: x[0].id,
                              });
                            }}
                            className="w-fit !px-1 !py-1 !my-1 mx-auto !bg-Main"
                          />
                          <ButtonRed
                            name="حذف"
                            onClick={() => {
                              let total = [...dataDegrees];
                              total.splice(i, 1);
                              setDataDegrees(total);
                            }}
                            className="w-fit !px-1 !py-1 !my-1 mx-auto !bg-red-600"
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </p>
                <div className="flex items-center justify-center m-5">
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() =>
                        handleSubmit(
                          `admin/specialty-sections/${id}/specialties`,
                          "",
                          true
                        )
                      }
                    >
                      إضافة
                    </div>
                  </div>
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() => setSuccessfulResponse(false)}
                    >
                      إلغاء
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-fit">
          <FaPlusCircle
            className="cursor-pointer text-Main mb-1  font-bold"
            size={28}
            onClick={() => setSuccessfulResponse(true)}
          />
        </div>
      </div>
    </>
  );
};
