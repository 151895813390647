import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { PopupTable, Table } from "../../components";

function Emails() {
  const { id } = useParams();
  const { search } = useLocation();
  const [emailType, setEmailType] = useState("all");
  const { data, isLoading } = useFETCH(
    `admin/students/${id}/${emailType}/emails${search}`
  );
  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e.id,
      text: e.text,
      sent_at: e.sent_at ? e.sent_at : "لم يتم الإرسال بعد",
      type:
        e.type === "WARNING" ? (
          <td className="">إنذار</td>
        ) : e.type === "REMINDER" ? (
          <td className=" ">تذكير</td>
        ) : (
          ""
        ),
    };
  });
  console.log(data?.data.data);
  return (
    <Container>
      <div>
        <p className="text-Third font-semibold mb-6">
          الإيميلات المُرسلة إلى الطالب (
          <span className="text-Fourth font-bold mx-1">
            {data?.data?.student.full_name}
          </span>
          )
        </p>
        <Row>
          <EmailBtnFilter
            onClick={() => setEmailType("all")}
            title="الكل"
            name={emailType === "all"}
          />
          <EmailBtnFilter
            onClick={() => setEmailType("warning")}
            title="إنذار"
            name={emailType === "warning"}
          />
          <EmailBtnFilter
            onClick={() => setEmailType("reminder")}
            title="تذكيرية"
            name={emailType === "reminder"}
          />
        </Row>
        {isLoading ? <Loading /> : ""}
        <Table
          thead={["الرسالة", "نوع الرسالة", "تاريخ ارسال"]}
          pageCount={Math.ceil(
            data?.data.data?.total / data?.data.data?.per_page
          )}
          tData={dataAll}
          tbody={[{ name: "text" }, { name: "type" }, { name: "sent_at" }]}
          hide
        />
          {data?.data?.data?.length === 0 && (
            <p className="text-center font-semibold my-4">
              لا يوجد بيانات ليتم عرضها
            </p>
          )}
      </div>
    </Container>
  );
}
export default Emails;

const EmailBtnFilter = ({ title, onClick, name }) => {
  return (
    <Col lg={2} md={4} xs={6}>
      <div
        className={`border-2 border-Third  rounded-xl hover:bg-opacity-70 text-center cursor-pointer w-full px-5 py-[0.6rem]
       ${name ? "bg-Third text-white" : "bg-white text-Third"} `}
        onClick={onClick}
      >
        {title}
      </div>
    </Col>
  );
};
