import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs";
import AddImage from "../../Tools/AddFile";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import {
  Back,
  ButtonRed,
  Data,
  Input,
  Selected,
  TextArea,
  Title,
} from "../../components";
import { DataTransaction } from "./Payment";

const AddPayment = () => {
  const { id, idd } = useParams();
  const isAdd = idd === "add";
  let today = new Date();
  let dates = today.toISOString().substring(0, 10);
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    setViewFile,
    viewFile,
    formData,
    loading,
  } = usePOST({ payment_date: dates });
  const { data: dataAdmins } = useFETCH(
    `admin/users?role=beneficiary&paginate=0`
  );
  const { data: dataPayment } = useFETCH(`admin/payment/methods?paginate=0`);
  
  const { data, isLoading } = useFETCH(
    isAdd ? "" : `admin/transactions/${id}/payments/${idd}`
  );

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      isAdd
        ? `admin/transactions/${id}/payments`
        : `admin/transactions/${id}/payments/${idd}`
    );
  };
  let dataOld = data?.data?.data;
  useEffect(() => {
    !isAdd &&
      setFormData({
        payment_date: dataOld?.date || "",
        amount: dataOld?.amount || "",
        state: dataOld?.state || "",
        payment_method_id: dataOld?.payment_method?.id || "",
        transferred_by: dataOld?.transferred_by || "",
        email: dataOld?.email || "",
        transfer_number: dataOld?.transfer_number || "",
        transfer_date: dataOld?.transfer_date || "",
        transfer_arrival_date: dataOld?.transfer_arrival_date || "",
        transfer_amount: dataOld?.transfer_amount || "",
        transfer_recipient_id: dataOld?.transfer_recipient_id || "",
        received_amount: dataOld?.received_amount || "",
        notes: dataOld?.notes || "",
        transfer_recipient_commission:
          dataOld?.transfer_recipient_commission || "",
      });
      setSelectedOptionType(dataOld?.payment_method?.type)
  }, [dataOld]);
  
  // const allowedTransferNumberTypes = [
  //   "CURRENCY_EXCHANGE_OFFICE",
  //   "WESTERN_UNION",
  //   "BANK_ACCOUNT",
  // ];
  // const allowedTransferRecipientTypes = [
  //   "CURRENCY_EXCHANGE_OFFICE",
  //   "WESTERN_UNION",
  // ];
 
  const [selectedOptionType, setSelectedOptionType] = useState(-1);


  return (
    <>
    <Row>
      <Col lg={6} md={6} sm={6}>
         <Title title={!isAdd ? "تعديل الدفعة" : "انشاء دفعة"} className="mt-5" />
      </Col>
      <Col lg={5} md={5} sm={5}>
      </Col>
      <Col lg={1} md={1} sm={1}>
      <Back />
      </Col>
    </Row>
      
      <DataTransaction id={id} />
      <Col sm={11} className="mx-auto">
        <Row className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto justify-center">
          <Col lg={4} md={4}>
            <Input
              type="date"
              title="تاريخ الدفعة"
              name="payment_date"
              value={formData?.payment_date ? formData?.payment_date : dates}
              onChange={handleChangeInput}
            />
          </Col>
          <Col lg={4} md={4}>
            <Input
              type="number"
              title="قيمة الدفعة"
              name="amount"
              value={formData?.amount}
              onChange={handleChangeInput}
            />
          </Col>
          <Col lg={4} md={4}>
            <Selected
              text="طريقة الدفع"
              title="اختر طريقة الدفع"
              name="payment_method_id"
              value={formData?.payment_method_id}
              onChange={(e)=>{
                handleChangeInput(e)
                setSelectedOptionType(e.target.options[e.target.selectedIndex].dataset.type)
              }}
            >
              {dataPayment?.data?.data?.map((e) => (
                <option key={e.id} value={e.id} data-type ={e.type} >
                  {e.name}
                </option>
              ))}
            </Selected>
          </Col>
          
        
          

          <h1 className="text-xl !my-5 font-extrabold text-Third text-center  ">
            معلومات الحوالة
          </h1>

          {  selectedOptionType==="STRIPE" && (
            <Col lg={4} md={4}>
              <Input
                title=" أيميل "
                name="email"
                value={formData?.email}
                onChange={handleChangeInput}
              />
            </Col>
          )}

          <Col lg={4} md={4}>
            <Input
              title="اسم الشخص الذي حوّل الحوالة"
              name="transferred_by"
              value={formData?.transferred_by}
              onChange={handleChangeInput}
            />
          </Col>
          {selectedOptionType === "CURRENCY_EXCHANGE_OFFICE" || selectedOptionType==="WESTERN_UNION" || selectedOptionType==="BANK_ACCOUNT"  && (
            <Col lg={4} md={3}>
              <Input
                type="text"
                title="رقم الحوالة"
                name="transfer_number"
                value={formData?.transfer_number}
                onChange={handleChangeInput}
              />
            </Col>
          )}
          <Col lg={4} md={4}>
            <Input
              type="date"
              title="تاريخ الحوالة"
              name="transfer_date"
              value={formData?.transfer_date}
              onChange={handleChangeInput}
            />
          </Col>
          {selectedOptionType === "BANK_ACCOUNT" && (
            <Col lg={4}
              md={4}
              className={`${
                localStorage?.getItem("role_name") ===
                  "financial_collection_officer" ||
                localStorage?.getItem("role_name") === "register_manager"
                  ? "hidden"
                  : ""
              }`}
            >
              <Input
                type="date"
                title="تاريخ وصول الحوالة (اذا كانت لحساب بنكي)"
                name="transfer_arrival_date"
                value={formData?.transfer_arrival_date}
                onChange={handleChangeInput}
              />
            </Col>
          )}
          { selectedOptionType==="CURRENCY_EXCHANGE_OFFICE" || selectedOptionType==="WESTERN_UNION"  ? (
            <>
              <Col lg={4} md={4}>
                <Selected
                  name="transfer_recipient_id"
                  value={formData?.transfer_recipient_id}
                  onChange={handleChangeInput}
                  text="اسم مستلم الحوالة "
                  title="اختر اسم مستلم الحوالة "
                >
                  {dataAdmins?.data.data?.map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.name}
                    </option>
                  ))}
                </Selected>
              </Col>
              {localStorage?.getItem("role_name") === "financial_manager" ||
              localStorage?.getItem("role_name") === "super_admin" ? (
                <Col lg={4} md={4}>
                  <Input
                    type="number"
                    title="عمولة مستلم الحوالة"
                    name="transfer_recipient_commission"
                    value={formData?.transfer_recipient_commission}
                    onChange={handleChangeInput}
                  />
                </Col>
              ) : (
                ""
              )}
            </>
          ): ""}
          <Col lg={4} md={4}>
            <Input
              type="number"
              title="قيمة الحوالة"
              name="transfer_amount"
              value={formData?.transfer_amount}
              onChange={handleChangeInput}
            />
          </Col>
          <Col lg={4}
            md={4}
            className={`${
              localStorage?.getItem("role_name") ===
                "financial_collection_officer" ||
              localStorage?.getItem("role_name") === "register_manager"
                ? "hidden"
                : ""
            }`}
          >
            <Input
              type="number"
              title="المبلغ الذي وصل"
              name="received_amount"
              value={formData?.received_amount}
              onChange={handleChangeInput}
            />
          </Col>
          {localStorage?.getItem("role_name") === "register_manager" ||
          localStorage?.getItem("role_name") ===
            "financial_collection_officer" ? (
            ""
          ) : (
            <Col lg={4} md={4}>
              <Selected
                name="state"
                value={formData?.state}
                onChange={handleChangeInput}
                text="حالة الدفعة"
                title="اختر حالة الدفعة"
                optionValue="true"
              >
                <option value="2">مقبولة</option>
              </Selected>
            </Col>
          )}

          <Col lg={4} md={4}>
            <Input
              title="ملاحظات"
              name="notes"
              value={formData?.notes}
              onChange={handleChangeInput}
            />
          </Col>
          <Col lg={4} md={4}>
            <div>
              <h1 className="text-center mt-2  ">أضف صورة</h1>
              <AddImage
                type="file"
                name="transfer_image"
                newImage={viewFile}
                oldImage={
                  dataOld?.transfer_image
                    ? fileUrl + dataOld?.transfer_image
                    : ""
                }
                onChange={handleChangeInput}
                title="أضف صورة"
                clickDeleteImage={() => {
                  setViewFile("");
                  setFormData({ ...formData, transfer_image: "" });
                }}
              />
            </div>
          </Col>

          {!isAdd && dataOld?.fco?.id === localStorage.getItem("admin_id") ? (
            <h2 className="text-center text-green-600 font-semibold">
              تم تحصيل الحوالة من قبلك
            </h2>
          ) : !isAdd && dataOld?.fco?.name ? (
            <Col lg={4} md={4}>
              <Data
                name=" تم تحصيل الحوالة بواسطة "
                text={dataOld?.fco?.name}
              />
            </Col>
          ) : (
            !isAdd && (
              <h2 className="text-center text-orange-600 font-semibold">
                لم يتم تحصيل الحوالة بعد .
              </h2>
            )
          )}
          
          <Row>
            <Col md={4} className="mx-auto">
              {loading || (isLoading && !isAdd) ? <Loading /> : ""}
              <ButtonRed
                name={!isAdd ? "تعديل" : "اضافة"}
                onClick={handleSubmitMain}
                className="py-3 mt-3"
              />
            </Col>
          </Row>
        </Row>
      </Col>
      
    </>
  );
};

export default AddPayment;
