import { useEffect, useState } from "react";
import { BsWalletFill } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { FaEye } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useClose, useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";

import {
  Add,
  AdminsFilter,
  Back,
  ButtonRed,
  Delete,
  DeleteParamsAll,
  Filter,
  GiveSalaries,
  Search,
  Title,
  Update,
} from "../../components";
import { useContextHook } from "../../Context/ContextOPen";
function Users() {
  const { search } = useLocation();
  const {
    handleSubmit,
    totalBalance,
    setTotalBalance,
    loading,
    checkArray,
    setCheckArray,
    successfulResponse,
    setSuccessfulResponse,
  } = usePOST({});
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/users?paginate=0${search ? "&" + search.substring(1) : ""}`,
    `admin/users`
  );

  const [selectApi, setSelectApi] = useState("");
  const { setMessage } = useContextHook();
  useEffect(() => {
    setCheckArray((prevState) => ({
      "users_ids[]": [],
    }));
  }, []);

  return (
    <Container>
      <Row>

        <Col lg={6} md={6} sm={6} className="flex justify-start ">
          <Title
            title=" كادر الأكاديمية"
            className="flex items-center text-[#0d5069]"
          />
        </Col>

        <Col md={5} lg={5} sm={7} className="flex justify-end max-lg:justify-center ">
          <Add link={`/admins/add`} />
        </Col>

        <Col lg={1} md={1} sm={1} className="flex justify-center ">
        <Back />
        </Col>

      </Row>

      <FilterUser />


      {loading || isLoading ? <Loading /> : ""}

      <Row justify="center md:px-3 mx-4">
        <Col className="overflow-x-auto ">
          <table className="w-[95%] max-md:w-[700px] mx-auto my-5 text-center rounded-2xl overflow-hidden border border-Secondary ">
            <thead className="table-thead text-white h-[70px] font-bold text-base">
              <tr>
                <td className="px-7">الاسم</td>
                <td className="px-7">الايميل</td>
                <td className="px-7">رقم الهاتف</td>
                <td className="px-7">نوع الحساب </td>
                <td className="px-7">الراتب</td>
                <td className="px-7">الرصيد</td>
                <td className="px-7">العمليات</td>
              </tr>
            </thead>
            <tbody className="">
              {isLoading ? (
                <Loading />
              ) : (
                data?.data.data.map((e, i) => (
                  <tr key={i} className="border border-Main bg-white">
                    <td className="">{e.name}</td>
                    <td className="">{e.email}</td>
                    <td className="">{e.phone_number}</td>
                    <td className="">{e.role?.display}</td>
                    <td className="">{e.salary}</td>
                    <td className="">{e.balance}</td>
                    <td className="flex justify-center items-center gap-3 mt-2">
                      <div className="flex justify-center items-center gap-3">
                        {selectApi && (
                          <div className="flex items-center gap-3 my-4  font-semibold">
                            <div
                              onClick={() => {
                                if (
                                  !checkArray?.["users_ids[]"].includes(e.id)
                                ) {
                                  setCheckArray((prevState) => ({
                                    ...prevState,
                                    "users_ids[]": [
                                      ...prevState["users_ids[]"],
                                      e.id,
                                    ],
                                  }));
                                } else {
                                  setCheckArray((prevState) => ({
                                    ...prevState,
                                    "users_ids[]": prevState?.[
                                      "users_ids[]"
                                    ].filter((id) => id !== e.id),
                                  }));
                                }
                              }}
                              className={`w-6 h-6 border border-black rounded-md ${
                                checkArray?.["users_ids[]"]?.includes(e.id)
                                  ? "bg-Main"
                                  : ""
                              }`}
                            />
                          </div>
                        )}
                        <UserEvnte deleteItem={deleteItem} e={e} />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* ================================================================= */}
          <Row justify="evenly">
            <Col lg={3} md={4} sm={12} className="flex justify-center">
              <ButtonRed
                className={` Third border-solid border-2 border-Third w-[180px] mx-4 shadow-sm shadow-Third ${
                  selectApi === "admin/users/check/balances?type=SALARIES"
                    ? "bg-Third text-white "
                    : "bg-white !text-Third "
                }`}
                onClick={() => {
                  if (
                    selectApi !== "admin/users/check/balances?type=SALARIES"
                  ) {
                    setSelectApi("admin/users/check/balances?type=SALARIES");
                  } else {
                    setSelectApi("");
                  }
                  setTotalBalance(-1);
                }}
                name="حساب الرواتب "
              />
            </Col>
            <Col lg={3} md={4} sm={12} className="flex justify-center">
              <ButtonRed
                className={` Third border-solid border-2 border-Third w-[180px] mx-4 shadow-sm shadow-Third ${
                  selectApi === "admin/users/check/balances?type=OTHERS"
                    ? "bg-Third text-white"
                    : "bg-white !text-Third "
                }`}
                onClick={() => {
                  if (selectApi !== "admin/users/check/balances?type=OTHERS") {
                    setSelectApi("admin/users/check/balances?type=OTHERS");
                  } else {
                    setSelectApi("");
                  }
                  setTotalBalance(-1);
                }}
                name="حساب العمولات "
              />
            </Col>
            <Col lg={3} md={4} sm={12}  className="  flex justify-center">
              <ButtonRed
                className={` Third border-solid border-2 border-Third w-[180px] mx-4 shadow-sm shadow-Third ${
                  selectApi === "admin/users/give/salaries"
                    ? "bg-Third text-white"
                    : "bg-white !text-Third "
                }`}
                onClick={() => {
                  if (selectApi !== "admin/users/give/salaries") {
                    setSelectApi("admin/users/give/salaries");
                  } else {
                    setSelectApi("");
                  }
                }}
                name="تنزيل رواتب جديدة"
              />
            </Col>
          </Row>
          {/* ================================================================= */}
          <Row justify="">
            {(selectApi === "admin/users/check/balances?type=SALARIES" ||
              selectApi === "admin/users/check/balances?type=OTHERS") && (
              <>
                <Col md={3} sm={6}>
                  <ButtonRed
                    className=""
                    onClick={() => {
                      if (checkArray?.["users_ids[]"]?.length > 0) {
                        handleSubmit(`${selectApi}`, "/admins", "");
                      } else {
                        setMessage(["يجب اختيار أحد العناصر"]);
                      }
                    }}
                    name="احسب"
                  />
                </Col>
                {totalBalance >= 0 && (
                  <Col md={3} sm={6}>
                    <ButtonRed
                      className=""
                      name={`إجمالي ${
                        selectApi === "admin/users/check/balances?type=OTHERS"
                          ? "العمولات"
                          : "الرواتب"
                      } ${totalBalance}`}
                    />
                  </Col>
                )}
              </>
            )}
            {selectApi === "admin/users/give/salaries" && (
              <Col md={3} sm={6}>
                <GiveSalaries
                  setSuccessfulResponse={setSuccessfulResponse}
                  successfulResponse={successfulResponse}
                  name={checkArray?.["users_ids[]"]?.length > 0}
                  onClick={() => handleSubmit(selectApi, "", "", true)}
                />
              </Col>
            )}
            {selectApi && (
              <Col md={3} sm={6}>
                <div className="flex items-center gap-3 my-4  font-semibold">
                  <div
                    onClick={() => {
                      if (
                        checkArray?.["users_ids[]"]?.length !==
                        data?.data.data.length
                      ) {
                        const allIds = data?.data.data.map((item) => item.id);
                        setCheckArray({
                          "users_ids[]": allIds,
                        });
                      } else {
                        setCheckArray({
                          "users_ids[]": [],
                        });
                      }
                    }}
                    className={`w-6 h-6 border border-black rounded-md bg-white ${
                      checkArray?.["users_ids[]"]?.length ===
                      data?.data.data.length
                        ? "!bg-Main"
                        : ""
                    }`}
                  />
                  <p>تحديد الكل</p>
                </div>
              </Col>
            )}
          </Row>
          {/* ================================================================= */}
        </Col>
      </Row>

      {/* <Back /> */}
    </Container>
  );
}
export default Users;
const FilterUser = () => {
  const { open, setOpen, mouse } = useClose();
  return (
    <Row className="mx-4 flex max-md:justify-between" >
      {/* <DeleteParamsAll /> */}
      <Col lg={3} md={4} xs={5} sm={12} className="flex md:justify-center " >
        <div ref={mouse} className="relative max-md:w-full  ">
          <div
            className={`lg:w-[180px] md:w-[210px] font-bold text-lg bg-white rounded-xl flex justify-center   gap-4 items-center py-3 px-5 text-Third shadow-Third shadow-sm`}
            onClick={() => setOpen(!open)}
          >
            بحث 
            <CiSearch size={20} />
          </div>

          <div
            className={`${
              open
                ? "absolute top-12 transition-all w-full flex justify-center  bg-white  rounded-2xl text-center text-Third  mt-1"
                : "hidden"
            }`}
          >
            <Filter onclick={() => console.log()} text="الاسم">
              <Search className="lg-w-[200px] max-md:w-full text-black" name="name" />
            </Filter>
          </div>
        </div>
      </Col>
      <AdminsFilter />
    </Row>
  );
};

export const UserEvnte = ({ e, deleteItem, display }) => {
  return (
    <>
      <Update edit={`/admins/${e?.id}`} />
      <div id="my-anchor-element-wallet">
        <div
          className={` w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
        >
          <Link to={`/admins/wallet/${e?.id}`}>
            <BsWalletFill size={30} className="text-sky-950" />
          </Link>
        </div>
        <Tooltip anchorSelect="#my-anchor-element-wallet" content="المحفظة" />
      </div>
      {!display && (
        <div id="my-anchor-element-show">
          <div
            className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
          >
            <Link to={`/admins/info/${e?.id}`}>
              <FaEye size={27} className="text-blue-700" />
            </Link>
          </div>
          <Tooltip
            anchorSelect="#my-anchor-element-show"
            content="عرض التفاصيل"
          />
        </div>
      )}

      {e?.name !== "آدمن رئيسي" ? (
        <Delete
          id={e?.id}
          name={e?.display || e?.name}
          onClick={() => deleteItem(e)}
        />
      ) : (
        <div className="w-10 h-10">
          <div className="w-[30px] h-[30px]" />
        </div>
      )}
    </>
  );
};
