import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import number from "../../svg/id-card_5989704.svg";
import ButtonRed from "../Buttons/ButtonRed";
import Input from "../Input/Input";
import { useContextHook } from "../../Context/ContextOPen";

const EditSittingNumber = ({ id, email, name, phone_number }) => {
  const [sure, setSure] = useState(false);
  const { setMessage } = useContextHook();
  const { handleChangeInput, handleSubmit, loading, formData } = usePOST({});

  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[520px] max-sm:w-[320px] min-h-[220px] py-4">
              <div className="w-[90%] mx-auto">
                <Input
                  name="sitting_number"
                  onChange={handleChangeInput}
                  title="رقم الجلوس"
                />

                {loading ? <Loading /> : ""}

                <ButtonRed
                  name="حفظ"
                  onClick={() => {
                    if (formData.sitting_number) {
                      handleSubmit(
                        `admin/students/${id}?email=${email}&name=${name}&sitting_number=${formData.sitting_number}&phone_number=${phone_number}`,
                        "",
                        "",
                        true
                      );
                    } else {
                      setMessage(["حقل رقم الجلوس مطلوب"]);
                    }
                  }}
                  className="py-3 mt-3 w-[100px] mx-auto"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <div id="my-anchor-element_33">
          <div className="!w-7 !h-7 flex justify-center items-center  cursor-pointer">
            <img src={number} alt="" onClick={() => setSure(true)} />
          </div>
          <Tooltip
            anchorSelect="#my-anchor-element_33"
            content="تعيين رقم الجلوس"
          />
        </div>
      </div>
    </>
  );
};

export default EditSittingNumber;
