function TextArea({ title, name, value, onChange }) {
  return (
    <div className="my-2 px-1 w-full">
      <h1 className="text-start mt-2 ">{title}</h1>
      <textarea
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        className="border-2 border-Third outline-none m-1 py-3 rounded-xl block w-full px-3 h-[55px]"
      />
    </div>
  );
}
export default TextArea;
