import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Add, Back, Search, Table, Title } from "../../components";

function Beneficiaries() {
  const { search } = useLocation();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/users?role=beneficiary${search ? "&" + search.substring(1) : ""}`,
    `admin/users`
  );
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      name: e.name,
      balance: e.balance,
    };
  });
  return (
    <>
      <Container>
        <Row>
          <Col lg={6} sm={6} md={6}>
            <Title title="المستفيدين" />
          </Col>

          <Col
            lg={5}
            sm={5}
            md={5}
            className="flex lg:justify-end max-md:justify-center"
          >
            <Add link={`/beneficiaries/add`} />
          </Col>

          <Col lg={1} className="flex justify-center ">
            <Back />
          </Col>
        </Row>

        <Col md={3}>
          <Search />
        </Col>
        {isLoading ? <Loading /> : ""}
      </Container>
      <Table
        thead={["الاسم", "الرصيد"]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={dataAll}
        tbody={[{ name: "name" }, { name: "balance" }]}
        funDelete={deleteItem}
        edit="beneficiaries"
        wallet="beneficiaries/wallet"
        showDetails="beneficiaries/info"
        Beneficiaries="true"
      />

      {/* <Back /> */}
    </>
  );
}
export default Beneficiaries;
