import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Add, Back, Table, Title } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";

const AuthenticationTypes = () => {
  const { search } = useLocation();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/authentication-types` + search,
    `admin/authentication-types`
  );
  return (
    <>
      <Row>
        <Col lg={6}>
          <Title title="انواع التصديقات" />
        </Col>
        <Col lg={5} className="flex lg:justify-end">
          <Add link={`/authentication-types/add`} />
        </Col>
        <Col lg={1} className="flex justify-center ">
        <Back />
        </Col>
      </Row>

      {isLoading ? <Loading /> : ""}
      <Table
        thead={["الاسم"]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[{ name: "name" }]}
        funDelete={deleteItem}
        edit="authentication-types"
      />

      {/* <Back /> */}
    </>
  );
};

export default AuthenticationTypes;
