import { Link, useLocation } from "react-router-dom";
import { useClose, useFETCH } from "../../Tools/APIs";
import Bell from "../../svg/bell_2499377.svg";
import { useEffect, useRef } from "react";
import Audio from "../../images/smallmessag_93143d2d5268411.mp3";
const NotificationNew = () => {
  const { mouse, open, setOpen } = useClose();
  const { pathname } = useLocation();
  const refAudio = useRef();
  const { data: dataNot } = useFETCH(
    open ? `admin/notifications?paginate=0` : ""
  );
  const { data } = useFETCH(
    localStorage.getItem("token") ? `admin/profile?test=${pathname}` : ""
  );
  if (
    data?.data.data.new_notifications_count > 0 &&
    !window.sessionStorage.getItem("count_notification")
  ) {
    window.sessionStorage.setItem(
      "count_notification",
      data?.data.data.new_notifications_count
    );
  }
  if (
    data?.data.data.new_notifications_count >
    window.sessionStorage.getItem("count_notification")
  ) {
    if (refAudio.current) {
      refAudio.current.play();
      window.sessionStorage.setItem(
        "count_notification",
        data?.data.data.new_notifications_count
      );
    }
  }
  useEffect(() => {}, [pathname]);
  if (open) {
    window.sessionStorage.removeItem("count_notification");
  }
  return (
    <>
      <audio ref={refAudio}>
        <source src={Audio} type="audio/mpeg" />
      </audio>
      <div ref={mouse}>
        <div
          onClick={() => {
            setOpen(!open);
          }}
          className="relative w-10 h-10 border cursor-pointer text-yellow-300 text-xl font-semibold rounded-full flex items-center  hover:bg-slate-300 justify-center"
        >
          <img src={Bell} alt="" className="w-7" />
          {data?.data.data.new_notifications_count > 0 && (
            <>
              <div className="w-4 h-4 rounded-full bg-red-600 flex justify-center items-center p-1 absolute -top-1 -right-0 text-white text-sm">
                {data?.data.data.new_notifications_count}
              </div>
            </>
          )}
        </div>
        <div
          className={`${
            open
              ? "absolute top-14 sm:left-10 text-black text-center border-2 border-Secondary w-[240px] px-3 py-4 rounded-xl bg-gray-50 text-sm"
              : "hidden"
          }`}
        >
          {dataNot?.data.data.data
            .filter((e) => +e.has_read !== 1)
            .slice(0, 3)
            .map((e, i) => (
              <div key={i}>
                <p>{e.title}</p>
                <p className="py-2">{e.description.substring(0, 50)}...</p>
                <div className="border-b border-Third pb-2 text-end text-gray-400 text-sm">
                  {e.updated_at.substring(0, 20)}
                </div>
              </div>
            ))}
          {dataNot?.data.data.data.filter((e) => +e.has_read !== 1).length ===
          0 ? (
            <>
              <p className="text-red-600 font-semibold py-2">
                لا يوجد إشعارات جديدة
              </p>
            </>
          ) : (
            ""
          )}
          <Link to="/notifications">
            <p
              onClick={() => {
                setOpen(false);
              }}
              className="text-normal underline decoration-Main"
            >
              عرض الكل
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};
export default NotificationNew;
