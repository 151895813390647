import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Add, Back, Table, Title } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";

const AcademicDegrees = () => {
  const { search } = useLocation();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/degrees` + search,
    `admin/degrees`
  );
  return (
    <>
      <Row>
        <Col lg={6}>
          <Title title="الدرجات العلمية" />
        </Col>
        <Col lg={5} className="flex lg:justify-end">
          <Add link={`/academic-degrees/add`} />
        </Col>
        <Col lg={1} className="flex justify-center ">
        <Back />
        </Col>
      </Row>

      
      {isLoading ? <Loading /> : ""}
      <Table
        thead={["الاسم"]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[{ name: "name" }]}
        funDelete={deleteItem}
        edit="academic-degrees"
      />
      
      {/* <Back /> */}
    </>
  );
};

export default AcademicDegrees;
