import { useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Back, Data, Title } from "../../components";
import Loading from "../../Tools/Loading";
import { UserEvnte } from "./Users";

function SingleUser() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/users/${id}`);
  let dataAll = data?.data.data;
  return (
    <div>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Row>
          <Col lg={5} md={5} sm={5}>
             <Title title="عضو الإدارة" />
          </Col>
          <Col lg={6} md={6} sm={6}></Col>
          <Col lg={1} sm={1} md={1} className="flex justify-center">
            <Back/>
          </Col>
        </Row>
       
        <Col
          lg={8}
          className="bg-white mx-auto border-2 border-Secondary py-5 px-3 rounded-xl"
        >
          <Row>
            <Card name="اسم المستخدم" value={dataAll?.username} />
            <Card name="الاسم" value={dataAll?.name} />
            <Card name="الاسم  بالانكليزي" value={dataAll?.name_en} />
            <Card name="رقم الهاتف" value={dataAll?.phone_number} />
            <Card name="الإيميل" value={dataAll?.email} />
            <Card name="الراتب" value={dataAll?.salary} />
            <Card name="الرصيد" value={dataAll?.balance} />
            <Card name="رقم الوظيفة" value={dataAll?.job_number} />
            <Card name="الايبان" value={dataAll?.iban} />
            <Card name="اسم البنك" value={dataAll?.bank_name} />
            <Card name="المسمى وظيفي" value={dataAll?.job_title} />
            <Card
              name="عمولة التسجيل"
              value={dataAll?.commission_type_display}
            />
            <Card name="قيمة عمولة التسجيل" value={dataAll?.commission} />
            <Card
              name="عمولة التحصيل"
              value={dataAll?.fc_commission_type_display}
            />
            <Card name="قيمة عمولة التحصيل" value={dataAll?.fc_commission} />
            <Card name="دور المدير" value={dataAll?.role?.display} />
          </Row>
          <div className="flex justify-end items-center gap-4">
            <UserEvnte e={dataAll} display={true} />
          </div>
        </Col>
      </Container>
      {/* <Back /> */}
    </div>
  );
}
export default SingleUser;

const Card = ({ name, value }) => {
  return (
    <>
      {value ? (
        <Col md={6}>
          <Data name={name} text={value} />
        </Col>
      ) : (
        ""
      )}
    </>
  );
};
