import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, ButtonRed, Input, PasswordInput, Title } from "../../components";

const AddUser = () => {
  const { id } = useParams();
  const isAdd = id === "add";
  const { setMessage } = useContextHook();
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({
      password: "",
      passwordConfirmation: "",
    });
  const { data: dataRoles } = useFETCH(`admin/roles`);
  const { data, isLoading } = useFETCH(isAdd ? "" : `admin/users/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    if (
      formData?.password === formData?.passwordConfirmation ||
      selectRef.current?.options[selectRef.current.selectedIndex]?.dataset
        .mode === "other"
    ) {
      handleSubmit(isAdd ? "admin/users" : `admin/users/${id}`);
    } else {
      setMessage(["كلمة المرور وتأكيد كلمة المرور غير متطابقتين"]);
    }
  };
  const selectRef = useRef(null);
  let dataOld = data?.data.data;

  useEffect(() => {
    !isAdd &&
      setFormData({
        password: "",
        passwordConfirmation: "",
        username: dataOld?.username,
        phone_number: dataOld?.phone_number,
        email: dataOld?.email,
        job_title: dataOld?.job_title,
        iban: dataOld?.iban,
        bank_name: dataOld?.bank_name,
        name_en: dataOld?.name_en,
        [dataOld?.salary && "salary"]: dataOld?.salary,
        [dataOld?.commission >= 0 && "commission"]: dataOld?.commission,
        [dataOld?.fc_commission >= 0 && "fc_commission"]:
          dataOld?.fc_commission,
        [dataOld?.fc_commission_type && "fc_commission_type"]:
          dataOld?.fc_commission_type,
        [dataOld?.name && "name"]: dataOld?.name,
        [dataOld?.commission_type && "commission_type"]:
          dataOld?.commission_type,
        role_id: dataOld?.role_id,
      });
    if (!isAdd && dataOld?.commission > 0) {
      setIsCheckboxChecked(true);
    }
    if (!isAdd && dataOld?.fc_commission > 0) {
      setIsCheckboxCheckedTow(true);
    }
  }, [dataOld]);
  useEffect(() => {
    if (isAdd) {
      setIsCheckboxChecked(true);
    }
    console.log(formData);
  }, []);
  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.value = formData.role_id || dataOld?.role_id;
      isAdd &&
        selectRef.current.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }, [formData.role_id, dataOld]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedTow, setIsCheckboxCheckedTow] = useState(false);
  const handleChangeInputNumber = (event) => {
    const { name, value } = event.target;
    if (value >= 0 && value <= 100) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  return (
    <div className="">
      <Row>
        <Col lg={6} md={6} sm={6} className="cursor-none">
          <Title
            title={isAdd ? "أضف إلى الكادر" : "تعديل الكادر"}
            className=""
          />
        </Col>
        <Col lg={5} md={5} sm={5}></Col>
        <Col lg={1} md={1} sm={1}>
          <Back />
        </Col>
      </Row>

      {isLoading && !isAdd && <Loading />}
      {(!isLoading || isAdd) && (
        <Row justify={"center"}>
          <Col md={11} lg={10}>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={11}
                className="flex justify-center max-md:mx-3"
              >
                <select
                  text="نوع الحساب"
                  title="اختر نوع الحساب"
                  name="role_id"
                  ref={selectRef}
                  value={formData?.role_id}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      role_id: e.target.value,
                    });
                    // setIsCheckboxChecked(false);
                    // setIsCheckboxCheckedTow(false);
                    if (
                      formData?.commission ||
                      formData?.commission_type ||
                      formData?.fc_commission ||
                      formData?.fc_commission_type
                    ) {
                      setFormData((prevFormData) => {
                        const {
                          commission,
                          commission_type,
                          fc_commission,
                          fc_commission_type,
                          ...formData
                        } = prevFormData;
                        return formData;
                      });
                    }
                  }}
                  className={`w-full font-bold border-2 border-Third text-Third py-3 text-xl rounded-xl  outline-none  mx-auto  text-center flex justify-center shadow-md shadow-Third `}
                >
                  <option value="" disabled hidden selected>
                    {dataRoles ? " اختر نوع الحساب" : "الرجاء الانتظار"}
                  </option>
                  {dataRoles?.data.data.map((e) => (
                    <option key={e.id} data-mode={e.name} value={e.id}>
                      {e.display}
                    </option>
                  ))}
                </select>
              </Col>

              <>
                {selectRef.current?.options[selectRef.current.selectedIndex]
                  ?.dataset.mode && (
                  <>
                    {selectRef.current?.options[selectRef.current.selectedIndex]
                      ?.dataset.mode === "register_manager" && (
                      <>
                        <div className="flex items-center gap-3 my-4  font-semibold">
                          <div
                            onClick={() => {
                              setIsCheckboxChecked(!isCheckboxChecked);
                              if (isCheckboxChecked) {
                                setFormData({
                                  ...formData,
                                  commission: 0,
                                });
                              }
                            }}
                            className={`w-6 h-6 border border-black rounded-md ${
                              isCheckboxChecked ? "bg-Main" : ""
                            }`}
                          />
                          <p>إضافة عمولة التسجيل</p>
                        </div>
                        {isCheckboxChecked && (
                          <>
                            <h1 className="text-center  font-bold">
                              عمولة التسجيل
                            </h1>
                            <div className="flex justify-center items-center gap-10 mb-5 !mt-3">
                              <div className="flex justify-center items-center gap-2">
                                <div className="bg-Main w-fit rounded-full p-[1.2px]">
                                  <div
                                    className={`${
                                      formData?.commission_type === "MONETARY"
                                        ? "bg-Main"
                                        : "bg-white"
                                    }  w-[18px] h-[18px] rounded-full border-[3px] border-white`}
                                    onClick={() => {
                                      setFormData({
                                        ...formData,
                                        commission_type: "MONETARY",
                                        commission: 0,
                                      });
                                    }}
                                  />
                                </div>
                                <p className=" font-bold">مبلغ مقطوع</p>
                              </div>
                              <div className="flex justify-center items-center gap-2">
                                <div className="bg-Main w-fit rounded-full p-[1.2px]">
                                  <div
                                    className={`${
                                      formData?.commission_type === "PERCENTAGE"
                                        ? "bg-Main"
                                        : "bg-white"
                                    } w-[18px] h-[18px] rounded-full border-[3px] border-white`}
                                    onClick={() => {
                                      setFormData({
                                        ...formData,
                                        commission_type: "PERCENTAGE",
                                        commission: 0,
                                      });
                                    }}
                                  />
                                </div>
                                <p className=" font-bold">
                                  نسبة من الملف المالي
                                </p>
                              </div>
                            </div>
                            {formData?.commission_type && (
                              <Col>
                                <Col md={4} className="">
                                  <Input
                                    type="number"
                                    name="commission"
                                    value={formData?.commission}
                                    onChange={
                                      formData?.commission_type === "MONETARY"
                                        ? handleChangeInput
                                        : formData?.commission_type ===
                                          "PERCENTAGE"
                                        ? handleChangeInputNumber
                                        : handleChangeInput
                                    }
                                    title={
                                      formData?.commission_type === "MONETARY"
                                        ? "مبلغ مقطوع"
                                        : formData?.commission_type ===
                                          "PERCENTAGE"
                                        ? "نسبة من الملف المالي "
                                        : ""
                                    }
                                  />
                                </Col>
                              </Col>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {(selectRef.current?.options[
                      selectRef.current.selectedIndex
                    ]?.dataset.mode === "financial_collection_officer" ||
                      selectRef.current?.options[
                        selectRef.current.selectedIndex
                      ]?.dataset.mode === "register_manager") && (
                      <>
                        <div className="flex items-center gap-3 my-4  font-semibold">
                          <div
                            onClick={() => {
                              setIsCheckboxCheckedTow(!isCheckboxCheckedTow);
                              if (isCheckboxCheckedTow) {
                                setFormData({
                                  ...formData,
                                  fc_commission: 0,
                                });
                              }
                            }}
                            className={`w-6 h-6 border border-black rounded-md ${
                              isCheckboxCheckedTow ? "bg-Main" : ""
                            }`}
                          />
                          <p>إضافة رسوم التحصيل المالي للدفعة الواحدة</p>
                        </div>
                        {isCheckboxCheckedTow && (
                          <>
                            <h1 className="text-center  my-2 font-semibold">
                              رسوم التحصيل المالي للدفعة الواحدة
                            </h1>
                            <div className="flex justify-center items-center gap-10 mb-5 !mt-3">
                              <div className="flex justify-center items-center gap-2">
                                <div className="bg-Main w-fit rounded-full p-[1.2px]">
                                  <div
                                    className={`${
                                      formData?.fc_commission_type ===
                                      "MONETARY"
                                        ? "bg-Main"
                                        : "bg-white"
                                    } w-[18px] h-[18px] rounded-full border-[3px] border-white`}
                                    onClick={() => {
                                      setFormData({
                                        ...formData,
                                        fc_commission_type: "MONETARY",
                                        fc_commission: 0,
                                      });
                                    }}
                                  />
                                </div>
                                <p className=" font-bold">مبلغ مقطوع</p>
                              </div>
                              <div className="flex justify-center items-center gap-2">
                                <div className="bg-Main w-fit rounded-full p-[1.2px]">
                                  <div
                                    className={`${
                                      formData?.fc_commission_type ===
                                      "PERCENTAGE"
                                        ? "bg-Main"
                                        : "bg-white"
                                    } w-[18px] h-[18px] rounded-full border-[3px] border-white`}
                                    onClick={() => {
                                      setFormData({
                                        ...formData,
                                        fc_commission_type: "PERCENTAGE",
                                        fc_commission: 0,
                                      });
                                    }}
                                  />
                                </div>
                                <p className=" font-bold">
                                  نسبة من المبلغ الذي وصل
                                </p>
                              </div>
                            </div>
                            {formData?.fc_commission_type && (
                              <Col>
                                <Col md={4} className="">
                                  <Input
                                    type="number"
                                    name="fc_commission"
                                    value={formData?.fc_commission}
                                    onChange={
                                      formData?.fc_commission_type ===
                                      "MONETARY"
                                        ? handleChangeInput
                                        : formData?.fc_commission_type ===
                                          "PERCENTAGE"
                                        ? handleChangeInputNumber
                                        : handleChangeInput
                                    }
                                    title={
                                      formData?.fc_commission_type ===
                                      "MONETARY"
                                        ? "مبلغ مقطوع"
                                        : formData?.fc_commission_type ===
                                          "PERCENTAGE"
                                        ? "نسبة من  المبلغ الذي وصل"
                                        : ""
                                    }
                                  />
                                </Col>
                              </Col>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {selectRef.current?.options[selectRef.current.selectedIndex]
                      ?.dataset.mode !== "other" && (
                      <>
                        <Col md={3}>
                          <Input
                            name="username"
                            value={formData?.username}
                            onChange={handleChangeInput}
                            title="اسم المستخدم"
                          />
                        </Col>
                        <Col md={3}>
                          <PasswordInput
                            name="password"
                            onChange={handleChangeInput}
                          />
                        </Col>
                        <Col md={3}>
                          <PasswordInput
                            name="passwordConfirmation"
                            onChange={handleChangeInput}
                            placeholder="تأكيد كلمة السر"
                          />
                        </Col>
                      </>
                    )}
                    <Col md={3}>
                      <Input
                        name="name"
                        value={formData?.name}
                        onChange={handleChangeInput}
                        title="الاسم"
                      />
                    </Col>
                    <Col md={3}>
                      <Input
                        name="name_en"
                        value={formData?.name_en}
                        onChange={handleChangeInput}
                        title="الاسم  بالانكليزي"
                      />
                    </Col>
                    <Col md={3}>
                      <Input
                        name="email"
                        value={formData?.email}
                        onChange={handleChangeInput}
                        title="الإيميل"
                      />
                    </Col>
                    <Col md={3}>
                      <Input
                        name="phone_number"
                        value={formData?.phone_number}
                        onChange={handleChangeInput}
                        title="رقم الهاتف"
                      />
                    </Col>
                    <Col md={3}>
                      <Input
                        name="salary"
                        value={formData?.salary}
                        onChange={handleChangeInput}
                        title="الراتب"
                      />
                    </Col>
                    <Col md={3}>
                      <Input
                        name="iban"
                        value={formData?.iban}
                        onChange={handleChangeInput}
                        title="الايبان"
                      />
                    </Col>
                    <Col md={3}>
                      <Input
                        name="bank_name"
                        value={formData?.bank_name}
                        onChange={handleChangeInput}
                        title="اسم البنك"
                      />
                    </Col>

                    {selectRef.current?.options[selectRef.current.selectedIndex]
                      ?.dataset.mode === "other" && (
                      <>
                        <Col md={3}>
                          <Input
                            name="job_title"
                            value={formData?.job_title}
                            onChange={handleChangeInput}
                            title="المسمى وظيفي"
                          />
                        </Col>
                      </>
                    )}
                    {loading ? <Loading /> : ""}
                    <Col md={4} sm={10}>
                      <ButtonRed
                        name={!isAdd ? "تعديل" : "اضافة"}
                        onClick={handleSubmitMain}
                        className="py-3 mt-8 mx-7 bg-white flex justify-center !text-Third border-2 border-Third shadow-md shadow-Third"
                      />
                    </Col>
                  </>
                )}
              </>
            </Row>
          </Col>
        </Row>
      )}
     
    </div>
  );
};

export default AddUser;
