import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Input, Title } from "../../components";
import Loading from "../../Tools/Loading";

const AddAuthenticationTypes = () => {
  const { id } = useParams();
  const isAdd = id === "add";
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({});
  const { data, isLoading } = useFETCH(
    isAdd ? "" : `admin/authentication-types/${id}`
  );
  let dataOld = data?.data.data;
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      isAdd ? "admin/authentication-types" : `admin/authentication-types/${id}`
    );
  };
  useEffect(() => {
    !isAdd &&
      setFormData({
        name: dataOld?.name,
      });
  }, [dataOld]);
  return (
    <>
      <Row>
        <Col lg={6} md={6} sm={6}>
          <Title title="انواع التصديقات" className="" />
        </Col>
        <Col lg={5} md={5} sm={5}></Col>
        <Col lg={1} md={1} sm={1}>
          <Back />
        </Col>
      </Row>

      <Row className="h-[70vh] flex justify-center items-center">
        <Col
          md={4}
          className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto "
        >
          <Input
            name="name"
            value={formData?.name}
            onChange={handleChangeInput}
            title="ادخل نوع التصديق"
          />
          {loading || (isLoading && !isAdd) ? <Loading /> : ""}
          <ButtonRed
            name={isAdd ? "اضافة" : "تعديل"}
            onClick={handleSubmitMain}
            className="py-3 mt-3"
          />
        </Col>
      </Row>
      {/* <Back /> */}
    </>
  );
};

export default AddAuthenticationTypes;
