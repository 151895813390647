import { Col, Container, Row } from "../../Tools/Grid-system";
import {
  Add,
  Back,
  Data,
  DateFilter,
  DeleteParamsAll,
  Filter,
  Search,
  Table,
  Title,
} from "../../components";
import { CiSearch } from "react-icons/ci";
import { useClose, useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import StudentFilter from "../../components/Filter/StudentFilter";
import { useLocation } from "react-router-dom";
import { ImportExcel } from "../Processes/Processes";
import { useState } from "react";
import { MdMore } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";
import logo from "../../svg/logo.svg"
function Students() {
  const { search } = useLocation();
  const { open, setOpen, mouse } = useClose();
  const [btnfilters, setBtnfilters] = useState(false);
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();

  const { data, isLoading, deleteItem } = useFETCH(
    `admin/students${search}`,
    `admin/students`
  );
  const { data: dataAuthentication } = useFETCH(
    `admin/authentication-types?paginate=0`
  );
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      name: `${e.first_name} ${e.mid_name} ${e.last_name}`,
      phone_number: e.phone_number,
      email: e.email,
      sitting_number: e.sitting_number,
      degree: e.degree,
      transaction_creator: e.transaction_creator?.name,
      status: e.status,
    };
  });
  return (
    <>
      {isLoading ? <Loading /> : ""}
      <Row>
        <Col lg={6} md={6} className="flex justify-between ">
          <Title title="الطلاب" className="!text-center" />
          {/* <img src={logo} /> */}
        </Col>

        <Col
          lg={5}
          sm={6}
          md={5}
          className="flex justify-end max-sm:mx-5  w-full max-md:justify-center  "
        >
         
          <Col className=" flex-col">
            <Add link={`/students/add`} />
            <ImportExcel api="admin/students/import/excel" />
          </Col>
        </Col>

        <Col lg={1} className="flex justify-center ">
          <Back />
        </Col>

        {/* <Col lg={10} sm={12} className="flex justify-end   ">
          <ImportExcel api="admin/students/import/excel" />
        </Col> */}
      </Row>

      <Row justify="" className="">
        <Col lg={5} sm={11} md={5} className="flex justify-start mx-5 ">
          <div className="lg:w-[150px] max-md:w-full  ">
            <button
              onClick={() => setBtnfilters(!btnfilters)}
              className={`flex items-center justify-between px-4 gap-3 text-sm py-2 rounded-r-full bg-white hover:bg-white text-[#0d5069] w-full ${
                btnfilters ? "bg-white  mb-2" : ""
              }`}
            >
              <div className="flex items-center gap-2">
                <BiSearch size={20} className="text-[#0F566A]" />
                <span className="text-[#0d5069] text-lg max-md:text-sm font-bold">
                  فلترة
                </span>
              </div>
              {
                <RiArrowDownSLine
                  size={20}
                  className={`${
                    btnfilters
                      ? "rotate-180 transition-all"
                      : "rotate-0 transition-all"
                  } text-[#0d5069]`}
                />
              }
            </button>
          </div>
        </Col>
      </Row>

      {btnfilters && (
        <>
          <Row justify={"start"}>
          <DeleteParamsAll />
            <Col lg={2} sm={10} md={3} className="lg:mx-5 mx-auto">
              <DateFilter />
            </Col>
            <Col lg={2} sm={10} md={3} className="lg:mx-5 mx-auto">
              <DateFilter title=" الدفعات المحصلة" pushSearch="has_payment" />
            </Col>
            <Col
              lg={2}
              sm={10}
              md={3}
              className=" max-lg:mx-5 max-md:mx-auto"
            >
              
              <div ref={mouse} className="relative  ">
                <div
                  className={`lg:w-[90%] text-base max-md:text-sm max-md:w-[100%] max-md:mx-auto  bg-white rounded-xl font-bold shadow-Third shadow-sm flex justify-center gap-4 items-center py-3  text-Third`}
                  onClick={() => setOpen(!open)}
                >
                  بحث 
                  <CiSearch size={20} />
                </div>

                <div
                  className={`${
                    open
                      ? "absolute top-12 lg:w-[200px] max-md:w-full transition-all mx-auto  bg-white rounded-md text-center text-Third  mt-1"
                      : "hidden"
                  }`}
                >
                  <Filter onclick={() => console.log()} text="الاسم">
                    <Search className="w-[200px] text-black" name="name" />
                  </Filter>
                  <Filter onclick={() => console.log()} text="رقم الهاتف">
                    <Search
                      className="w-[200px] text-black"
                      name="phone_number"
                    />
                  </Filter>
                  <Filter onclick={() => console.log()} text="رقم الجلوس">
                    <Search
                      className="w-[200px] text-black"
                      name="sitting_number"
                    />
                  </Filter>
                </div>
              </div>
            </Col>
          
              {/* <DeleteParamsAll /> */}
            
          

            <Col lg={2} md={3}  sm={10} className="max-sm:mx-auto max-lg:mx-10">
              <select
                name="authentication_type_id"
                value={searchParams.get("authentication_type_id")}
                onChange={(e) => {
                  if (e.target.value) {
                    handleParamsClick("authentication_type_id", e.target.value);
                  } else {
                    handleParamsDelete("authentication_type_id");
                  }
                }}
                className={`h-[49px] w-full  text-Third font-bold text-base max-md:text-sm  max-md:w-[100%] shadow-Third shadow-sm border-Secondary  rounded-xl outline-none text-center mx-auto `}
              >
                <option value="" disabled selected hidden>
                  التصديق
                </option>
                <option value="">الكل</option>
                {dataAuthentication?.data.data.map((e) => (
                  <option value={e.id}>{e.name}</option>
                ))}
              </select>
            </Col>

            <Col lg={2} sm={10} md={3} className="max-md:mx-auto max-lg:mx-9 ">
              <StudentFilter />
            </Col>
          </Row>

          {/* <Row justify={"center"} className="!mt-3">
            <StudentFilter />
          </Row> */}
        </>
      )}

      <Data
        name="مجموع الطلاب "
        text={data?.data.data?.total}
        className="mx-5 max-md:text-lg"
      />
      <Table
        thead={[
          "اسم الطالب",
          "رقم الهاتف",
          "الايميل",
          "رقم الجلوس",
          "الدرجة العلمية",
          "مُسجل الطالب",
          "الحالة",
        ]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={[
          { name: "name" },
          { name: "phone_number" },
          { name: "email" },
          { name: "sitting_number" },
          { name: "degree" },
          searchParams.get("trashed") != 1 && { name: "transaction_creator" },
          { name: "status" },
        ]}
        funDelete={searchParams.get("trashed") != 1 && deleteItem}
        edit={searchParams.get("trashed") != 1 && "students"}
        shows={searchParams.get("trashed") != 1 && "students/info"}
        showProStudent={searchParams.get("trashed") != 1 && "true"}
        showEmail={searchParams.get("trashed") != 1 && "students/emails"}
        studentRecovery="true"
      />

      {/* <Back /> */}
    </>
  );
}
export default Students;
