import { Col } from "../../Tools/Grid-system";
import DeleteParamsAll from "./DeleteParamsAll";
import SingleButFiler from "./SingleButFiler";
function WalletFilter({ showDel }) {
  return (
    <>

  
    <DeleteParamsAll />
  
      <Col lg={3} md={3} sm={10} className=" max-md:mx-auto max-md:!mt-4">
        <SingleButFiler value="1" name="completed" title="المقبوضة" />
      </Col>

      <Col lg={3} md={3} sm={10} className="max-md:mx-auto max-md:!mt-2">
        <SingleButFiler value="0" name="completed" title=" الغير مقبوضة" />
      </Col>

        <Col lg={3} md={3} sm={10} className="max-md:mx-auto max-md:!mt-4"> 
        {localStorage.getItem("role_name") === "financial_manager" ||
        (localStorage.getItem("role_name") === "super_admin" && !showDel && (
          <SingleButFiler value="1" name="trashed" title="  سجلات المحذوفين " />
        ))}
        
        </Col>

    
    </>
  );
}
export default WalletFilter;
