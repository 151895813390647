import React, { createContext, useContext, useState } from "react";
const ContextOpen = createContext({});
const ContextProvider = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(true);
  const [newMessage, setNewMessage] = useState({});
  const [message, setMessage] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [successPost, setSuccessPost] = useState(0);
  const changeMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <ContextOpen.Provider
      value={{
        openMenu,
        changeMenu,
        setNewMessage,
        newMessage,
        message,
        setMessage,
        successMessage,
        setSuccessMessage,
        successPost,
        setSuccessPost,
      }}
    >
      {children}
    </ContextOpen.Provider>
  );
};

export default ContextProvider;
export const useContextHook = () => {
  return useContext(ContextOpen);
};
