import { HiFilter } from "react-icons/hi";
import { Col, Container, Row } from "../../Tools/Grid-system";
import {
  ArchiveFilter,
  Back,
  DeleteParamsAll,
  Filter,
  Search,
  Selected,
  Table,
  Title,
} from "../../components";
import { useClose, useFETCH, useFilter, usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import SingleButFiler from "../../components/Filter/SingleButFiler";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useContextHook } from "../../Context/ContextOPen";
import { SiMicrosoftexcel } from "react-icons/si";
function Transactions() {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  const { search } = useLocation();
  const [specialtySectionId, setSpecialtySectionId] = useState(1);
  const { handleSubmit, dataPlayer, loading } = usePOST({});
  const { open, setOpen, mouse } = useClose();
  const { data: dataSpecialty } = useFETCH(
    `admin/specialty-sections?paginate=0`
  );
  const { data: specialties } = useFETCH(
    `admin/specialty-sections/${specialtySectionId}/specialties?paginate=0`
  );
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/transactions${search}`,
    `admin/transactions`
  );
  const { setSuccessMessage } = useContextHook();
  useEffect(() => {
    if (dataPlayer?.data?.data.success) {
      setSuccessMessage(["تم ارسال التذكير الى ايميل الطالب"]);
    }
  }, [dataPlayer?.data.data.success]);
  useEffect(() => {
    handleParamsClick("in_archive", 0);
  }, []);
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      student_id: e.student?.id,
      date: e.date,
      student:
        e.student?.first_name +
        " " +
        e.student?.mid_name +
        " " +
        e.student?.last_name,
      specialty: e.specialty?.name,
      total_cost: e.total_cost,
      total_cost_after_discount: e.total_cost_after_discount,
      paid_amount: e.paid_amount,
      remaining_amount: e.remaining_amount,
      is_completed: e.is_completed,
    };
  });
  return (
    <div>
      <Container>
        <Row>
          <Col lg={6}>
            <Title title=" الملفات المالية" />
          </Col>
          <Col lg={5}></Col>
          <Col lg={1} className="flex justify-center ">
            <Back />
          </Col>
        </Row>

        <Row className="mb-5">
          <Col md={3} className="max-lg:mx-auto">
            <Search placeholder="اسم الطالب" />
          </Col>
          <Col md={3} className="max-lg:mx-auto">
            <div ref={mouse} className="relative">
              <button
                className={` w-full bg-white rounded-xl font-bold text-base flex justify-center gap-4 items-center border-solid border-Third border-1 py-3 px-5 text-Third shadow-sm shadow-Third`}
                onClick={() => setOpen(!open)}
              >
                فرز
                <HiFilter size={25} />
              </button>
              <div
                className={`${
                  open
                    ? "absolute w-full top-12 transition-all max-sm:right-1  bg-white rounded-md text-center space-y-2 py-2 px-5 text-Third mt-1"
                    : "hidden"
                }`}
              >
                <Filter
                  onclick={() => handleParamsDelete("date")}
                  text="التاريخ"
                >
                  <input
                    type="date"
                    placeholder="التارخ"
                    value={searchParams.get("date")}
                    onChange={(e) => handleParamsClick("date", e.target.value)}
                    className="text-black p-2 rounded-xl w-40 border-2 border-Secondary"
                  />
                </Filter>
                <Filter
                  onclick={() => handleParamsDelete("specialty_id")}
                  className="w-[300px] max-sm:w-[170px] !h-[200px]"
                  text="حسب التخصص"
                >
                  <Selected
                    className="!text-Third"
                    text="الكليات"
                    title="اختر الكلية"
                    value={setSpecialtySectionId}
                    onChange={(e) => {
                      setSpecialtySectionId(e.target.value);
                      if (searchParams.has("specialty_id")) {
                        handleParamsClick("specialty_id", "");
                      }
                    }}
                  >
                    {dataSpecialty?.data.data.map((e, i) => (
                      <option className="!text-Third" key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </Selected>
                  <div className="my-3">
                    <Selected
                      className="!text-Third"
                      text="التخصصات"
                      title="اختر التخصص"
                      value={searchParams.get("specialty_id")}
                      onChange={(e) =>
                        handleParamsClick("specialty_id", e.target.value)
                      }
                    >
                      {specialties?.data.data?.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </Selected>
                  </div>
                </Filter>
              </div>
            </div>
          </Col>
          <Col md={3} className="max-lg:mx-auto">
            <ArchiveFilter />
          </Col>
        </Row>
        <DeleteParamsAll />
        <Row className="!mt-5">

          <Col lg={3} md={4}>
            <SingleButFiler value="1" name="completed" title="مكتملة" />
          </Col>

          <Col lg={3} md={4}>
            <SingleButFiler value="0" name="completed" title="غير مكتملة" />
          </Col>

          <Col lg={3} md={4}>
            <SingleButFiler
              value="1"
              name="failed_to_pay"
              title="تم التأخير عن الدفع "
            />
          </Col>
        </Row>
        <Col className="max-lg:mt-5">
          <div className="flex items-center gap-2 mx-5">
            <SiMicrosoftexcel size={25} className="text-green-500" />
            <p>الملفات المالية المُنشأة من ملف إكسل</p>
          </div>
        </Col>
        {isLoading || loading ? <Loading /> : ""}
      </Container>
      <Table
        thead={[
          "التاريخ",
          "الطالب",
          "التخصص",
          "القيمة",
          "القيمة بعدالخصم",
          "المبلغ المدفوع",
          "المبلغ المتبقي",
          "الحالة",
        ]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={dataAll}
        tbody={[
          { name: "date" },
          { name: "student" },
          { name: "specialty" },
          { name: "total_cost" },
          { name: "total_cost_after_discount" },
          { name: "paid_amount" },
          { name: "remaining_amount" },
          { name: "is_completed" },
        ]}
        funDelete={deleteItem}
        summary={true}
        onClickSendToEmail={handleSubmit}
        edit="transactions"
        name="transactions"
        showTransactions="true"
        excel="true"
      />
      {/* <Back /> */}
    </div>
  );
}
export default Transactions;
