import { useState } from "react";
import { useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Col, Row } from "../../Tools/Grid-system";
import Table from "../MapData/Table";

function CommissionBtn() {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("2");

  const { data, isLoading } = useFETCH(
    show && `admin/commissions?${show}=1&state=${status}`,
    `admin/commissions`
  );

  const dataAllR = data?.data?.data?.commissions?.registration?.data?.map(
    (e) => {
      return {
        id: e.id,
        state: e.state === 2 ? "تمت الموافقة" : "بانتظار الموافقة",
        transaction_id: e.transaction_id,
        student_id: e.student?.id,
        date: e.date,
        student:
          e.student?.first_name +
          " " +
          e.student?.mid_name +
          " " +
          e.student?.last_name,
        specialty: e.specialty?.name,
        total_cost: e.total_cost,
        total_cost_after_discount: e.total_cost_after_discount,
        paid_amount: e.paid_amount,
        remaining_amount: e.remaining_amount,
        is_completed: e.is_completed,
      };
    }
  );
  const dataAllC = data?.data?.data?.commissions?.collected_payments?.data?.map(
    (e) => {
      return {
        transaction_id: e.transaction_id,
        state: e.state === 2 ? "تمت الموافقة" : "بانتظار الموافقة",
        date: e.date,
        amount: e.amount,
        transfer_amount: e.transfer_amount,
        received_amount: e.received_amount,
      };
    }
  );
  return (
    <div>
      {show && isLoading ? <Loading /> : ""}
      <div className="flex items-center justify-center gap-2 min-w-[320px]">
        {localStorage.getItem("role_name") !==
          "financial_collection_officer" && (
          <Btn
            title="عمولات التسجيل"
            name={show === "registration"}
            onClick={() => setShow("registration")}
          />
        )}
        <Btn
          title="عمولات التحصيل"
          name={show === "collected_payments"}
          onClick={() => setShow("collected_payments")}
        />
      </div>
      {show === "registration" && (
        <>
          <Row>
            <EmailBtnFilter
              onClick={() => setStatus("2")}
              title="مكتملة"
              name={status === "2"}
            />
            <EmailBtnFilter
              onClick={() => setStatus("1")}
              title="غير مكتملة"
              name={status === "1"}
            />
          </Row>
          <Table
            thead={[
              "تاريخ",
              "الطالب",
              "التخصص",
              "قيمة",
              "القيمة بعدالخصم",
              "المبلغ المدفوع",
              "المبلغ المتبقي",
              "الحالة",
            ]}
            pageCount={Math.ceil(
              data?.data?.data?.commissions?.registration?.total /
                data?.data?.data?.commissions?.registration?.per_page
            )}
            tData={dataAllR}
            tbody={[
              { name: "date" },
              { name: "student" },
              { name: "specialty" },
              { name: "total_cost" },
              { name: "total_cost_after_discount" },
              { name: "paid_amount" },
              { name: "remaining_amount" },
              { name: "is_completed" },
            ]}
            showTransactions="true"
            summary={true}
            name="transactions"
          />
        </>
      )}
      {show === "collected_payments" && (
        <>
          <Row>
            <EmailBtnFilter
              onClick={() => setStatus("2")}
              title="مكتملة"
              name={status === "2"}
            />
            <EmailBtnFilter
              onClick={() => setStatus("1")}
              title="غير مكتملة"
              name={status === "1"}
            />
          </Row>
          <Table
            thead={[
              "حالة الدفعة",
              "تاريخ الدفعة",
              "قيمة الدفعة",
              "قيمة الحوالة",
              "المبلغ الذي وصل",
              "الحالة",
            ]}
            pageCount={Math.ceil(
              data?.data?.data?.commissions?.collected_payments?.total /
                data?.data?.data?.commissions?.collected_payments?.per_page
            )}
            tData={dataAllC}
            tbody={[
              { name: "state" },
              { name: "date" },
              { name: "amount" },
              { name: "transfer_amount" },
              { name: "received_amount" },
              { name: "is_completed" },
            ]}
            transaction2="transaction"
          />
        </>
      )}
    </div>
  );
}
export default CommissionBtn;

const EmailBtnFilter = ({ title, onClick, name }) => {
  return (
    <Col lg={3} md={4} xs={6}>
      <div
        className={`mt-3 border-2 border-Main  rounded-xl hover:bg-opacity-70 text-center cursor-pointer w-full px-5 py-[0.6rem]
       ${name ? "bg-Main text-white" : "bg-white text-Main"} `}
        onClick={onClick}
      >
        {title}
      </div>
    </Col>
  );
};

const Btn = ({ title, onClick, name }) => {
  return (
    <div
      className={`border-2 border-Third  rounded-xl hover:bg-opacity-70 text-center cursor-pointer w-full px-5 py-[0.6rem]
       ${name ? "bg-Third text-white" : " bg-white text-Third"} `}
      onClick={onClick}
    >
      {title}
    </div>
  );
};
