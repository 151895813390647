import { AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
const Update = ({ edit, show }) => {
  return (
    <>
      <div className="w-fit relative">
        <div id="my-anchor-element-edit">
          <Link to={`${edit}`}>
            <div
              className={`${
                localStorage.getItem("role_name") ===
                  "financial_collection_officer" && !show
                  ? "hidden"
                  : ""
              }  w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
            >
              <AiOutlineEdit size={30} className="text-green-700" />
            </div>
          </Link>
          <Tooltip anchorSelect="#my-anchor-element-edit" content="تعديل" />
        </div>
      </div>
    </>
  );
};

export default Update;
