import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Add, Back, Table, Title } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import { useEffect } from "react";

function Payments() {
  const { data, deleteItem, isLoading, setData } = useFETCH(
    'admin/payment/methods'
  );


  useEffect(() => {
    if (data) {
        setData(prevData => ({
            ...prevData,
            data: {
                ...prevData.data,
                data: {
                    ...prevData.data.data,
                    data:  data.data.data.data.map(e => {
                      switch (e.type) {
                          case "CURRENCY_EXCHANGE_OFFICE":
                              e.type = "مكتب صرافة ";
                              break;
                          case "WESTERN_UNION":
                              e.type = "ويسترين يونيون";
                              break;
                          case "BANK_ACCOUNT":
                              e.type = "حساب بنكي  ";
                              break;
                          case "STRIPE ":
                              e.type = "سترايب ";
                              break;
                          case "OTHER":
                              e.type = " أخرى ";
                              break;
                      }
                      return e;
                  })
                }
            }
        }));
    }
}, [data]);


  return (
    <>
      <Row>
        <Col lg={6}>
          <Title title="طرق الدفع" />
        </Col>
        <Col lg={5} className="flex lg:justify-end">
          <Add link={`/payments/add`} />
        </Col>
        <Col lg={1} className="flex justify-center ">
          <Back />
        </Col>
      </Row>

      {isLoading ? <Loading /> : ""}
      <Table
        thead={["الاسم", "طريقة الدفع"]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[{ name: "name" }, { name: "type" }]}
        funDelete={deleteItem}
        edit="payments"
      />

      {/* <Back /> */}
    </>
  );
}
export default Payments;
