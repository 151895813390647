import React, { useState } from "react";
import { MdMore } from "react-icons/md";
import { RiArrowDownSLine, RiLockPasswordLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const Accordion = ({ list, name }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  
  return (
    <div className="">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className={`flex items-center justify-between px-4 gap-3 text-sm py-2 rounded-r-full hover:bg-[#F5F5F5] text-[#0d5069] w-full ${
          accordionOpen ? "bg-[#EBEFF4]  mb-2" : ""
        }`}
      >
        <div className="flex items-center gap-2">
          <MdMore size={20} className="text-[#0F566A]" />
          <span className="text-[#0d5069]">{name}</span>
        </div>
        {
          <RiArrowDownSLine
            size={20}
            className={`${
              accordionOpen
                ? "rotate-180 transition-all"
                : "rotate-0 transition-all"
            } text-[#0d5069]`}
          />
        }
      </button>
      <div
        className={`grid overflow-hidden w-[90%] mr-5 transition-all duration-300 ease-in-out text-[#0d5069] text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="overflow-hidden px-1 border-r-2 space-y-1">
          {list.map((e, i) => (
            <li className="" key={i}>
              <NavLink
                to={e.link}
                className="flex items-center gap-3 text-sm px-2 py-2 rounded-full hover:bg-[#F5F5F5] text-[#0d5069]"
              >
                {e.image ? (
                  <img
                    src={e.image}
                    alt={e.name}
                    className="w-[20px] h-[20px]"
                  />
                ) : (
                  <RiLockPasswordLine size={25} className="text-[#0F566A]" />
                )}
                <p className="text-">{e.name}</p>
              </NavLink>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
