import React from "react";
import { useFETCH } from "../../Tools/APIs";
import { useParams } from "react-router-dom";
import Loading from "../../Tools/Loading";
import { Back } from "../../components";

const IframePDF = ({ summary }) => {
  const { id, idPayment } = useParams();
  const { data, isLoading } = useFETCH(
    summary
      ? `admin/transactions/${id}/payments/summary/pdf`
      : `admin/transactions/${id}/payments/${idPayment}/receipt`
  );

  return (
    <>
      <iframe
        className="w-full h-[80vh] px-3 pt-14"
        src={data?.data.data.receipt_url}
      ></iframe>
      {isLoading ? <Loading /> : ""}
      <Back />
    </>
  );
};

export default IframePDF;
