import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, ButtonRed, Table, Title } from "../../components";
import { SiMicrosoftexcel } from "react-icons/si";

function StudentTransactions() {
  const { id } = useParams({});
  const { setSuccessMessage } = useContextHook();
  const { data: dataStudent } = useFETCH(`admin/students/${id}`);
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/transactions?student_id=${id}`,
    `admin/transactions`
  );
  const { handleSubmit, dataPlayer, loading } = usePOST({});
  useEffect(() => {
    if (dataPlayer?.data?.data.success) {
      setSuccessMessage(["تم ارسال التذكير الى ايميل الطالب"]);
    }
  }, [dataPlayer?.data.data.success]);
  return (
    <div>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={6}>
            <Title
              title={`${
                (dataStudent?.data?.data.first_name || "") +
                " " +
                (dataStudent?.data?.data.mid_name || "") +
                " " +
                (dataStudent?.data?.data.last_name || " ")
              }  » الملف المالي`}
            />
          </Col>
          <Col lg={5} md={5} sm={5}></Col>
          <Col lg={1} md={1} sm={1}>
            <Back />
          </Col>
        </Row>
              
        {isLoading || loading ? <Loading /> : ""}
      </Container>
      {data?.data?.data?.data?.length <= 0 && (
        <Col xs={5} md={2} className="mx-auto">
          <ButtonRed
            link={`/students/${id}/transactions/add`}
            name="انشاء الملف المالي"
          />
        </Col>
      )}
      <Col md={3}>
        <div className="flex items-center gap-2 mx-5">
          <SiMicrosoftexcel size={25} className="text-green-500" />
          <p>الدفعات المُدخلة من ملف إكسل</p>
        </div>
      </Col>
      <Table
        thead={[
          "تاريخ ",
          "التخصص",
          "قيمة ",
          "القيمة  بعد الخصم",
          "المبلغ  المدفوع",
          "المبلغ  المتبقي",
          "الحالة ",
        ]}
        tData={data?.data.data.data}
        tbody={[
          { name: "date" },
          { name: "specialty", object: "name" },
          { name: "total_cost" },
          { name: "total_cost_after_discount" },
          { name: "paid_amount" },
          { name: "remaining_amount" },
          { name: "is_completed" },
        ]}
        summary={true}
        onClickSendToEmail={handleSubmit}
        funDelete={deleteItem}
        edit="transactions"
        name="transactions"
        showTransactions="true"
        excel="true"
      />

      
    </div>
  );
}
export default StudentTransactions;
