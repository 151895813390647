import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";

function PasswordInput({ onChange, value, name, placeholder }) {
  const [biShow, setBiShow] = useState(false);
  return (
    <>
      <div className="my-2 px-1">
        <h1 className="text-start text-Third my-2 font-semibold">
          {placeholder || "كلمة السر"}
        </h1>
        <div className="relative">
          <input
            style={{
              boxShadow:
                "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              border: "1px solid black",
            }}
            type={!biShow ? "password" : "text"}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder || "كلمة السر"}
            autocomplete="off"
            className={"w-full border-2 border-Secondary m-1 py-3 rounded-xl mx-auto"}
          />
          {biShow ? (
            <BiHide
              onClick={() => setBiShow(false)}
              size={25}
              className="absolute left-3 top-4 cursor-pointer"
            />
          ) : (
            <BiShow
              onClick={() => setBiShow(true)}
              size={25}
              className="absolute left-3 top-4 cursor-pointer"
            />
          )}
        </div>
      </div>
    </>
  );
}
export default PasswordInput;
