import { Link, useParams } from "react-router-dom";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Data, Title } from "../../components";
import Loading from "../../Tools/Loading";
import transactions from "../../svg/sort_12963813.svg";
import { Tooltip } from "react-tooltip";
import { AiFillPrinter } from "react-icons/ai";
import { MdBlockFlipped } from "react-icons/md";

function PaymentDetails() {
  const { id, idd } = useParams();
  const { data, isLoading } = useFETCH(
    `admin/transactions/${id}/payments/${idd}`
  );
  let dataAll = data?.data.data;

  return (
    <div>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Row>
          <Col lg={6} md={6} sm={6}>
            <Title title="الدفعة" />
          </Col>
          <Col lg={5} md={5} sm={5}></Col>
          <Col lg={1} md={1} sm={1}>
            <Back />
          </Col>
        </Row>

        <Col className="bg-white mx-auto border-2 w-[90%] border-Secondary py-5 px-3 !mt-3 rounded-xl">
          <Row>
            <Row>
              <Col lg={1} sm={1} md={1} >
                <Link to={`/transactions/${id}/payment`}>
                  <div
                    className={`w-10 h-10  rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                  >
                    <div id="payment">
                      <img src={transactions} alt="" className="w-7 h-7" />
                      <Tooltip
                        anchorSelect="#payment"
                        content="عرض  الملف المالي"
                      />
                    </div>
                  </div>
                </Link>
              </Col>

              <Col lg={1} sm={1} md={1} className="max-md:mx-6">
                {/* {e.state === 2 && ( */}
                <div
                  className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                >
                  <div id="ff">
                    <Link to={`/transactions/${id}/payment/pdf/${id}`}>
                      <AiFillPrinter size={25} className="text-gray-600" />
                      <Tooltip
                        anchorSelect="#ff"
                        content="طباعة الفاتورة"
                      />
                    </Link>
                  </div>
                </div>
                {/* )} */}
                </Col>

                {/* {e.state === 1 && ( */}
                {/* <Col lg={1}>
                  <div
                    className={`w-10 h-10 rounded-full flex justify-center items-center`}
                  >
                    <div id="my-anchor-element-4">
                      <MdBlockFlipped size={25} className="text-red-700" />
                      <Tooltip
                        anchorSelect="#my-anchor-element-4"
                        content="غير مسموح بطباعة الفاتورة"
                      />
                    </div>
                  </div>
                </Col> */}
                {/* )} */}
              
            </Row>

            <Col lg={3} md={4}>
              <Data name="قيمة الدفعة الإجمالية" text={dataAll?.amount} />
            </Col>
            {localStorage.getItem("role_name") === "register_manager" ||
            localStorage.getItem("role_name") ===
              "financial_collection_officer" ? (
              ""
            ) : (
              <Col lg={3} md={4}>
                <Data
                  name="حالة الدفعة"
                  text={dataAll?.state === 2 ? "مقبولة" : "مرفوضة"}
                />
              </Col>
            )}
            <Col lg={3} md={4}>
              <Data name="تاريخ الدفعة" text={dataAll?.date} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="طريقة الدفع" text={dataAll?.payment_method?.name} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="ملاحظة" text={dataAll?.notes} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="الدفعة المستلمة" text={dataAll?.received_amount} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="الدفعة المُحولة" text={dataAll?.transfer_amount} />
            </Col>
            <Col
              lg={3}
              md={4}
              className={`${
                localStorage.getItem("role_name") ===
                  "financial_collection_officer" ||
                localStorage.getItem("role_name") === "register_manager"
                  ? "hidden"
                  : ""
              }`}
            >
              <Data
                name="تاريخ وصول الحوالة"
                text={dataAll?.transfer_arrival_date}
              />
            </Col>
            <Col lg={3} md={4}>
              <Data name="تاريخ الحوالة" text={dataAll?.transfer_date} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="رقم الحوالة" text={dataAll?.transfer_number} />
            </Col>
            <Col lg={3} md={4}>
              <Data
                name="اسم مستلم الحوالة"
                text={dataAll?.transfer_recipient_name}
              />
            </Col>
            <Col lg={3} md={4}>
              <Data
                name="اسم الذي قام بالحوالة"
                text={dataAll?.transferred_by}
              />
            </Col>
            <Col
              lg={3}
              md={4}
              className={`${
                localStorage.getItem("role_name") ===
                  "financial_collection_officer" ||
                localStorage.getItem("role_name") === "register_manager"
                  ? "hidden"
                  : ""
              }`}
            >
              <Data name="المبلغ الذي وصل" text={dataAll?.received_amount} />
            </Col>

            {localStorage.getItem("role_name") === "financial_manager" ||
              (localStorage.getItem("role_name") === "super_admin" && (
                <Col lg={3} md={4}>
                  <Data
                    name="عمولة مستلم الحوالة"
                    text={dataAll?.transfer_recipient_commission}
                  />
                </Col>
              ))}
            <Col lg={12} md={12} className="!mt-3">
              <ButtonRed
                link={`/transactions/${id}/payment/${idd}`}
                name="تعديل الدفعة"
                className="!w-fit !px-3"
              />
            </Col>

            <Col lg={3} md={4}></Col>
            <Col xs={6} lg={12} className="flex justify-center">
              <img
                onClick={clickZoomInImage}
                src={fileUrl + dataAll?.transfer_image}
                alt=""
                className="rounded-3xl mt-5 lg:w-[300px]"
              />
            </Col>
          </Row>
        </Col>
      </Container>
      {/* <Back /> */}
    </div>
  );
}
export default PaymentDetails;
