import { useLocation } from "react-router-dom";
import { useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Add, Back, Table, Title } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";

function Items() {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/categories?page=${search}`,
    `admin/categories`
  );
  return (
    <>
      <Row>
        <Col lg={6}>
          <Title title="الأصناف" />
        </Col>
        <Col lg={5} className="flex lg:justify-end">
          <Add link={`/items/add`} />
        </Col>
        <Col lg={1} className="flex justify-center ">
        <Back />
        </Col>
      </Row>

      {isLoading ? <Loading /> : ""}
      <Table
        thead={["الصنف"]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[{ name: "display" }]}
        funDelete={deleteItem}
        edit="items"
        showPro="true"
      />

      {/* <Back /> */}
    </>
  );
}
export default Items;
