import { AiFillLeftCircle } from "react-icons/ai";
import { BiLeftArrow } from "react-icons/bi";
import { HiArrowLeft } from "react-icons/hi";
import { Tooltip } from "react-tooltip";

const Back = () => {
  return (
    <div className="top-10 left-5 z-20 max-lg:hidden">
      <div id=" my-anchor-element-back ">
        <AiFillLeftCircle
          className="  cursor-pointer text-[#0d5069]"
          size={40}
          onClick={() => window.history.go(-1)}
        />
        <Tooltip anchorSelect="#my-anchor-element-back" content="رجوع" />
      </div>
    </div>
  );
};

export default Back;
