import "./Loading.css";

const Loading = () => {
  return (
    <div className="fixed w-full h-full bg-[#ffffff77] top-0 left-0 z-[90]">
      <div className="loading"></div>
    </div>
  );
};

export default Loading;
