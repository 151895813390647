import { CiSearch } from "react-icons/ci";
import { useFilter } from "../../Tools/APIs";
import { useState } from "react";

function Search({ className, name, placeholder }) {
  const { handleParamsClick } = useFilter();
  const [typingTimeout, setTypingTimeout] = useState("");
  return (
    <div
      className={`bg-white w-[200px] max-md:w-[200px] border-2 lg:mx-3 shadow-sm shadow-Third border-Third flex items-center justify-between space-x-2 p-2 rounded-xl ${className}`}
    >
      <input
        type="search"
        placeholder={placeholder ? placeholder : "إبحث"}
        onChange={(e) => {
          const { value } = e.target;
          clearTimeout(typingTimeout);
          const newTypingTimeout = setTimeout(() => {
            handleParamsClick("search", value);
            if (name) {
              handleParamsClick("sort_by", name);
            }
          }, 1000);
          setTypingTimeout(newTypingTimeout);
        }}
        className="w-full "
      />
      <span>
        <CiSearch size={20} className="h-full" />
      </span>
    </div>
  );
}
export default Search;
