import { useEffect } from "react";
import { BsWalletFill } from "react-icons/bs";
import { Link, useLocation, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useContextHook } from "../../Context/ContextOPen";
import { useFETCH, useFilter, usePOST } from "../../Tools/APIs";
import AddImage from "../../Tools/AddFile";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import {
  Add,
  ArchiveFilter,
  Back,
  ButtonRed,
  DateFilter,
  Delete,
  Selected,
  Title,
  Update,
} from "../../components";
import Download from "../../components/Delete/Download";
import UpLoad from "../../components/Delete/UpLoad";

function Processes() {
  const { idItems } = useParams();
  const { searchParams, handleParamsClick } = useFilter();
  const { search } = useLocation();
  const { handleChangeInput, handleSubmit, formData, loading } = usePOST({});
  const { setSuccessPost, successPost } = useContextHook();

  const { data: dataAdmin } = useFETCH(`admin/users?paginate=0`);
  const { data: dataBeneficiaries } = useFETCH(
    `admin/users?role=beneficiary&paginate=0`
  );
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/operations${search}`,
    `admin/operations`
  );
  useEffect(() => {
    handleParamsClick("in_archive", 0);
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col lg={6} md={6}>
            <TitleProcesses searchParams={searchParams} />
          </Col>

          <Col lg={5} sm={7} md={5} className="flex justify-end ">
            <div className="flex flex-col">
              <Add
                link={
                  idItems ? `/items/${idItems}/processes/add` : "/processes/add"
                }
              />

              <ImportExcel api="admin/operations/import/excel" />
            </div>
          </Col>
          <Col lg={1} className="flex justify-center ">
            <Back />
          </Col>
        </Row>

        <Row className="items-center lg:!mx-3">
          <Col xs={6} md={4} lg={3} className="lg:!mt-3">
            <DateFilter />
          </Col>
          <Col xs={6} md={4} lg={3} className="lg:!mt-3 max-sm:!mt-3">
            <ArchiveFilter />
          </Col>
          <Col xs={6} md={4} lg={3} className="max-lg:mb-5" >
            <Selected
              title="فلترة حسب الكادر"
              value={searchParams?.get("user_id")}
              onChange={(e) => handleParamsClick("user_id", e.target.value)}
              className="border-none w-full font-bold text-base  bg-white shadow-sm shadow-Third text-center px-5 text-Third outline-none"
            >
              <option value="">الكل</option>
              {dataAdmin?.data.data.map((e) => (
                <option className="" key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Selected>
          </Col>
          <Col xs={7} md={4} lg={3} >
            <Selected
              title="الجهة المستفيدة"
              value={searchParams?.get("user_id")}
              onChange={(e) => handleParamsClick("user_id", e.target.value)}
              className="border-none font-bold text-base w-full bg-white text-center px-5 shadow-sm shadow-Third text-Third outline-none"
            >
              <option value="">الكل</option>
              {dataBeneficiaries?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Selected>
          </Col>
        </Row>
        {isLoading ? <Loading /> : ""}
      </Container>

      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row justify="center md:px-3">
          <Col className="overflow-x-auto ">
            <table className="w-full max-md:w-[700px] mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-Main">
              <thead className="table-thead text-white h-[70px] font-bold text-base">
                <tr>
                  <td className="px-7">التاريخ</td>
                  <td className="px-7">المبلغ</td>
                  <td className="px-7">صرفها</td>
                  <td className="px-7">تم صرفها ل</td>
                  <td className="px-7 ">الصنف</td>
                  <td className="px-7">الحالة</td>
                  <td className="px-7">ملاحظات</td>
                  <td className="px-7">العمليات</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e, i) => (
                    <tr key={i} className="border border-Main bg-white">
                      <td className="font-bold ">{e.date}</td>
                      <td className="font-semibold py-7">{e.amount}</td>
                      <td className="">{e.added_by_admin?.name || "___"}</td>
                      <td className="">{e.user?.name}</td>
                      <td className="font-bold ">{e.category?.display}</td>
                      {e.status === "PENDING" ? (
                        <td className="text-red-500 font-semibold">
                          {"غير مصروفة"}
                        </td>
                      ) : e.status === "COMPLETED" ? (
                        <td className="text-green-500 font-semibold">
                          {"مصروفة"}
                        </td>
                      ) : (
                        ""
                      )}
                      <td className="">{e.notes || "___"}</td>
                      <td className="flex justify-center items-center gap-3 h-full">
                        <div className="flex justify-center items-center gap-3 mt-4">
                          <>
                            <div id="my-anchor-element-wallet">
                              <div
                                className={` w-10 h-10 rounded-full flex justify-center items-center cursor-pointer `}
                              >
                                <Link
                                  to={`/processes/wallet/${e.balance_log?.user_id}?model_id=${e.balance_log?.id}`}
                                >
                                  <BsWalletFill
                                    size={30}
                                    className="text-sky-950"
                                  />
                                </Link>
                              </div>
                              <Tooltip
                                anchorSelect="#my-anchor-element-wallet"
                                content="الذهاب الى السجل الخاص بالمصروف"
                              />
                            </div>
                          </>

                          {e.status !== "COMPLETED" ? (
                            <>
                              <Update
                                name={e.name}
                                edit={
                                  idItems
                                    ? `/items/${idItems}/processes/${e.id}`
                                    : `/processes/${e.id}`
                                }
                              />

                              <Delete id={e.id} onClick={() => deleteItem(e)} />
                            </>
                          ) : (
                            <div className="w-24" />
                          )}
                          {e.in_archive === 1 ? (
                            <UpLoad name="operations" iddd={`${e.id}`} />
                          ) : (
                            ""
                          )}
                          {e.in_archive === 0 ? (
                            <Download name="operations" iddd={`${e.id}`} />
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      {!isLoading && (
        <div
          className={`bg-Third rounded-xl text-center font-semibold mb-3 text-white w-[250px] mx-auto py-3`}
        >
          {data?.data.total_amount}
        </div>
      )}

      {/* <Back /> */}
    </>
  );
}
export default Processes;

const TitleProcesses = ({ searchParams }) => {
  const { data } = useFETCH(
    searchParams.has("category_id") &&
      `admin/categories/${searchParams?.get("category_id")}`
  );
  return (
    <>
      {searchParams.has("category_id") ? (
        <>
          <Title title={data?.data.data.name} link="/items" />
          <Title ball title="المصاريف" />
        </>
      ) : (
        <Title title="المصاريف" />
      )}
    </>
  );
};
export const ImportExcel = ({ api }) => {
  const { setSuccessPost, successPost } = useContextHook();
  const { handleChangeInput, handleSubmit, formData, loading } = usePOST({});
  const handleSubmitMain = () => {
    setSuccessPost(0);
    handleSubmit(api, "", true);
  };
  return (
    <Row className="flex justify-end items-center w-40  ">
      {loading ? <Loading /> : ""}
      <Col lg={12} className="mx-auto ">
        <AddImage
          accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          title="استيراد"
          name="file"
          className="!text-lg"
          onChange={(e) => {
            setSuccessPost(0);
            handleChangeInput(e);
          }}
        />
      </Col>
      {formData?.file && successPost === 0 && (
        <Col md={2}>
          {formData?.file.name}
          <ButtonRed
            successPost={successPost}
            popup
            text={`هل انت متأكد من أضافة الملف ${formData?.file.name} `}
            name="أضافة"
            className="my-1 py-[9px] "
            onClick={handleSubmitMain}
          />
        </Col>
      )}
    </Row>
  );
};
