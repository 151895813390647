import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useFETCH, useFilter } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import DeleteParamsAll from "./DeleteParamsAll";
import SingleButFiler from "./SingleButFiler";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { RiArrowDownSLine } from "react-icons/ri";
function StudentFilter() {
  const {
    searchParams,
    handleParamsClick,
    handleParamsDelete,
    handleParamsDeleteAll,
  } = useFilter();
  const { data: dataAuthentication } = useFETCH(
    `admin/authentication-types?paginate=0`
  );

  const [stuFilter, setStufilter] = useState(false);
  const { search } = useLocation();

  return (
    <>
      {/* <DeleteParamsAll /> */}

      {/* <Row justify={"center"}>
      <Col lg={4} md={4} xs={6} sm={11} className="!mt-3 flex justify-center"> */}
      <div className="w-full max-md:w-full lg:mx-5  lg:mt-3 ">
        <button
          onClick={() => setStufilter(!stuFilter)}
          className={`h-[48px] w-full  text-Third flex justify-center items-center bg-white font-bold text-base max-md:text-sm  max-md:w-[100%] shadow-Third shadow-sm border-Secondary  rounded-xl outline-none text-center mx-auto  ${
            stuFilter ? "bg-white  mb-2" : ""
          }`}
        >
          <div className="flex items-center ">
            {/* <BiSearch size={20} className="text-[#0F566A]" /> */}
            <span className="text-[#0d5069] text-base max-md:text-sm font-bold  ">
              الطلاب
            </span>
          </div>
          {
            <RiArrowDownSLine
              size={20}
              className={`${
                stuFilter
                  ? "rotate-180 transition-all"
                  : "rotate-0 transition-all"
              } text-[#0d5069]`}
            />
          }
        </button>
      </div>
      {/* </Col>
      </Row> */}

      {stuFilter && (
        <>
   
            <SingleButFiler value="1" name="completed" title=" الفعالين" />
            <SingleButFiler value="0" name="completed" title=" الغير فعالين" />
            <SingleButFiler value="1" name="trashed" title="  المحذوفين" />
            <SingleButFiler value="0" name="is_new" title="  الطلاب القدامى" />
            <SingleButFiler value="1" name="is_new" title="  الطلاب الجدد" />
        
        </>
      )}

      {/* <Row>
     <Col md={4} xs={6} sm={11} className="!mt-3">
        <select
          name="authentication_type_id"
          value={searchParams.get("authentication_type_id")}
          onChange={(e) => {
            if (e.target.value) {
              handleParamsClick("authentication_type_id", e.target.value);
            } else {
              handleParamsDelete("authentication_type_id");
            }
          }}
          className={`h-[40px] w-full max-md:mx-3 text-Third font-bold text-base max-md:text-sm  max-md:w-[94%] shadow-Third shadow-sm border-Secondary  rounded-xl outline-none text-center mx-auto `}
        >
          <option value="" disabled selected hidden>
            التصديق
          </option>
          <option value="">الكل</option>
          {dataAuthentication?.data.data.map((e) => (
            <option value={e.id}>{e.name}</option>
          ))}
        </select>
      </Col>
     </Row> */}
    </>
  );
}
export default StudentFilter;
