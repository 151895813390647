import { MdUnarchive } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import Popup from "../Popup/Popup";
const UpLoad = ({ iddd, name, showTransactions }) => {
  const { handleSubmit, loading } = usePOST({});
  const { pathname } = useLocation();
  const handleClick = () => {
    handleSubmit(`admin/${name}/${iddd}/manage/archive?in_archive=0`, "", true);
  };
  return (
    <>
      {loading ? <Loading /> : ""}
      <Popup
        id="archive"
        text={
          pathname === "/transactions"
            ? " إلغاء أرشفة الملف المالي "
            : "إلغاء أرشفة المصروف"
        }
        description={
          <p className="font-semibold text-xl text-center py-3 ">
            {!showTransactions
              ? "إلغاء أرشفة المصروف"
              : "إلغاء أرشفة الملف المالي"}
          </p>
        }
        Icon={MdUnarchive}
        iconColor="text-gray-600"
        onClick={handleClick}
      >
        <MdUnarchive size={27} className="text-gray-600" />
      </Popup>
    </>
  );
};

export default UpLoad;
