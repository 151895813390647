import { useEffect } from "react";
import { MdArchive } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import Popup from "../Popup/Popup";
const Download = ({ iddd, name, showTransactions }) => {
  const { handleSubmit, loading, dataPlayer } = usePOST({});
  const { pathname } = useLocation();
  const handleClick = () => {
    handleSubmit(`admin/${name}/${iddd}/manage/archive?in_archive=1`, "", true);
  };
  useEffect(() => {
    if (dataPlayer?.data.code) {
    }
  }, [dataPlayer?.data.code]);
  return (
    <>
      {loading ? <Loading /> : ""}
      <Popup
        id="Unarchive"
        text={
          pathname === "/transactions" ? "أرشفة الملف المالي " : "أرشفة المصروف"
        }
        description={
          <p className="font-semibold text-xl text-center py-3 ">
            {!showTransactions
              ? "هل تريد أرشفة المصروف"
              : " هل تريد أرشفة الملف المالي "}
          </p>
        }
        Icon={MdArchive}
        iconColor="text-blue-700"
        onClick={handleClick}
      >
        <MdArchive size={30} className="text-blue-700" />
      </Popup>
    </>
  );
};

export default Download;
