import { useFilter } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";

const AdminsFilter = () => {
  const { handleParamsClick, searchParams, handleParamsDelete } = useFilter();
  return (
    <>
      <Col lg={3} md={4} xs={6} sm={12} className="flex justify-start">
        <select
       
          value={searchParams.get("role")}
          onChange={(e) => handleParamsClick("role", e.target.value)}
          className="lg:w-[180px] font-bold text-lg max-md:w-full bg-white rounded-2xl py-2 text-center px-5 text-Third shadow-Third shadow-sm "
        >
          <option className="text-base" value="" onClick={() => handleParamsDelete("role")}>
            الكل
          </option>
          <option className="text-base"  value="financial_manager">المدراء الماليين</option>
          <option className="text-base"  value="register_manager">مدراء التسجيل</option>
          <option className="text-base"  value="financial_collection_officer">
            موظفو التحصيل المالي
          </option>
          <option className="text-base"  value="other">بقية الكادر</option>
        </select>
      </Col>
    </>
  );
};

export default AdminsFilter;
