import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Input, Title } from "../../components";
import Loading from "../../Tools/Loading";

const AddSpecialties = () => {
  const { id } = useParams();
  const isAdd = id === "add";
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({});
  const { data, isLoading } = useFETCH(
    isAdd ? "" : `admin/specialty-sections/${id}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      isAdd ? "admin/specialty-sections" : `admin/specialty-sections/${id}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    !isAdd &&
      setFormData({
        name: dataOld?.name,
      });
  }, [dataOld]);
  return (
    <>
    <Row>
        <Col lg={6} md={6} sm={6}>
          <Title title={!isAdd ? "تعديل الكلية" : "اضافة كلية"} className="mt-5" />
        </Col>
        <Col lg={5} md={5} sm={5}>
      </Col>
      <Col lg={1} md={1} sm={1}>
      <Back />
      </Col>
    </Row>
      
      <Row className="h-[70vh] flex justify-center items-center">
        <Col
          md={5}
          className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto "
        >
          <Input
            name="name"
            value={formData?.name}
            onChange={handleChangeInput}
            title="اسم الكلية"
          />
          {loading || (isLoading && !isAdd) ? <Loading /> : ""}
          <ButtonRed
            name={!isAdd ? "تعديل" : "اضافة"}
            onClick={handleSubmitMain}
            className="py-3 mt-3"
          />
        </Col>
      </Row>
      {/* <Back /> */}
    </>
  );
};

export default AddSpecialties;
