import { RiDeviceRecoverLine } from "react-icons/ri";
import { usePOST } from "../../Tools/APIs";
import Popup from "../Popup/Popup";
const Recovery = ({ id, name }) => {
  const { handleSubmit } = usePOST({});
  const handleClick = () => {
    handleSubmit(`admin/students/${id}/restore`, "", "", true);
  };
  return (
    <>
      <Popup
        text="استعادة"
        description={
          <p className="font-semibold text-xl text-center py-3 ">
            هل تريد استعادة
            <span className="text-red-700"> ({name})</span> من قائمة المحذوفين
          </p>
        }
        Icon={RiDeviceRecoverLine}
        iconColor="text-pink-700"
        onClick={handleClick}
      >
        <RiDeviceRecoverLine size={27} className="text-pink-700" />
      </Popup>
    </>
  );
};

export default Recovery;
