import React from "react";
import { Col } from "../../Tools/Grid-system";
import { useFilter } from "../../Tools/APIs";

const SingleButFiler = ({ value, name, title }) => {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  return (
    <Col lg={2} md={12}  sm={12} className="flex flex-col justify-evenly !w-full lg:!mx-5">
      <div
        className={` max-md:w-full  max-lg:w-[180px]  mx-auto shadow-Third lg:mx-2  flex justify-end !flex-col  font-bold text-base max-md:text-base    shadow-sm  border-Third bg-white text-Third rounded-xl hover:bg-opacity-70 text-center  cursor-pointer  px-2 py-[0.8rem]
                    ${
                      searchParams.get(name) === value
                        ? "!bg-Third text-white"
                        : ""
                    }`}
        onClick={() => {
          if (name === "trashed" || name === "failed_to_pay") {
            if (searchParams.get(name) === value) {
              handleParamsDelete(name);
              
            } else {
              handleParamsClick(name, value);
              handleParamsDelete("completed");
              handleParamsDelete("completed");
            }
          } else {
            if (searchParams.get(name) === value) {
              handleParamsDelete(name);
            } else {
              if (
                searchParams.has("trashed") ||
                searchParams.has("failed_to_pay")
              ) {
                handleParamsClick(name, value);
                handleParamsDelete("trashed");
                handleParamsDelete("failed_to_pay");
              } else {
                handleParamsClick(name, value);
              }
            }
          }
        }}  
      >
        {title}
      </div>
    </Col>
  );
};

export default SingleButFiler;
