import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Data, Input, Title } from "../../components";
import Loading from "../../Tools/Loading";

const AddSingleSpecialties = () => {
  const { id, idd } = useParams();
  const isAdd = idd === "add";
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    handleChangeObject,
    handleAddObject,
    setDataDegrees,
    dataDegrees,
    setObj,
    obj,
  } = usePOST({});
  const { data: dataAllDegrees } = useFETCH(`admin/degrees?paginate=0`);
  const { data, isLoading } = useFETCH(
    !isAdd ? `admin/specialty-sections/${id}/specialties/${idd}` : ""
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      isAdd
        ? `admin/specialty-sections/${id}/specialties`
        : `admin/specialty-sections/${id}/specialties/${idd}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    !isAdd &&
      setFormData({
        name: dataOld?.name,
      });
    !isAdd &&
      setDataDegrees(
        dataOld?.degrees.map((e) => {
          return {
            id: e.id,
            price: e.price,
          };
        })
      );
  }, [dataOld]);

  return (
    <>
      <Title title={!isAdd ? "تعديل التخصص" : "اضافة تخصص"} className="mt-5" />
      <Row className="">
        <Col
          md={6}
          className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto "
        >
          <Input
            name="name"
            value={formData?.name}
            onChange={handleChangeInput}
            title="اسم التخصص"
          />
          <Row justify="center" className="items-center ">
            <Col xs={6}>
              <Input
                name="price"
                value={obj?.price}
                onChange={handleChangeObject}
                title="السعر"
              />
            </Col>
            <Col xs={6}>
              <h1 className={`text-start mt-2 `}>الدرجة العلمية</h1>
              <select
                name="id"
                value={obj?.id}
                onChange={handleChangeObject}
                className={`w-full border-2 border-Secondary py-2 rounded-xl  outline-none text-center mx-auto `}
              >
                <option value="" disabled selected hidden>
                  اختر الدرجة العلمية
                </option>

                {dataAllDegrees?.data.data
                  .filter((i) => !dataDegrees?.some((d) => +d.id === +i.id))
                  .map((k, i) => (
                    <option key={i} value={k.id}>
                      {k.name}
                    </option>
                  ))}
              </select>
            </Col>
            <Col xs={5}>
              <ButtonRed
                name="حفظ"
                onClick={handleAddObject}
                className="w-fit px-5 py-2 mb-2 mx-auto"
              />
            </Col>
            <Col xs={8}></Col>
            {dataDegrees?.map((e, i) => (
              <Col key={i} md={6} sm={12}>
                <div className="border rounded-xl border-Main flex justify-between p-1">
                  <div>
                    <Data
                      name="الدرجة العلمية"
                      text={
                        dataAllDegrees?.data.data?.filter(
                          (p) => +p.id === +e.id
                        )?.[0]?.name
                      }
                    />
                    <Data name="السعر" text={e.price} />
                  </div>
                  <div>
                    <ButtonRed
                      name=" تعديل"
                      onClick={() => {
                        let thisData = [...dataDegrees];
                        let x = thisData.splice(i, 1);
                        setDataDegrees(thisData);

                        setObj({
                          price: x[0].price,
                          id: x[0].id,
                        });
                      }}
                      className="w-fit !px-1 !py-1 !my-1 mx-auto !bg-Main"
                    />
                    <ButtonRed
                      name="حذف"
                      onClick={() => {
                        let total = [...dataDegrees];
                        total.splice(i, 1);
                        setDataDegrees(total);
                      }}
                      className="w-fit !px-1 !py-1 !my-1 mx-auto !bg-red-600"
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {loading || (isLoading && !isAdd) ? <Loading /> : ""}
          <ButtonRed
            name={!isAdd ? "تعديل" : "اضافة"}
            onClick={handleSubmitMain}
            className="py-3 mt-3"
          />
        </Col>
      </Row>
      <Back />
    </>
  );
};

export default AddSingleSpecialties;
