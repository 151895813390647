import { Tooltip } from "react-tooltip";

function IconHover({ children, id, text }) {
  return (
    <div>
      <div
        className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
        id={id || text}
      >
        {children}
        <Tooltip anchorSelect={`#${id || text}`} content={text} />
      </div>
    </div>
  );
}
export default IconHover;
