import { Col, Container, Row } from "../../Tools/Grid-system";
import {
  Add,
  Back,
  Data,
  DateFilter,
  Delete,
  PaymentFilter,
  Selected,
  Title,
  Update,
} from "../../components";
import { useFETCH, useFilter, usePOST } from "../../Tools/APIs";
import { Link, useLocation, useParams } from "react-router-dom";
import Pagination from "../../Tools/Pagination";
import Loading from "../../Tools/Loading";
import { AiFillPrinter } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { MdBlockFlipped } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";
import transactions from "../../svg/sort_12963813.svg";
import { useState } from "react";
function Payment({ all = null }) {
  const { id } = useParams();
  const { search } = useLocation();
  const [excel, setExcel] = useState();
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  const url =
    all != null
      ? `admin/transactions/all/payments${search}`
      : `admin/transactions/${id}/payments${search}`;
  const { data, deleteItem, isLoading } = useFETCH(
    url,
    `admin/transactions/${id}/payments`
  );
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    setViewFile,
    viewFile,
    formData,
    loading,
  } = usePOST();
  const { data: dataAdmins } = useFETCH(
    `admin/users?role=beneficiary&paginate=0`
  );

  const { data: payment } = useFETCH(`admin/payment/methods?paginate=0`);

  return (
    <Container>
      <Row>
        <Col lg={6} md={6} sm={6}>
          <Title title={`  الملفات المالية » الدفعات ${searchParams.get("state")==1||!searchParams.get("state") ? 'المُحصَّلة المُعلقة':''}`} link="/transactions" />
        </Col>
        <Col
          md={5}
          lg={5}
          sm={6}
          className="flex justify-end max-lg:justify-center "
        >
          {all == null && <Add link={`/transactions/${id}/payment/add`} />}
        </Col>
        <Col lg={1} className="flex justify-center ">
          <Back />
        </Col>
      </Row>

      <Row className="mb-5 px-3">
        <Col lg={2} xs={3} sm={10} className="max-md:mx-auto">
          <PaymentFilter />
        </Col>
        <Col lg={2} xs={3} sm={10} className="max-md:mx-auto">
          <DateFilter />
        </Col>
        <Col lg={2} md={3} sm={10} className="max-md:mx-auto">
          <select
            name="payment_method_id"
            value={searchParams.get("payment_method_id")}
            onChange={(e) =>
              handleParamsClick("payment_method_id", e.target.value)
            }
            className={`h-[48px]  text-Third font-bold text-base w-full  shadow-Third shadow-sm border-Secondary  rounded-xl outline-none text-center mx-auto `}
          >
            <option value="" disabled selected hidden>
                طرق الدفع
            </option>
            <option value="">الكل</option>
            {payment?.data.data.map((e) => (
              <option value={e.id}>{e.name}</option>
            ))}
          </select>
        </Col>
        <Col lg={2} md={3} sm={10} className="max-md:mx-auto">
          <Selected
            name="transfer_recipient_id"
            value={searchParams.get("transfer_recipient_id")}
            onChange={(e) =>
              handleParamsClick("transfer_recipient_id", e.target.value)
            }
            title="  مستلم الحوالة "
            className="!mt-0 text-Third font-bold text-base border-none"
          >
            <option value="">الكل</option>
            {dataAdmins?.data.data?.map((e) => (
              <>
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
              </>
            ))}
          </Selected>
        </Col>
      </Row>
     
      {all == null && <DataTransaction id={id} />}

      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row justify="center md:px-3 mx-4">
          <Col  className="overflow-x-auto ">
          <table className="w-[90%] max-md:w-[700px] mx-auto my-5 text-center rounded-2xl overflow-hidden border border-Secondary ">
              <thead className="table-thead text-white font-extrabold text-sm h-[70px]">
                <tr>
                  {excel && <td className=""></td>}

                  {all && (
                    <>
                      <td className="px-7"> اسم الطالب</td>
                    </>
                  )}
                  {all && (
                    <>
                      <td className="px-7"> حالة الطالب</td>
                    </>
                  )}
                  {all && (
                    <>
                      <td className="px-7"> اسم مسجل الطالب</td>
                    </>
                  )}

                  <td className="px-7">حالة الدفعة</td>
                  {all && (
                    <>
                      <td className="px-7"> طريقة الدفع</td>
                    </>
                  )}
                  <td className="px-7">تاريخ الدفعة</td>
                  {all && (
                    <>
                      <td className="px-7"> آخر تعديل</td>
                    </>
                  )}

                  <td className="px-7"> قيمة الدفعة</td>
                  <td className="px-7">قيمة الحوالة</td>
                  {all && (
                    <>
                      <td className="px-7"> مسجل الحوالة</td>
                    </>
                  )}
                  
                  <td className="px-7">المبلغ الذي وصل</td>
                  <td className="px-7">العمليات</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e, i) => (
                    <PaymentRow
                      e={e}
                      i={i}
                      setExcel={setExcel}
                      id={id}
                      deleteItem={deleteItem}
                      all={all}
                    />
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      {/* {all == null && <Add link={`/transactions/${id}/payment/add`} />} */}
      {/* <Back /> */}
    </Container>
  );
}
export default Payment;

export const DataTransaction = ({ id }) => {
  const { data: dataTransaction } = useFETCH(`admin/transactions/${id}`);
  
  console.log(dataTransaction?.data?.data)

  return (
    <Row className="mb-5 justify-start px-3">
      <Col lg={3} xs={6}>
        <Data
          name=" المبلغ الكامل "
          text={dataTransaction?.data.data.total_cost}
        />
      </Col>
      <Col lg={3} xs={6}>
        <Data
          name=" مبلغ الحسم"
          text={dataTransaction?.data.data.discounted_amount}
        />
      </Col>
      <Col lg={3} xs={6}>
        <Data
          name=" المبلغ بعد الحسم"
          text={dataTransaction?.data.data.total_cost_after_discount}
        />
      </Col>
      <Col lg={3} xs={6}>
        <Data
          name=" المبلغ المدفوع "
          text={dataTransaction?.data.data.paid_amount}
        />
      </Col>
      <Col lg={3} xs={6}>
        <Data
          name=" المبلغ المتبقي "
          text={dataTransaction?.data.data.remaining_amount}
        />
      </Col>
      <Col lg={3} xs={6}>
        <Data
          name=" الطالب"
          text={
            dataTransaction?.data.data.student
              ? dataTransaction?.data.data.student?.first_name +
                " " +
                dataTransaction?.data.data.student?.mid_name +
                " " +
                dataTransaction?.data.data.student?.last_name
              : " ______ "
          }
        />
      </Col>
      <Col lg={3} xs={6}>
        <Data name=" التخصص" text={dataTransaction?.data.data.specialty.name} />
      </Col>
      <Col lg={3} xs={6}>
        <Data name=" تاريخ " text={dataTransaction?.data.data.date} />
      </Col>
      <Col lg={3} xs={6}>
        <Data
          name=" الرسوم الاضافية "
          text={dataTransaction?.data.data?.extra_charge}
        />
      </Col>
      <Col lg={3} xs={6}>
        <Data
          name=" سبب الرسوم  "
          text={dataTransaction?.data.data?.extra_charge_reason}
        />
      </Col>
      <Col lg={3} xs={6}>
        <Data
          name="حالة الملف المالي "
          text={
            dataTransaction?.data.data.is_completed ? "مكتملة" : "غير مكتملة"
          }
        />
      </Col>
    </Row>
  );
};

const PaymentRow = ({ e, i, id, setExcel, deleteItem, all }) => {
  id = e.transaction.id;

  return (
    <tr
      key={i}
      className={`border border-Main
       ${e.state === 2 ? "bg-green-400" : "bg-gray-200"}`}
    >
      {all && (
        <>
          <td className="">{e.transaction?.student?.full_name}</td>
        </>
      )}
      {all && (
        <>
          <td className="">{e.transaction?.student?.status}</td>
        </>
      )}
      {all && (
        <>
          <td className="">
            {e.transaction?.student?.transaction_creator?.name}
          </td>
        </>
      )}
      <td className={`font-bold `}>
        {e.state === 2 ? "مقبولة" : "غير مقبولة بعد"}
      </td>
      {all && <td>{e.payment_method?.name}</td>}
      <td className="">{e.date}</td>
      {all && (
        <>
          <td className="">{e.updated_at}</td>
        </>
      )}

      <td className="">{e.amount}</td>

      <td className="">{e.transfer_amount || "___"}</td>

      {all && (
        <>
          <td className="">{e.transfer_recipient?.name}</td>
        </>
      )}

      <td className="">
        {e.received_amount === 0 ? "غير محدد بعد" : e.received_amount}
      </td>
      <td className="flex justify-center items-center gap-3 mt-2">
        <div className="flex justify-center items-center gap-3">
          {() => setExcel(e.excel_import)}
          {e.excel_import ? (
            <td className="relative border-Main border w-fit">
              <SiMicrosoftexcel
                id={e.id}
                size={25}
                className="text-green-500"
              />
            </td>
          ) : (
            <td className="w-fit"></td>
          )}

          {/* {all != null && (
            <Link to={`/transactions/${id}/payment`}>
              <div
                className={`w-10 h-10  rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
              >
                <div id="my-anchor-element">
                  <img src={transactions} alt="" className="w-7 h-7" />
                  <Tooltip
                    anchorSelect="#my-anchor-element"
                    content="عرض  الملف المالي"
                  />
                </div>
              </div>
            </Link>
          )} */}

          {/* {e.state === 2 && (
            <div
              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
            >
              <div id="my-anchor-element">
                <Link to={`/transactions/${e.id}/payment/pdf/${e.id}`}>
                  <AiFillPrinter size={25} className="text-gray-600" />
                  <Tooltip
                    anchorSelect="#my-anchor-element"
                    content="طباعة الفاتورة"
                  />
                </Link>
              </div>
            </div>
          )} */}
          {/* {e.state === 1 && (
            <div
              className={`w-10 h-10 rounded-full flex justify-center items-center`}
            >
              <div id="my-anchor-element-4">
                <MdBlockFlipped size={25} className="text-red-700" />
                <Tooltip
                  anchorSelect="#my-anchor-element-4"
                  content="غير مسموح بطباعة الفاتورة"
                />
              </div>
            </div>
          )} */}
          {e.state === 2 &&
          (localStorage.getItem("role_name") === "super_admin" ||
            localStorage.getItem("role_name") === "financial_manager") ? (
            <Update
              show={true}
              name={e.name}
              edit={`/transactions/${id}/payment/${e.id}`}
            />
          ) : e.state === 1 ? (
            <Update
              show={true}
              name={e.name}
              edit={`/transactions/${id}/payment/${e.id}`}
            />
          ) : (
            ""
          )}

          <div id="my-anchor-element-show">
            <div
              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
            >
              <Link to={`/transactions/${id}/payment-details/${e.id}`}>
                <FaEye size={27} className="text-blue-700" />
              </Link>
            </div>
            <Tooltip anchorSelect="#my-anchor-element-show" content="عرض" />
          </div>
          <Delete id={e.id} onClick={() => deleteItem(e)} />
        </div>
        {e.has_transfer_info ? (
          <div id="show">
             <Tooltip anchorSelect="#show" content="يوجد حوالة" />
          <FaStar size={25} className="text-yellow-400" />
          </div>
          
        ) : (
          <FaStar
            size={25}
            className={`${e.state === 2 ? "text-green-400" : "text-gray-200"}`}
          />
        )}
      </td>
    </tr>
  );
};
