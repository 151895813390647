function Input({ title, name, value, type, onChange, ref, readOnly }) {
  return (
    <div className="my-2 px-1">
      <h1 className="text-start text-Third my-2 font-semibold">{title}</h1>
      <input 
        style={{
          boxShadow:
            "3px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "1px solid black",
        }}
        ref={ref}
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        autocomplete="off"
        readOnly={readOnly}
        className={
          readOnly
            ? "w-full border-2 m-1 py-3 rounded-xl"
            : "w-full border-2 border-Third m-1 py-3 rounded-xl mx-auto"
        }
      />
    </div>
  );
}
export default Input;
